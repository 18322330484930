import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import ApexCharts from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { color } from "framer-motion";
import { grid, width } from "@mui/system";
import { Margin } from "@mui/icons-material";
import IncentiveBarChart from "./IncentiveBarChart ";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TotalRevenue = () => {
  const [filter, setFilter] = useState("month");
  const [data, setData] = useState([]);
  const [totalReward, setTotalReward] = useState(0);
  const [currency, setCurrency] = useState("");
  const [distributionData, setDistributionData] = useState([]);
  const [isMobileScreen, setIsMobileScreen] = useState(window.matchMedia("(max-width: 768px)").matches);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(
          `https://zola.technology/api/company/employees/performance/rewards/?filter=${filter}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const result = await response.json();
        const actualData = result.data;

        setData(actualData);
        calculateTotalReward(actualData);
        setDistributionData(generateDistributionData(actualData));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const handleResize = () => {
      setIsMobileScreen(window.matchMedia("(max-width: 768px)").matches);
    };

    window.addEventListener('resize', handleResize);
    fetchData();
    handleResize(); // Call once to set initial state

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [filter]);

  const calculateTotalReward = (data) => {
    const total = data.reduce((acc, item) => acc + item.reward_total, 0);
    const rewardCurrency = data.length > 0 ? data[0].reward_currency : "";
    setTotalReward(total);
    setCurrency(rewardCurrency);
  };

  const generateDistributionData = (data) => {
    return data.map((item, index) => ({
      label: item?.employee?.username || `Employee ${index + 1}`, // Fallback to "Employee X" if username is missing
      value: item?.reward_total ?? 0, // Fallback to 0 if reward_total is missing
      color: getColor(index),
    }));
  };
  

  const getColor = (index) => {
    const colors = ["#FF66CC", "#3399FF", "#33CC33", "#FF9933", "#6633FF"];
    return colors[index % colors.length];
  };

 

  const areaChartOptions = {
    chart: {
      type: 'area',
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false, // Hide the toolbar
      },
    
    },
    colors: ['#00BA34'], // Set the line color
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false, // Hide the grid
    },
    stroke: {
      curve: 'straight', // Set the curve to straight
      width: 2, // Set the line thickness 
    },

    xaxis: {
      type: 'category',
      categories: data.map((item) => item.period),
      labels: {
        rotate: 0, // Set rotation to 0 to display labels horizontally
        style: {
          fontSize: '12px',
          fontWeight: 'medium',
          fontStyle: 'italic', // Set the text to italic
          colors : '#6F707E'
        },
      },

    },
    yaxis: {
    
      labels: {
        formatter: (val) => ` ${val}`,
        style: {
          fontSize: '12px',
          fontWeight: 'medium',
          colors : '#6F707E'
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 1,
        opacityTo: 0,
        stops: [0, 100],
        colorStops: [
          {
            offset: 0,
            color: '#D8FECA',
            opacity: 1,
          },
          {
            offset: 100,
            color: '#ACFFC300',
            opacity: 0,
          },
        ],
      },
    },
    tooltip: {
      y: {
        formatter: (val) => `${currency} ${val}`,
      },
    },
  };

  const areaSeries = [
    {
      name: 'Revenue',
      data: data.map((item) => item.reward_total || 0)
    },
  ];

  return (
    <div className="p-4 lg:space-x-12 bg-white mt-7 rounded-xl lg:w-full xs:w-full grid lg:grid-cols-2 xs:grid-cols-1 gap-8">
      {/* Total Incentive Card */}
      <div className="flex-1 dark:bg-gray-700 lg:w-full ">
        <div className="flex items-center mb-4">
          <div className="w-full ">
            <div className="flex justify-between "> 
            <h2 className="text-[20px] font-semibold text-[#6F707E] dark:text-white">
              Total Incentive
            </h2>
            <div className="border rounded px-2 py-[2px]">
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="p-1 dark:bg-gray-700 bg-white  dark:text-white focus:outline-none"
            >
              <option value="month">Month</option>
              <option value="year">Year</option>
            </select>
          </div>
          </div>
            <p className="text-[28px] text-[#454861] font-semibold dark:text-white ml-1">
              {currency} {totalReward.toLocaleString()}
            </p>
          {/* {  <p className="text-green-500">
              25%{" "}
              <span className="text-gray-500 dark:text-gray-400">increase</span>
            </p>} */}
          </div>
         
        </div>
        <ReactApexChart
          options={areaChartOptions}
          series={areaSeries}
          type="area"
          height={350}
          width={isMobileScreen ? "100%" : "100%"}
         
        />
      </div>
      {/* Incentive Distribution with Semicircle */}
      <div>
      <IncentiveBarChart/>
      </div>
    </div>
  );
};

export default TotalRevenue;

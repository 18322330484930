import React, { useEffect, useState } from "react";
import { listFeedback } from "../../api";
import { HiDotsVertical } from "react-icons/hi";

function CustomerTable() {
  const [feedbackData, setFeedbackData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10); // Default page size
  const [totalPages, setTotalPages] = useState(1); // For pagination
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows
  const [isAllSelected, setIsAllSelected] = useState(false); // Track "Select All" checkbox
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  useEffect(() => {
    const fetchFeedback = async () => {
      setLoading(true);
      try {
        const data = await listFeedback(currentPage, pageSize);
        console.log("API Response:", data); // Check the structure of the response
        setFeedbackData(data.data || []); // Safeguard against undefined `data`
        setTotalPages(
          data.total_pages || Math.ceil((data.count || 0) / pageSize)
        );
      } catch (err) {
        console.error("Error fetching feedback:", err);
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchFeedback();
  }, [currentPage, pageSize]); // Refetch data when page or page size changes

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Handle "Select All" checkbox
  const handleSelectAll = () => {
    const currentPageIds = feedbackData.map((row) => row.id);

    if (isAllSelected) {
      // Deselect all rows on the current page
      setSelectedRows((prev) => prev.filter((id) => !currentPageIds.includes(id)));
    } else {
      // Select all rows on the current page
      setSelectedRows((prev) => [...new Set([...prev, ...currentPageIds])]);
    }

    setIsAllSelected(!isAllSelected);
  };

  // Sync the "Select All" state with the current page data
  useEffect(() => {
    const currentPageIds = feedbackData.map((row) => row.id);
    const allSelected = currentPageIds.every((id) => selectedRows.includes(id));
    setIsAllSelected(allSelected);
  }, [feedbackData, selectedRows]);

  // Handle individual row selection
  const handleRowSelection = (id) => {
    setSelectedRows((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // Filter feedbackData to exclude entries without `author_name` or `phone_number`
  const filteredFeedbackData = feedbackData.filter(
    (feedback) => feedback.author_name && feedback.phone_number
  );

  const handleSendSMS = () => {
    console.log("Message sent");
  };

  const handleSendWhatsApp = () => {
    console.log("Whatsapp Messages Sent");
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-lg font-bold">Customer Contacts</h1>

        <select
          id="timeFilter"
          className="border border-gray-300 rounded px-4 py-2 text-sm"
        >
          <option value="this-month">This Month</option>
          <option value="this-year">This Year</option>
        </select>
      </div>

      <div className="flex items-center justify-between space-x-2 mb-4">
        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={isAllSelected}
            onChange={handleSelectAll}
            className="w-6 h-6 border-gray-300 rounded"
          />
          <span className="bg-[#e7edfe] py-1 px-3 rounded-lg text-blue-600 font-medium cursor-pointer">
            Select All
          </span>
        </div>

        {/* Vertical Dots Icon with Dropdown */}
        <div className="relative">
          <button
            onClick={toggleDropdown} // Function to toggle the dropdown visibility
            className="p-2 rounded-full hover:bg-gray-200"
          >
            <HiDotsVertical size={32} />
          </button>

          {/* Dropdown Menu */}
          {isDropdownOpen && (
            <div className="absolute right-0 w-64 bg-gray-100 border border-gray-200 rounded shadow-lg">
              <button
                onClick={handleSendSMS} // Function to handle SMS action
                className="block w-full px-4 py-2 text-left text-gray-700 hover:bg-white"
              >
                Send SMS
              </button>
              <button
                onClick={handleSendWhatsApp} // Function to handle WhatsApp action
                className="block w-full px-4 py-2 text-left text-gray-700 hover:bg-white"
              >
                Send WhatsApp Message
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full table-auto border-separate border-spacing-y-3">
          <thead className="text-[#6F707E] text-[16px]">
            <tr>
              <th className="px-4 py-2 text-left font-medium">S/N</th>
              <th className="px-4 py-2 text-left font-medium">Name</th>
              <th className="px-4 py-2 text-left font-medium">Contact</th>
              <th className="px-4 py-2 text-left font-medium">Count</th>
              <th className="px-4 py-2 text-left font-medium">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredFeedbackData.length > 0 ? (
              filteredFeedbackData.map((feedback, index) => (
                <tr
                  key={feedback.id}
                  onClick={() => handleRowSelection(feedback.id)}
                  className={`cursor-pointer ${
                    selectedRows.includes(feedback.id)
                      ? "bg-blue-100"
                      : "bg-white"
                  } text-[#282A3E] text-[16px] hover:shadow-md transition`}
                >
                  <td data-cell="S/N">{index + 1}</td>
                  <td data-cell="Name">{feedback.author_name}</td>
                  <td data-cell="Contact" className="font-semibold">
                    +{feedback.phone_number}
                  </td>
                  <td data-cell="Count" className="font-semibold">
                    2
                  </td>
                  <td
                    data-cell="Actions"
                    className="font-semibold px-4 py-2 flex justify-start space-x-2"
                  >
                    <button className="bg-blue-500 text-white px-3 py-1 rounded">
                      Details
                    </button>
                    <button className="bg-teal-500 text-white px-3 py-1 rounded">
                      Send SMS
                    </button>
                    <button className="bg-green-600 text-white px-3 py-1 rounded">
                      Send WhatsApp
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center py-4">
                  {loading ? "Loading..." : "No data available."}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="flex justify-between items-center mt-4">
        <button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
          className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50"
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
          className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default CustomerTable;

import { useEffect, useState } from "react";
import { getLinkInteractions, feedBackList } from "../../api";
import axios from "axios";
import { PiChartLineUpLight } from "react-icons/pi";
import {
  FaExclamationTriangle,
  FaDownload,
  FaUsers,
  FaSmile,
  FaLink,
  FaSms,
  FaWhatsapp,
} from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FaStar } from "react-icons/fa";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa6";
import OverallPerformance from "./TeamPerformance/OverallPerformance";
import { BiQr } from "react-icons/bi";
import { SiNfc } from "react-icons/si";
import { Doughnut } from "react-chartjs-2";
import { MdOutlineArrowCircleUp } from "react-icons/md";
import Overview from "./Overview";
import { useTimeRange } from "../../context/TimeRangeContext";
import { toPng, toCanvas } from "html-to-image";
import { jsPDF } from "jspdf";

const ReviewAnalytics2 = () => {
  const { timeRange } = useTimeRange();

  const [performanceData, setPerformanceData] = useState([]);
  const [allEmployeesData, setAllEmployeesData] = useState(null);
  const [filteredFeedbackData, setFilteredFeedbackData] = useState([]);
  const [categorisedFeedbackData, setCategorisedFeedbackData] = useState(null);
  const currentPage = 1;
  const pageSize = 4;
  const [filteredTapsData, setFilteredTapsData] = useState(null); // New state for filtered taps
  const [googleReviews, setGoogleReviews] = useState(null);
  const [ratingData, setRatingData] = useState(null);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [linkInteractions, setLinkInteractions] = useState(null);

  const [filterType, setFilterType] = useState("week");

  const [weeklyFeedbacks, setWeeklyFeedbacks] = useState(0);
  const [monthlyFeedbacks, setMonthlyFeedbacks] = useState(0);

  const [feedbackData, setFeedbackData] = useState(null);

  const [feedbackChannels, setFeedbackChannels] = useState({});

  // Assuming the JSON data is stored in localStorage with a key, e.g., "myData"
  const userData = localStorage.getItem("userData");
  const parsedData = JSON.parse(userData);
  const companyType = parsedData.company_type;

  // Fetch data dynamically based on timeRange
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };

        setLoading(true);

        const [
          performanceRes,
          allEmployeesRes,
          filteredFeedbackRes,
          // categorisedFeedbackRes,
          filteredTapsRes,
          googleReviewsRes,
          ratingRes,
        ] = await Promise.all([
          axios.get(
            `https://zola.technology/api/company/dashboard/data/employees-taps-issues-performance/?filter=${timeRange}`,
            { headers }
          ),
          axios.get(
            `https://zola.technology/api/company/employees/get-all/?page=1&page_size=10`,
            { headers }
          ),
          axios.get(
            `https://zola.technology/api/company/dashboard/feedback-data/get/?filter=${timeRange}`,
            { headers }
          ),
          // axios.get(
          //   `https://zola.technology/api/company/feedback/list-categorised/?filter=${timeRange}`,
          //   { headers }
          // ),
          axios.get(
            `https://zola.technology/api/company/employees/performance/filter/get-filtered-taps/?filter=${timeRange}`,
            { headers }
          ),
          axios.get("https://zola.technology/api/company/reviews/list", {
            headers,
          }),
          axios.get(
            `https://zola.technology/api/company/analytics/performance-and-rating/get/?filter=${timeRange}`,
            { headers }
          ),
        ]);

        setPerformanceData(performanceRes?.data);
        setTotalEmployees(allEmployeesRes?.data.total);
        setAllEmployeesData(allEmployeesRes?.data.data);
        setFilteredFeedbackData(filteredFeedbackRes?.data);
        // setCategorisedFeedbackData(categorisedFeedbackRes?.data);
        setFilteredTapsData(filteredTapsRes?.data);
        setGoogleReviews(googleReviewsRes?.data);
        setRatingData(ratingRes?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [timeRange]);

  // Fetch link interactions data
  useEffect(() => {
    const fetchLinkInteractions = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token not found");
        }
        const data = await getLinkInteractions(token);
        console.log(data);
        setLinkInteractions(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchLinkInteractions();
  }, []);

  useEffect(() => {
    const fetchSortedFeedback = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token not found");
        }
        const response = await axios.get(
          "https://zola.technology/api/company/feedback/get-filtered-feedback/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              filter: "week", // Default filter type
            },
          }
        );

        const feedbackData = response.data;

        // Calculate the total feedback count
        const total = (feedbackData || []).reduce(
          (sum, item) => sum + (item.feedback?.length || 0),
          0
        );

        setWeeklyFeedbacks(total);
        console.log(`Total feedbacks for week:`, total);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchSortedFeedback();
  }, []);

  useEffect(() => {
    const fetchMonthlyFeedback = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token not found");
        }
        const response = await axios.get(
          "https://zola.technology/api/company/feedback/get-filtered-feedback/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              filter: "month",
            },
          }
        );

        const feedbackData = response.data;

        // Calculate the total feedback count
        const total = feedbackData.reduce(
          (sum, item) => sum + item.feedback.length,
          0
        );

        setMonthlyFeedbacks(total);
        console.log(`Total feedbacks for month:`, total);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchMonthlyFeedback();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await feedBackList();
        console.log(data);
        setFeedbackData(data.categorized_feedbacks);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchFeedbackChannels = async () => {
      const url = "https://zola.technology/api/company/dashboard/data/feedback-channels/";
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found");
      }

      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
    
        if (!response.ok) {
          throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
        }

        console.log(response)
    
        const data = await response.json();
        console.log("Feedback Channels Data:", data);
        setFeedbackChannels(data);
      } catch (error) {
        console.error("Error fetching feedback channels:", error);
      }
    };
    
    // Call the function
    fetchFeedbackChannels();
    
  }, [])

  // Calculate the number of taps and percentage change for link interactions
  const totalTaps = linkInteractions?.total_filtered_taps || 0;

  if (loading) {
    return <></>;
  }

  if (error) {
    return <div>Error fetching data: : {error.message}</div>;
  }
  const data = {
    labels: ["QR Code", "NFC Cards", "SMS", "WhatsApp"], // Added WhatsApp to labels
    datasets: [
      {
        label: "Conversions",
        data: [
          feedbackChannels?.qr_code?.percentage || 0, // QR Code percentage
          feedbackChannels?.nfc_card?.percentage || 0, // NFC Cards percentage
          feedbackChannels?.sms?.percentage || 0, // SMS percentage
          feedbackChannels?.whatsapp?.percentage || 0, // WhatsApp percentage
        ],
        backgroundColor: ["#111827", "#3B82F6", "#FACC15", "#25D366"], // Added WhatsApp color
        borderWidth: 0,
      },
    ],
  };
  
  // Only log data if feedbackChannels is defined
  console.log("Chart Data:", data);
  
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "75%",
  };

  const conversions = feedbackChannels
  ? [
      {
        icon: <BiQr size={24} className="text-white" />,
        iconBg: "#191f2f",
        title: "QR Code",
        description: "Feedbacks Through QR Code",
        value: `${feedbackChannels.qr_code?.count || 0}`,
        change: `${feedbackChannels.qr_code?.change?.percentage_change || 0}%`,
        positive:
          feedbackChannels.qr_code?.change?.type === "Increase" ||
          feedbackChannels.qr_code?.change?.percentage_change === 0,
      },
      {
        icon: <SiNfc size={24} className="text-white" />,
        iconBg: "#0c4ef8",
        title: "NFC Cards",
        description: "NFC Scans",
        value: `${feedbackChannels.nfc_card?.count || 0}`,
        change: `${feedbackChannels.nfc_card?.change?.percentage_change || 0}%`,
        positive:
          feedbackChannels.nfc_card?.change?.type === "Increase" ||
          feedbackChannels.nfc_card?.change?.percentage_change === 0,
      },
      {
        icon: <FaSms size={24} className="text-white" />,
        iconBg: "#eab846",
        title: "SMS",
        description: "SMS Received",
        value: `${feedbackChannels.sms?.count || 0}`,
        change: `${feedbackChannels.sms?.change?.percentage_change || 0}%`,
        positive:
          feedbackChannels.sms?.change?.type === "Increase" ||
          feedbackChannels.sms?.change?.percentage_change === 0,
      },
      {
        icon: <FaWhatsapp size={24} className="text-white" />,
        iconBg: "#25D366",
        title: "WhatsApp",
        description: "Feedbacks Through WhatsApp",
        value: `${feedbackChannels.whatsapp?.count || 0}`,
        change: `${feedbackChannels.whatsapp?.change?.percentage_change || 0}%`,
        positive:
          feedbackChannels.whatsapp?.change?.type === "Increase" ||
          feedbackChannels.whatsapp?.change?.percentage_change === 0,
      },
    ]
  : [];


  //feedback cards

  const totalNeutralData = filteredFeedbackData.neutral;
  const totalPositiveData = filteredFeedbackData.positive;
  const totalNegativeData = filteredFeedbackData.negative;

  //performance employees andissues cards
  const combinedPerformance = performanceData;
  const employees = performanceData.employees;
  const issues = performanceData.issues;
  const performance = performanceData.performance;
  const taps = performanceData.taps;

  const cards = [
    {
      title: "Total Employees",
      value: employees.count ? employees.count : 0,
      percentage: employees.change.total_change,
      change: "Employees",
      icon: <FaUsers className="text-red-300 dark:text-red-400" />,
      iconBg: "#feebeb", // No change as it's inline styling
      color: "bg-red-500",
      positive: employees.change.type,
    },
    {
      title: "Total Issues",
      value: issues.count,
      extraInfo: "Resolved: 78 Pending: 24",
      icon: (
        <FaExclamationTriangle className="text-red-400 dark:text-red-500" />
      ),
      iconBg: "#fce9eb", // No change as it's inline styling
      color: "bg-red-500",
    },
    {
      title: "Average Performance",
      value: `${performance.score}%`,
      percentage: `${performance.change.percentage_change}%`,
      change: "",
      icon: <PiChartLineUpLight className="text-white dark:text-black" />,
      iconBg: "#d4f3dd", // No change as it's inline styling
      color: "bg-green-500",
      positive: performance.change.type,
    },
  ];

  const handleExport = async (format) => {
    const element = document.getElementById("exportable-content");

    if (format === "pdf") {
      const pdf = new jsPDF();
      const canvas = await toCanvas(element);
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
      pdf.save("review-analytics.pdf");
    } else if (format === "doc") {
      const htmlContent = element.innerHTML;
      const blob = new Blob([htmlContent], { type: "application/msword" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "review-analytics.doc";
      link.click();
    } else if (format === "image") {
      const dataUrl = await toPng(element);
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "review-analytics.png";
      link.click();
    }
  };

  return (
    <div className="w-full space-y-4 md:p-2 font-inter bg-[#F5F8FF] dark:bg-gray-900" id="exportable-content">
      <Overview onExportClick={handleExport} />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8 bg-gray-50 dark:bg-transparent">
        <div className="bg-white dark:bg-gray-900 rounded-xl p-6 shadow">
          <div className="w-full">
            <div className="flex justify-between items-center mb-6">
              <div className="flex items-center gap-3">
                <div className="bg-green-100 dark:bg-green-800 p-3 rounded-lg">
                  <FaThumbsUp className="text-green-500" />
                </div>
                <div>
                  <h2 className="text-md font-medium text-gray-500 dark:text-gray-400">
                    Feedback
                  </h2>
                  <h1 className="text-4xl font-bold text-gray-900 dark:text-gray-100 text-gray-900 dark:text-gray-100">
                    {filteredFeedbackData.total_feedback}
                  </h1>
                  <p
                    className={`text-sm mt-1 ${
                      filteredFeedbackData.change.type === "Increase"
                        ? "text-green-500"
                        : filteredFeedbackData.change.type === "Decrease"
                        ? "text-red-500"
                        : "text-gray-500 dark:text-gray-400"
                    }`}
                  >
                    {filteredFeedbackData.change.type === "Increase"
                      ? "↑"
                      : filteredFeedbackData.change.type === "Decrease"
                      ? "↓"
                      : ""}{" "}
                    {filteredFeedbackData.change.percentage_change}%{" "}
                    <span className="text-gray-400 dark:text-gray-500">
                      {`Since last ${timeRange.toLowerCase()}`}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-[#f9fafb] dark:bg-gray-800 rounded-lg p-6">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center gap-2">
                    <FaStar className="text-yellow-500 text-xl" />
                    <h4 className="text-md font-medium text-gray-900 dark:text-gray-100">
                      Average Rating
                    </h4>
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <h1 className="text-4xl font-bold text-gray-900 dark:text-gray-100">
                    {filteredFeedbackData?.average_rating.toFixed(1)}
                  </h1>
                  <div className="flex justify-center space-x-1">
                    {[...Array(5)].map((_, index) => (
                      <FaStar
                        key={index}
                        size={24}
                        className={
                          index <
                          Math.floor(filteredFeedbackData?.average_rating)
                            ? "text-[#F3BB21]" // Full stars
                            : index ===
                                Math.floor(
                                  filteredFeedbackData?.average_rating
                                ) &&
                              filteredFeedbackData?.average_rating % 1 >= 0.5
                            ? "text-[#F3BB21] opacity-50" // Half star
                            : "text-[#EAEAEC] dark:text-gray-700" // Empty stars
                        }
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div className="bg-[#f9fafb] dark:bg-gray-800 rounded-lg p-6">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center gap-2">
                    <FaThumbsUp className="text-green-500 text-xl" />
                    <h4 className="text-md font-medium text-gray-900 dark:text-gray-100">
                      Positive Reviews
                    </h4>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <h1 className="text-4xl font-bold text-gray-900 dark:text-gray-100">
                    {totalPositiveData.count}
                  </h1>
                  <span className="text-green-500 bg-green-100 px-2 py-1 rounded-md text-xs">
                    {totalPositiveData.change.total_change}
                  </span>
                </div>
                <div className="flex w-full gap-2 items-center mt-3">
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-green-500 h-2 rounded-full"
                      style={{
                        width: `${totalPositiveData.percentage}%`,
                      }}
                    ></div>
                  </div>
                  <p className="text-xs text-gray-500">
                  {totalPositiveData.percentage}%
                  </p>
                </div>
              </div>

              <div className="bg-[#f9fafb] dark:bg-gray-800 rounded-lg p-6">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center gap-2">
                    <FaStar className="text-yellow-500 text-xl" />
                    <h4 className="text-md font-medium text-gray-900 dark:text-gray-100">
                      Neutral Reviews
                    </h4>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <h1 className="text-4xl font-bold text-gray-900 dark:text-gray-100">
                    {totalNeutralData?.count}
                  </h1>
                  <span className="text-yellow-500 bg-yellow-100 px-2 py-1 rounded-md text-xs">
                    {totalNeutralData?.change.total_change}
                  </span>
                </div>
                <div className="flex w-full gap-2 items-center mt-3">
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-yellow-500 h-2 rounded-full"
                      style={{
                        width: `${totalNeutralData.percentage}%`,
                      }}
                    ></div>
                  </div>
                  <p className="text-xs text-gray-500">
                    {totalNeutralData.percentage}%
                  </p>
                </div>
              </div>

              <div className="bg-[#f9fafb] dark:bg-gray-800 rounded-lg p-6">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center gap-2">
                    <FaThumbsDown className="text-red-500 text-xl" />
                    <h4 className="text-md font-medium text-gray-900 dark:text-gray-100">
                      Negative Reviews
                    </h4>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <h1 className="text-4xl font-bold text-gray-900 dark:text-gray-100">
                    {totalNegativeData.count}
                  </h1>
                  <span className="text-red-500 bg-red-100 px-2 py-1 rounded-md text-xs">
                    {totalNegativeData.change.total_change}
                  </span>
                </div>
                <div className="flex w-full gap-2 items-center mt-3">
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-red-500 h-2 rounded-full"
                      style={{
                        width: `${totalPositiveData.percentage}%`,
                      }}
                    ></div>
                  </div>
                  <p className="text-xs text-gray-500">
                    {totalNegativeData.percentage}%
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-900 rounded-xl p-6 shadow-md">
          <div className="flex justify-between items-center mb-6">
            <div className="flex items-center gap-2">
              <div className="bg-yellow-100 dark:bg-yellow-800 p-2 rounded-lg">
                <FaStar className="text-yellow-500" />
              </div>
              <div>
                <h2 className="text-md font-medium text-gray-500 dark:text-gray-400">
                  Reviews
                </h2>
                <h1 className="text-4xl font-bold text-gray-900 dark:text-gray-100">
                  {googleReviews?.reviews}
                </h1>
                <p className="text-green-500 text-sm mt-1">
                  
                  <span className="text-gray-400 dark:text-gray-500">
                    {/* {`Since last ${timeRange.toLowerCase()}`} */} All time reviews
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="bg-[#f9fafb] dark:bg-gray-800 rounded-lg p-6">
            {/* Title */}
            <h4 className="text-md font-medium text-gray-900 dark:text-gray-100 mb-3">
              Average Rating
            </h4>

            {/* Rating Section */}
            <div className="flex items-center gap-4">
              {/* Rating Value */}
              <h1 className="text-5xl font-bold text-gray-900 dark:text-gray-100">
                {googleReviews?.rating.toFixed(1)}
              </h1>

              {/* Stars */}
              <div className="flex justify-center space-x-1">
                {[...Array(5)].map((_, index) => (
                  <FaStar
                    key={index}
                    size={24}
                    className={
                      index < Math.floor(googleReviews?.rating)
                        ? "text-[#F3BB21]" // Full stars
                        : index === Math.floor(googleReviews?.rating) &&
                          googleReviews?.rating % 1 >= 0.5
                        ? "text-[#F3BB21] opacity-50" // Half star
                        : "text-[#EAEAEC] dark:text-gray-500" // Empty stars with dark mode
                    }
                  />
                ))}
              </div>
            </div>

            {/* <div className="mt-6 space-y-4">
              {[
                { value: 5, count: 3500, color: "bg-green-500" },
                { value: 4, count: 2500, color: "bg-blue-500" },
                { value: 3, count: 800, color: "bg-indigo-700" },
                { value: 2, count: 500, color: "bg-yellow-500" },
                { value: 1, count: 0, color: "bg-red-500" },
              ].map((rating) => (
                <div key={rating.value} className="flex items-center gap-4">
                  <div className="w-5 text-sm font-medium">{rating.value}</div>
                  <div className="w-full bg-gray-200 rounded-full h-3">
                    <div
                      className={`${rating.color} h-3 rounded-full transition-all`}
                      style={{ width: `${(rating.count / 5000) * 100}%` }}
                    ></div>
                  </div>
                  <span className="w-12 text-right text-sm">
                    {rating.count.toLocaleString()}
                  </span>
                </div>
              ))}
            </div> */}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {cards
          .filter(
            (card) =>
              !(card.title === "Total Employees" && companyType !== "team")
          ) // Exclude the card if not a team
          .map((card, index) => (
            <div
              key={index}
              className="bg-white dark:bg-gray-800 py-2 px-4 relative rounded-lg shadow"
            >
              <div className="flex justify-between items-start">
                <div className="grid gap-4">
                  {/* Card Title */}
                  <p className="text-gray-500 dark:text-gray-300">
                    {card.title}
                  </p>

                  {/* Card Value */}
                  <h2 className="text-3xl font-medium text-gray-900 dark:text-gray-100">
                    {card.value}
                  </h2>

                  {/* Percentage and Additional Info */}
                  <div className="flex items-center mb-2">
                    {card.percentage && (
                      <span
                        className={`text-sm font-medium ${
                          card.positive === "Increase"
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {card.positive === "Increase" ? "↑" : "↓"}{" "}
                        {card.percentage}
                      </span>
                    )}
                    {card.extraInfo && (
                      <p className="text-sm">
                        <span className="text-green-500 font-medium">
                          Resolved: {issues.resolved.count}
                        </span>
                        <span className="text-red-500 ml-2">
                          Pending: {issues.non_resolved.count}
                        </span>
                      </p>
                    )}
                    <p className="text-sm text-gray-500 dark:text-gray-400 ml-2">
                      {card.change}
                    </p>
                  </div>
                </div>

                {/* Card Icon */}
                <div
                  style={{ backgroundColor: card.iconBg }}
                  className="p-3 rounded-lg"
                >
                  {card.icon}
                </div>
              </div>

              {/* Bottom Bar */}
              <div
                className={`absolute bottom-0 left-0 w-full h-[4px] ${card.color}`}
              ></div>
            </div>
          ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
        <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-md">
          <div className="flex items-center gap-3 mb-4">
            <div className="bg-green-100 dark:bg-green-900 p-2 rounded-lg">
              <FaSmile className="text-green-500 dark:text-green-300" />
            </div>
            <h2 className="text-lg font-medium text-gray-900 dark:text-gray-100">
              Overall Sentiments
            </h2>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="bg-gray-50 dark:bg-gray-700 p-5 rounded-lg grid gap-4">
              <div>
                <h4 className="text-md font-medium text-gray-500 dark:text-gray-300 mb-1">
                  Average Sentiment
                </h4>
              </div>
              <div className="flex items-center gap-2">
                {totalPositiveData.percentage >= 51 ? (
                  <h1 className="text-4xl font-bold">Good</h1>
                ) : (
                  <h1 className="text-4xl font-bold text-gray-900 dark:text-gray-100">
                    Bad
                  </h1>
                )}
                <span
                  className={`text-sm font-semibold px-1 py-1 rounded-md ${
                    totalPositiveData.percentage >= 51
                      ? "bg-green-100 text-green-500"
                      : "bg-red-100 text-red-500"
                  }`}
                >
                  {totalPositiveData.percentage >= 51
                    ? `+${totalPositiveData.percentage}%`
                    : `-${totalNegativeData.percentage}%`}
                </span>
              </div>
            </div>
            <div className="grid gap-4">
              <div className="bg-white dark:bg-gray-800 rounded-lg p-4 flex justify-between items-center shadow-sm">
                <div className="flex items-center gap-2">
                  <FaThumbsUp className="text-green-500 dark:text-green-300" />
                  <p className="text-sm text-gray-600 dark:text-gray-300">
                    Positive Sentiment
                  </p>
                </div>
                <span className="text-green-500 font-medium text-sm bg-green-100 px-2 py-1 rounded-lg">
                  {totalPositiveData.percentage.toFixed()}
                  %
                </span>
              </div>
              <div className="bg-white dark:bg-gray-800 rounded-lg p-4 flex justify-between items-center shadow-sm">
                <div className="flex items-center gap-2">
                  <FaThumbsDown className="text-red-500 dark:text-red-300" />
                  <p className="text-sm text-gray-600 dark:text-gray-300">
                    Negative Sentiment
                  </p>
                </div>
                <span className="text-red-500 dark:text-red-300 font-medium text-sm bg-red-100 dark:bg-red-900 px-2 py-1 rounded-lg">
                  {(
                    totalNegativeData.percentage +
                    totalNeutralData.percentage
                  ).toFixed()}
                  %
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-md">
          <div className="flex items-center gap-3 mb-4">
            <div className="bg-blue-100 dark:bg-blue-900 p-2 rounded-lg">
              <FaLink className="text-blue-500 dark:text-blue-300" />
            </div>
            <h2 className="text-lg font-medium text-gray-900 dark:text-gray-100">
              Link Interaction
            </h2>
          </div>

          <div className="bg-gray-50 dark:bg-gray-700 py-8 px-4 rounded-lg flex flex-row items-center justify-between shadow-sm">
            <h1 className="text-5xl font-bold text-gray-900 dark:text-gray-100">
              {taps.count || 0}
            </h1>
            <div className="flex flex-col items-center gap-2 mt-4">
              <span
                className={`flex items-center px-3 py-1 rounded-full text-sm ${
                  taps.change.type === "Increase"
                    ? "bg-green-100 text-green-500 dark:bg-green-900 dark:text-green-300"
                    : taps.change.type === "Decrease"
                    ? "bg-red-100 text-red-500 dark:bg-red-900 dark:text-red-300"
                    : "bg-gray-100 text-gray-500 dark:bg-gray-800 dark:text-gray-300"
                }`}
              >
                {taps.change.type === "Increase" ? (
                  <span className="text-green-500 dark:text-green-300">↑</span>
                ) : taps.change.type === "Decrease" ? (
                  <span className="text-red-500 dark:text-red-300">↓</span>
                ) : null}
                {` ${taps.change.percentage_change}%`}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-8">
        <div
          className="hidden md:block bg-black p-4 rounded-xl  flex flex-col justify-between relative overflow-hidden"
          style={{ boxShadow: " 0px 24px 48px -12px #1018282E" }}
        >
          <div className="flex items-center space-x-1">
            <FaWhatsapp size={20} className="text-green-500" />
            <h3 className="text-white text-sm font-medium">WhatsApp</h3>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-32">
            <h1 className="text-white text-3xl font-medium mt-2">35%</h1>

            <div className="flex flex-col items-start md:flex-row gap-8 md:items-end w-full">
              <div>
                <h4 className="text-white font-medium text-2xl">20k</h4>
                <p className="text-white text-xs">Messages</p>
              </div>

              <div className="flex items-center space-x-2">
                <div className="px-2 rounded-full flex items-center border border-green-400">
                  <span className="text-green-400 text-sm p-1 font-medium flex items-center gap-1">
                    <MdOutlineArrowCircleUp size={20} />
                    75%
                  </span>
                </div>
              </div>
            </div>
          </div>

          <svg
            className="absolute bottom-0 -right-32 w-full h-auto opacity-50 md:opacity-100"
            width="236"
            height="94"
            viewBox="0 0 236 94"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.1"
              d="M1.78718 95.6311C16.8369 102.433 32.9032 110.81 50.2852 112.391C71.7329 114.342 85.4871 100.673 97.0459 88.6012C120.309 64.3057 127.737 31.7611 156.137 10.3617C172.769 -2.17024 200.258 -1.03494 221.561 3.27687C232.12 5.4139 248.357 18.1304 252.283 25.7953"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <path
              opacity="0.4"
              d="M15.1134 116.354C30.163 123.156 46.2294 131.533 63.6114 133.114C85.0591 135.064 98.8133 121.396 110.372 109.324C133.635 85.0283 141.063 52.4838 169.464 31.0844C186.096 18.5524 213.584 19.6877 234.887 23.9995C245.446 26.1366 261.683 38.8531 265.609 46.518"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <path
              opacity="0.8"
              d="M26.1134 138.354C41.163 145.156 57.2294 153.533 74.6114 155.114C96.0591 157.064 109.813 143.396 121.372 131.324C144.635 107.028 152.063 74.4838 180.464 53.0844C197.096 40.5524 224.584 41.6877 245.887 45.9995C256.446 48.1366 272.683 60.8531 276.609 68.518"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
            />
          </svg>
        </div>

        <div className="flex flex-col gap-6 lg:flex-row items-start lg:items-center justify-between border border-gray-200 bg-white p-6 rounded-lg shadow-sm">
          <div className="flex items-center gap-4">
            <div className="bg-yellow-100 p-3 rounded-lg flex items-center justify-center">
              <FaStar size={20} className="text-yellow-500" />
            </div>
            <h2 className="text-sm font-medium text-gray-600">
              Review Collection
            </h2>
          </div>

          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between w-full lg:w-auto gap-6 sm:gap-12">
            <div className="flex flex-col items-start gap-1">
              <span className="text-green-500 text-md flex items-center gap-1 leading-tight">
                <MdOutlineArrowCircleUp size={18} />
                50%
              </span>
              <p className="text-sm text-gray-500 -mt-1">-18 Employees</p>
            </div>
            <h1 className="text-3xl sm:text-4xl font-bold text-gray-800">
              175k
            </h1>
          </div>
        </div>
      </div> */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center gap-2 mb-4">
            <div className="bg-white p-2 rounded-lg shadow w-fit">
              <FaLink className="text-blue-500" size={22} />
            </div>
            <h2 className="text-lg font-light">Conversions</h2>
          </div>
          <div className="space-y-6">
            {conversions.map((conversion, index) => (
              <div
                key={index}
                className="flex justify-between items-center border-b pb-4 last:border-b-0"
              >
                <div className="flex items-center gap-4">
                  <div
                    style={{ backgroundColor: conversion.iconBg }}
                    className="p-3 rounded-full"
                  >
                    {conversion.icon}
                  </div>
                  <div>
                    <h3 className="text-md font-medium">{conversion.title}</h3>
                    <p className="text-sm text-gray-500">
                      {conversion.description}
                    </p>
                  </div>
                </div>
                <div className="text-right">
                  <h4 className="text-xl font-medium">{conversion.value}</h4>
                  <p
                    className={`text-sm ${
                      conversion.positive ? "text-green-500" : "text-red-500"
                    }`}
                  >
                    {conversion.change}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6 flex flex-col">
          <h2 className="text-lg font-light mb-6 self-start">
            Conversion Statistics
          </h2>
          <div className="flex flex-col lg:flex-row w-full justify-between items-center gap-4">
            <div className="relative w-72 h-72 mb-6">
              <Doughnut data={data} options={options} />
              <div className="absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full shadow-lg flex items-center justify-center">
                <div className="text-center">
                  <h3 className="text-4xl font-bold">{feedbackChannels.total_feedback_from_channels}</h3>
                  <p className="text-sm text-gray-500">Feedbacks</p>
                </div>
              </div>
            </div>

            <div className="w-full flex flex-col gap-4">
              {data.labels.map((label, index) => (
                <div key={index} className="flex justify-between items-center">
                  <div className="flex items-center gap-2">
                    <div
                      className="w-3 h-3 rounded-full"
                      style={{
                        backgroundColor:
                          data.datasets[0].backgroundColor[index],
                      }}
                    ></div>
                    <p className="text-sm">{label}</p>
                  </div>
                  <h4 className="text-md font-medium">
                    {conversions[index].value}
                  </h4>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <OverallPerformance />
    </div>
  );
};

export default ReviewAnalytics2;

import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import axios from "axios";

const IncentiveBarChart = () => {
  const [distributionData, setDistributionData] = useState([]);
  const [filter, setFilter] = useState("month");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.get(
        `https://zola.technology/api/company/employees/performance/rewards/`,
        {
          params: {
            filter,
            page: currentPage,
            page_size: 5,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { data, total_pages } = response.data;
      setDistributionData(data);
      setTotalPages(total_pages);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter, currentPage]);

  return (
    <div className="dark:bg-gray-700 w-full flex flex-col justify-between h-[50vh] md:p-4 rounded-[11px] md:border border-[#EAEAEC]  w-full">
      {/* Header */}
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-[20px] font-semibold text-[#6F707E] dark:text-white">
          Incentive Distribution
        </h2>
        <div className="border rounded px-2 py-[2px]">
          <select
            value={filter}
            onChange={(e) => {
              setFilter(e.target.value);
              setCurrentPage(1);
            }}
            className="p-1 dark:bg-gray-700 bg-white dark:text-white focus:outline-none"
          >
            <option value="day">Today</option><option value="week">This week</option><option value="month">This month</option>
            <option value="year">This Year</option>
          </select>
        </div>
      </div>

      {/* Distribution Data */}
      <div className="space-y-6 flex-grow">
        {distributionData.map((item, index) => (
          <div key={index} className="flex items-center">
            {/* Employee Name */}
            <span className="text-[#454861] font-medium text-[14px] truncate">
              {item.employee.username}
            </span>
            {/* Bar and Value */}
            <div className="flex items-center w-[600px] ml-4">
              <div
                className="h-7 rounded-r-lg"
                style={{
                  width:
                    item.reward_total > 0
                      ? `${
                          (item.reward_total /
                            Math.max(
                              ...distributionData.map((d) => d.reward_total || 0)
                            )) * 100
                        }%`
                      : "0%", // No width if reward_total is 0
                  backgroundColor: item.reward_total > 0 ? "blue" : "transparent",
                }}
              ></div>
              <span className="text-[#454861] font-bold text-[14px] border ml-2 w-10 text-center">
                ${item.reward_total.toLocaleString()}
              </span>
            </div>
          </div>
        ))}
      </div>
      <hr />

      {/* Pagination */}
      <div className="flex items-center justify-between mt-6">
        {/* Previous Button */}
        <button
          className="flex items-center px-4 py-2 border border-gray-300 rounded-lg shadow-sm text-gray-700 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:text-gray-200 dark:border-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          <FaArrowLeft className="mr-2" size={20} />
          Previous
        </button>

        {/* Page Numbers */}
        <div className="flex justify-center space-x-2">
          <button
            className={`flex md:hidden px-3 py-2 rounded-lg text-sm font-medium ${
              currentPage === 1
                ? "bg-blue-100 text-blue-600 dark:bg-blue-800 dark:text-white"
                : "bg-transparent text-gray-700 hover:text-blue-600 dark:text-gray-200 dark:hover:text-blue-400"
            }`}
            disabled
          >
            {currentPage}
          </button>

          {/* Larger Screens Full Pagination */}
          <div className="hidden md:flex space-x-2">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <button
                key={page}
                onClick={() => setCurrentPage(page)}
                className={`px-3 py-2 rounded-lg text-sm font-medium ${
                  page === currentPage
                    ? "bg-blue-100 text-blue-600 dark:bg-blue-800 dark:text-white"
                    : "bg-transparent text-gray-700 hover:text-blue-600 dark:text-gray-200 dark:hover:text-blue-400"
                }`}
              >
                {page}
              </button>
            ))}
          </div>
        </div>

        {/* Next Button */}
        <button
          className="flex items-center px-4 py-2 border border-gray-300 rounded-lg shadow-sm text-gray-700 bg-white hover:bg-gray-50 dark:bg-gray-800 dark:text-gray-200 dark:border-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          Next
          <FaArrowRight className="ml-2" size={20} />
        </button>
      </div>
    </div>
  );
};

export default IncentiveBarChart;

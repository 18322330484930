import React from "react";
import { FaStar, FaEye, FaTrashAlt } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { BiSolidEditAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

// Dummy data for branches
const dummyBranches = [
  {
    id: 1,
    name: "New York Restaurant",
    imageUrl: "/images/ny-restaurant.jpg",
    employees: 200,
    performance: 75,
    issues: { good: 80, bad: 20 },
    reviews: { rating: 4.9, count: "15K" },
    engagement: 50,
    rewards: 18,
  },
  {
    id: 2,
    name: "California Restaurant",
    imageUrl: "/images/ca-restaurant.jpg",
    employees: 36,
    performance: 90,
    issues: { good: 70, bad: 30 },
    reviews: { rating: 3.8, count: "1K" },
    engagement: 90,
    rewards: 548,
  },
  {
    id: 3,
    name: "McDonald Restaurant",
    imageUrl: "/images/mcdonalds.jpg",
    employees: 20,
    performance: 55,
    issues: { good: 25, bad: 75 },
    reviews: { rating: 5.0, count: "90" },
    engagement: 99,
    rewards: 9548,
  },
];

function BranchTable({ paginatedBranches }) {
  const navigate = useNavigate();

  console.log(paginatedBranches)

  // Function to handle redirection when "Details" is clicked
  const handleDetailsClick = (branchId) => {
    // Navigate to the BranchDashboard page with the branch ID
    navigate(`/hqdashboard/branchdashboard/${branchId}`);
    console.log(`/${branchId}`)
  };
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full table-auto border-separate border-spacing-y-3">
        <thead>
          <tr className="text-gray-500 bg-gray-50">
            <th className="px-4 py-3 text-left">
              <input type="checkbox" />
            </th>
            <th className="px-4 py-3 text-left">S/N</th>
            <th className="px-4 py-3 text-left">Name</th>
            <th className="px-4 py-3 text-left">People</th>
            <th className="px-4 py-3 text-left">Performance</th>
            <th className="px-4 py-3 text-left">Issues</th>
            <th className="px-4 py-3 text-left">Reviews</th>
            <th className="px-4 py-3 text-left">Engagement</th>
            <th className="px-4 py-3 text-left">Rewards</th>
            <th className="px-4 py-3 text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          {paginatedBranches.map((branch, index) => (
            <tr
              key={branch.id}
              className="bg-white text-gray-700 rounded-lg shadow-sm hover:shadow-md transition transform"
            >
              <td className="px-4 py-3">
                <input type="checkbox" className="mr-2" />
              </td>
              <td className="px-4 py-3">
                {String(index + 1).padStart(2, "0")}
              </td>
              <td className="px-4 py-3 flex items-center gap-3">
                <img
                  src={branch.imageUrl || "https://via.placeholder.com/40"}
                  alt="branch"
                  className="w-10 h-10 rounded-full object-cover"
                />
                <span>{branch.name}</span>
              </td>
              <td className="px-4 py-3">{branch.employees.toLocaleString()}</td>
              <td className="px-4 py-3">{branch.performance}%</td>
              <td className="px-4 py-3">
                <span className="text-green-500 bg-green-100 px-2 py-1 mr-2 rounded text-xs font-semibold">
                  {branch.issues.good || 0}
                </span>
                <span className="text-red-500 bg-red-100 px-2 py-1 rounded text-xs font-semibold">
                  {branch.issues.bad || 0}
                </span>
              </td>
              <td className="px-4 py-3">
                <div className="flex items-center gap-1 bg-black text-white px-3 py-1 text-sm rounded-lg">
                  <FaStar className="text-yellow-400" />
                  {/* {branch.reviews.rating} ({branch.reviews.count}) */}
                  0
                </div>
              </td>
              <td className="px-4 py-3">{branch.engagement || 0}%</td>
              <td className="px-4 py-3">{branch.rewards || 0}</td>
              <td className="px-4 py-3 flex items-center gap-2">
                {/* View Button */}
                <button
                  aria-label="View"
                  onClick={() => handleDetailsClick(branch.id)}
                  className="bg-yellow-500 text-white p-2 rounded-full shadow-md hover:shadow-lg hover:bg-yellow-600 transition-transform transform hover:scale-110 duration-200 ease-in-out"
                >
                  <FaEye />
                </button>

                {/* Edit Button */}
                <button
                  aria-label="Edit"
                  className="bg-blue-500 text-white p-2 rounded-full shadow-md hover:shadow-lg hover:bg-blue-600 transition-transform transform hover:scale-110 duration-200 ease-in-out"
                >
                  <BiSolidEditAlt />
                </button>

                {/* Delete Button */}
                <button
                  aria-label="Delete"
                  className="bg-red-500 text-white p-2 rounded-full shadow-md hover:shadow-lg hover:bg-red-600 transition-transform transform hover:scale-110 duration-200 ease-in-out"
                >
                  <FaTrashAlt />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default BranchTable;

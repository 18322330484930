import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { NFCProvider } from "./context/NFCContext";
import { RatingProvider } from "./context/RatingContext";
import { TimeRangeProvider } from "./context/TimeRangeContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <TimeRangeProvider>
          <NFCProvider>
            <RatingProvider>
              <App />
            </RatingProvider>
          </NFCProvider>
        </TimeRangeProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Line } from "react-chartjs-2";

import {
  FaExclamationTriangle,
  FaStar,
  FaStarHalfAlt,
  FaRegStar,
} from "react-icons/fa";
import { HiChartBar } from "react-icons/hi";
import cover from "../assets/cover.png";
import { PiUsersFill } from "react-icons/pi";
import { PiCityFill } from "react-icons/pi";
import { PiChatTeardropTextFill } from "react-icons/pi";
import { PiMoneyWavyFill } from "react-icons/pi";

const BranchDashboard = () => {
  const { branchId } = useParams();
  const [feedbackData, setFeedbackData] = useState(null);
  const [branchData, setBranchData] = useState(null);
  const [ratingData, setRatingData] = useState(null);
  const [googleReviews, setGoogleReviews] = useState(null);
  const [performanceData, setPerformanceData] = useState({ labels: [], data: [] });
  const [filter, setFilter] = useState("month"); // default filter
  const [totalPerformance, setTotalPerformance] = useState(0)
  const [branchDash, setBranchdash] = useState(null)
  const [branchFeed, setBranchFeed] = useState(null)

  const fetchBranchData = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };

      // Fetch other branch data as before
      const feedbackResponse = await axios.get(
        `https://zola.technology/api/hq-company/branches/branch/feedback/get/${branchId}/`,
        { headers }
      );
      setFeedbackData(feedbackResponse.data);

      const ratingResponse = await axios.get(
        `https://zola.technology/api/hq-company/branches/branch/feedback/get-last-two/${branchId}/`,
        { headers }
      );
      setRatingData(ratingResponse.data);


      const branchDashResponse = await axios.get(
        `https://zola.technology/api/hq-company/dashboard/branches/branch/data/employees-taps-issues-performance/${branchId}/?filter=${filter}/`,
        { headers }
      );
      setBranchdash(branchDashResponse?.data);


      const branchFeedResponse = await axios.get(
        `https://zola.technology/api/hq-company/dashboard/branches/branch/data/employees-taps-issues-performance/${branchId}/?filter=${filter}/`,
        { headers }
      );
      setBranchFeed(branchFeedResponse);

      const reviewsResponse = await axios.get(
        `https://zola.technology/api/hq-company/branches/branch/get-google-reviews/${branchId}/`,
        { headers }
      );
      setGoogleReviews(reviewsResponse.data);

      const branchResponse = await axios.get(
        `https://zola.technology/api/hq-company/branches/branch/data/${branchId}/`,
        { headers }
      );
      setBranchData(branchResponse.data);

      // Fetch performance data based on filter
      const performanceResponse = await axios.get(
        `https://zola.technology/api/hq-company/branches/branch/${branchId}/performance/get-filtered-performance/?filter=${filter}`,
        { headers }
      );

      const performancePeriods = performanceResponse.data.period_performance;
      const labels = performancePeriods.map(item => item.period);
      const data = performancePeriods.map(item => item.performance_score);
      setTotalPerformance(performanceResponse.data.total_performance_score)
      setPerformanceData({ labels, data });

    } catch (error) {
      console.error("Error fetching branch data:", error);
    }
  };

  useEffect(() => {
    fetchBranchData();
  }, [branchId, filter]);

  console.log(branchId);
  console.log("branch dashboard", branchDash);
  console.log("branch data", branchData);
  console.log("google reviews", googleReviews);

  let newRatingData = googleReviews?.reviews_list.slice(0, 2);
  console.log(newRatingData, " rating welcome back");

  const calculateWidth = (count, data) => {
    const total =
      data?.rating_of_1_count +
      data?.rating_of_2_count +
      data?.rating_of_3_count +
      data?.rating_of_4_count +
      data?.rating_of_5_count;
    return total ? (count / total) * 100 : 0;
  };

  const ratingDistribution = [
    {
      rating: 5,
      count: feedbackData?.rating_of_5_count || 0,
      color: "#4CAF50",
      width: calculateWidth(feedbackData?.rating_of_5_count, feedbackData),
    },
    {
      rating: 4,
      count: feedbackData?.rating_of_4_count || 0,
      color: "#DC78FF",
      width: calculateWidth(feedbackData?.rating_of_4_count, feedbackData),
    },
    {
      rating: 3,
      count: feedbackData?.rating_of_3_count || 0,
      color: "#F5C148",
      width: calculateWidth(feedbackData?.rating_of_3_count, feedbackData),
    },
    {
      rating: 2,
      count: feedbackData?.rating_of_2_count || 0,
      color: "#F5C148",
      width: calculateWidth(feedbackData?.rating_of_2_count, feedbackData),
    },
    {
      rating: 1,
      count: feedbackData?.rating_of_1_count || 0,
      color: "#E6283B",
      width: calculateWidth(feedbackData?.rating_of_1_count, feedbackData),
    },
  ];

  const chartData = {
    labels: performanceData.labels,
    datasets: [
      {
        label: "Performance",
        data: performanceData.data,
        fill: true,
        backgroundColor: "rgba(76, 175, 80, 0.3)",
        borderColor: "#4CAF50",
        tension: 0.4,
      },
    ],
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const averageRating = googleReviews?.rating || 0;

  // Calculate the number of full stars, half stars, and empty stars
  const fullStars = Math.floor(averageRating);
  const hasHalfStar = averageRating % 1 >= 0.5;
  const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

  return (
    <div className="p-8 min-h-screen">
      {/* Branch Info */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-3">
          <h1 className="font-normal text-3xl">Locations</h1>
        </div>
        <div className="col-span-12 flex space-x-2 items-center bg-white p-4 border-b">
          <span className="bg-white p-2 rounded-xl shadow-md">
            <PiCityFill className="text-blue-400" size={24} />
          </span>
          <h2 className="text-lg font-semibold">
            {branchData?.preferred_business_name || "Business name"}
          </h2>
        </div>

        <div className="col-span-12 space-y-4 mb-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mb-8 ">
            <div className="group bg-white h-[140px] rounded-lg p-4 flex flex-col justify-between items-start  transition transform hover:scale-105 hover:bg-[#0c4ef8] hover:shadow-lg">
              <div className="flex items-center gap-3 ">
                <div className="bg-red-600 group-hover:bg-white  text-white group-hover:text-red-600  p-2 rounded-lg">
                  <FaExclamationTriangle size={24} />
                </div>
                <p className="text-gray-700 font-semibold text-sm group-hover:text-white">
                  Total <br />
                  Issues
                </p>
                <p className="text-3xl font-bold text-gray-900 group-hover:text-white">
                  {branchDash?.issues?.count || 0}
                </p>
              </div>

              <div className="flex space-x-2">
                <p className="px-2 rounded-xl bg-green-100 text-green-400 group-hover:bg-green-200 group-hover:text-green-600">
                  Resolved: {branchDash?.issues?.resolved?.count || 0}
                </p>
                <p className="px-2 rounded-xl bg-red-100 text-red-400 group-hover:bg-red-200 group-hover:text-red-600">
                  Pending: {branchDash?.issues?.non_resolved?.count || 0}
                </p>
              </div>
            </div>

            <div className="group bg-white rounded-lg p-4 h-[140px] flex flex-col justify-between items-start space-y-4 transition transform hover:scale-105 hover:bg-[#0c4ef8] hover:shadow-lg">
              <div className="flex items-center gap-3">
                <div className="bg-[#00C159] group-hover:bg-white text-white group-hover:text-[#00C159] p-2 rounded-lg">
                  <HiChartBar size={24} />
                </div>
                <p className="text-gray-700 font-semibold text-sm group-hover:text-white">
                  Average <br />
                  Performance
                </p>
              </div>
              <p className="text-3xl font-bold text-gray-900 group-hover:text-white">
                {branchDash?.performance?.score}%
              </p>
            </div>

            <div className="group bg-white rounded-lg h-[140px] xs:h-[150px] lg:p-4 xs:p-4 flex flex-col justify-between items-start space-y-4 transition transform hover:scale-105 hover:shadow-lg">
              <div className="flex items-center gap-3">
                <div className="bg-blue-600 group-hover:bg-white text-white group-hover:text-blue-600 p-2 rounded-lg">
                  <PiUsersFill size={24} />
                </div>
                <p className="text-gray-700 font-semibold text-sm group-hover:text-white">
                  Total <br />
                  Employees
                </p>
              </div>
              <p className="text-3xl font-bold text-gray-900 group-hover:text-white">
                {branchData?.total_employees || 0}
              </p>
              <style jsx>{`
                .group:hover {
                  background-image: url(${cover});
                  background-size: cover;
                  background-position: center;
                  background-repeat: no-repeat;
                }
              `}</style>
            </div>

            {/* <div className="group bg-white rounded-lg h-[140px] xs:h-[150px] lg:p-4 xs:p-4 flex flex-col justify-between items-start space-y-4 transition transform hover:scale-105 hover:shadow-lg">
              <div className="flex items-center gap-3">
                <div className="bg-[#FFB11A] group-hover:bg-white text-white group-hover:text-[#FFB11A] p-2 rounded-lg">
                  <PiChatTeardropTextFill size={24} />
                </div>
                <p className="text-gray-700 font-semibold text-sm group-hover:text-white">
                  Total <br />
                  Messages
                </p>
              </div>
              <p className="text-3xl font-bold text-gray-900 group-hover:text-white">
                125K
              </p>
              <style jsx>{`
                .group:hover {
                  background-image: url(${cover});
                  background-size: cover;
                  background-position: center;
                  background-repeat: no-repeat;
                }
              `}</style>
            </div>

            <div className="group bg-white h-[140px] rounded-lg xs:h-[150px] lg:p-4 xs:p-4 flex flex-col justify-between items-start  transition transform hover:scale-105 hover:shadow-lg">
              <div className="flex items-center gap-3">
                <div className="bg-[#DC78FF] group-hover:bg-white text-white group-hover:text-[#DC78FF] p-2 rounded-lg">
                  <PiMoneyWavyFill size={24} />
                </div>
                <p className="text-gray-700 font-semibold text-sm group-hover:text-white">
                  Total <br />
                  Rewards
                </p>
                <p className="text-3xl font-bold text-gray-900 group-hover:text-white">
                  103
                </p>
              </div>

              <div className="flex space-x-2">
                <p className="px-2 rounded-xl bg-green-100 text-green-400 group-hover:bg-green-200 group-hover:text-green-600">
                  Disburded: 78
                </p>
                <p className="px-2 rounded-xl bg-red-100 text-red-400 group-hover:bg-red-200 group-hover:text-red-600">
                  Pending: 78
                </p>
              </div>
              <style jsx>{`
                .group:hover {
                  background-image: url(${cover});
                  background-size: cover;
                  background-position: center;
                  background-repeat: no-repeat;
                }
              `}</style>
            </div>

            <div className="group bg-white h-[140px] rounded-lg xs:h-[150px] lg:p-4 xs:p-4 flex flex-col justify-between items-start space-y-4 transition transform hover:scale-105 hover:shadow-lg">
              <div className="flex items-center gap-3">
                <div className="bg-[#DC78FF] group-hover:bg-white text-white group-hover:text-[#DC78FF] p-2 rounded-lg">
                  <PiMoneyWavyFill size={24} />
                </div>
                <p className="text-gray-700 font-semibold text-sm group-hover:text-white">
                  Total <br />
                  Rewards amount
                </p>
              </div>
              <p className="text-3xl font-bold text-gray-900 group-hover:text-white">
                $125K
              </p>
              <style jsx>{`
                .group:hover {
                  background-image: url(${cover});
                  background-size: cover;
                  background-position: center;
                  background-repeat: no-repeat;
                }
              `}</style>
            </div> */}
          </div>
        </div>
      </div>

      {/* Performance Overview */}
      <div className="bg-white p-6 mt-6 shadow-md rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Performance Overview: {totalPerformance}%</h2>
        <select
          className="p-2 border rounded-md"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          <option value="week">Weekly</option>
          <option value="month">Monthly</option>
          <option value="year">Yearly</option>
        </select>
      </div>
      <Line data={chartData} options={chartOptions} />
    </div>

      {/* Cards Section below Graph */}
      <h2 className="text-lg font-semibold mt-4">Reviews</h2>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-6">
        {/* Total Reviews */}
        <div className="bg-white p-6 shadow rounded-lg flex flex-col justify-between">
          <p
            className="text-gray-500 font-semibold text-[16px]"
            style={{ lineHeight: "19.36px" }}
          >
            Total Reviews
          </p>
          <p
            className="font-semibold text-[32px] mt-2"
            style={{ lineHeight: "38.73px" }}
          >
            {googleReviews?.reviews || 0}
          </p>
        </div>

        {/* Average Rating */}
        <div className="bg-white p-6 shadow rounded-lg flex flex-col justify-between">
          <p
            className="text-gray-500 font-semibold text-[16px]"
            style={{ lineHeight: "19.36px" }}
          >
            Average Rating
          </p>
          <p
            className="font-semibold text-[32px] mt-2 flex items-center"
            style={{ lineHeight: "38.73px" }}
          >
            {averageRating.toFixed(1)}
            <span className="ml-2 flex">
              {/* Render full stars */}
              {[...Array(fullStars)].map((_, i) => (
                <FaStar key={`full-${i}`} className="w-8 h-8 text-yellow-500" />
              ))}

              {/* Render half star if needed */}
              {hasHalfStar && (
                <FaStarHalfAlt className="w-8 h-8 text-yellow-500" />
              )}

              {/* Render empty stars */}
              {[...Array(emptyStars)].map((_, i) => (
                <FaRegStar
                  key={`empty-${i}`}
                  className="w-8 h-8 text-gray-300"
                />
              ))}
            </span>
          </p>
        </div>

        {/* Rating Distribution */}
        <div className="bg-white p-4 shadow rounded-lg flex flex-col justify-between">
          <p
            className="text-gray-500 font-semibold text-[16px]"
            style={{ lineHeight: "19.36px" }}
          >
            Feedbacks Distribution
          </p>
          <div className="mt-4">
            {ratingDistribution.map(
              ({ rating, count, color, width }, index) => (
                <div className="flex justify-between items-center" key={index}>
                  <div className="flex items-center space-x-2 w-full">
                    <span className="text-sm w-8">{rating}</span>
                    <div className="relative flex-1">
                      {/* Placeholder background */}
                      <div
                        className="h-2 rounded-lg bg-gray-100"
                        style={{ width: "100%", maxWidth: "100%" }}
                      ></div>
                      {/* Colored bar */}
                      <div
                        className="h-2 rounded-lg absolute top-0 left-0"
                        style={{
                          backgroundColor: color,
                          width: `${width}%`,
                          maxWidth: "100%",
                        }}
                      ></div>
                    </div>
                  </div>
                  <span className="text-sm ml-4">{count}</span>
                </div>
              )
            )}
          </div>
        </div>
      </div>

      {/* Reviews Section */}
      <div className="mt-6 space-y-4">
        {newRatingData?.map(
          ({ author_name, author_image, rating, text_info }, index) => (
            <div
              key={index} // Use index as a key for unique identification
              className="bg-white p-6 rounded-lg flex justify-between space-x-6"
            >
              <div className="flex items-start space-x-4 w-1/5 ">
                <img
                  className="w-16 h-16 rounded-full object-cover"
                  src={
                    author_image
                      ? author_image
                      : "https://img.freepik.com/premium-vector/stylish-default-user-profile-photo-avatar-vector-illustration_664995-352.jpg"
                  } // Show author_image if exists, else show placeholder
                  alt={author_name || "Anonymous"}
                />
                <p className="font-semibold text-lg">
                  {author_name || "Anonymous"}
                </p>
                {/* Handle missing names */}
              </div>
              <div className="w-4/5">
                <div className="flex items-center mb-2">
                  <span className="text-[#282A3E] text-[18px] font-semibold text-lg mr-2">
                    {rating.toFixed(1)}
                  </span>
                  <div className="flex">
                    {[...Array(rating)].map((_, i) => (
                      <svg
                        key={i}
                        className="w-5 h-5 text-yellow-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.035 3.185a1 1 0 00.95.69h3.347c.969 0 1.371 1.24.588 1.81l-2.711 1.97a1 1 0 00-.364 1.118l1.035 3.185c.3.921-.755 1.688-1.538 1.118l-2.711-1.97a1 1 0 00-1.176 0l-2.711 1.97c-.783.57-1.838-.197-1.538-1.118l1.035-3.185a1 1 0 00-.364-1.118l-2.711-1.97c-.783-.57-.38-1.81.588-1.81h3.347a1 1 0 00.95-.69l1.035-3.185z" />
                      </svg>
                    ))}
                    {/* Empty Star for remaining stars */}
                    {Array(5 - rating)
                      .fill(null)
                      .map((_, i) => (
                        <svg
                          key={i}
                          className="w-5 h-5 text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.035 3.185a1 1 0 00.95.69h3.347c.969 0 1.371 1.24.588 1.81l-2.711 1.97a1 1 0 00-.364 1.118l1.035 3.185c.3.921-.755 1.688-1.538 1.118l-2.711-1.97a1 1 0 00-1.176 0l-2.711 1.97c-.783.57-1.838-.197-1.538-1.118l1.035-3.185a1 1 0 00-.364-1.118l-2.711-1.97c-.783-.57-.38-1.81.588-1.81h3.347a1 1 0 00.95-.69l1.035-3.185z" />
                        </svg>
                      ))}
                  </div>
                </div>
                <p className="text-gray-600 text-sm">
                  {text_info || "No feedback provided."}
                </p>
                {/* Handle missing comments */}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default BranchDashboard;

import React, { useContext, useState } from "react";
import { BranchContext } from "../context/BranchContext";
import {
  FaTh,
  FaList,
  FaLongArrowAltRight,
  FaSearch,
  FaFilter,
  FaDownload,
} from "react-icons/fa";
import CreateBranchModal from "./CreateBranchModal";
import BranchTable from "./BranchTable";
import BranchGrid from "./BranchGrid";
import { PiFunnel } from "react-icons/pi";
import { IoCaretDownOutline, IoCaretUpOutline } from "react-icons/io5";
import { FaLongArrowAltLeft } from "react-icons/fa";
import cover from "../assets/cover.png";
import { PiUsersFill } from "react-icons/pi";
import { HiChartBar } from "react-icons/hi";
import { FaExclamationTriangle, FaBuilding } from "react-icons/fa";
import { PiCityFill } from "react-icons/pi";
import { PiChatTeardropTextFill } from "react-icons/pi";
import { PiMoneyWavyFill } from "react-icons/pi";
import { IoIosArrowDown } from "react-icons/io";
import OverviewSection from "../components/overview/OverviewSection";

function Branches({ showSearchAndCreate = true, showOverview = true }) {
  const { branches, employeeTaps } = useContext(BranchContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewMode, setViewMode] = useState("table"); // Default to table view
  const [sortBy, setSortBy] = useState("name");
  const [currentPage, setCurrentPage] = useState(1);

  const [isOpen, setIsOpen] = useState(false);

  // Calculate statistics
  const totalEmployees = branches.reduce(
    (total, branch) => total + branch.employees,
    0
  );
  const totalIssues = branches.reduce(
    (total, branch) => total + branch.issues,
    0
  );

  const averagePerformance = (
    branches.reduce(
      (total, branch) => total + parseFloat(branch.performance),
      0
    ) / branches.length
  ).toFixed(2);
  const totalBranches = employeeTaps.total;

  const resolvedIssues = branches.filter(
    (branch) => branch.issues === 0
  ).length;

  const pendingIssues = branches.filter((branch) => branch.issues > 0).length;

  const activeBranches = branches.filter(
    (branch) => branch.status === "Active"
  ).length;
  const pendingBranches = branches.filter(
    (branch) => branch.status === "Pending"
  ).length;

  // Data for the bar chart
  const employeeData = branches.map((branch) => ({
    name: branch.name,
    employees: branch.employees,
  }));

  const handleSelect = (value) => {
    setSortBy(value);
    setIsOpen(false);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFormSubmit = (formData) => {
    console.log(formData);
  };

  const filteredBranches = branches
    .filter((branch) =>
      branch.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (sortBy === "name") return a.name.localeCompare(b.name);
      if (sortBy === "performance") return b.performance - a.performance;
      if (sortBy === "employees") return b.employees - a.employees;
      return 0;
    });

  const itemsPerPage = 10;
  console.log(filteredBranches);
  const paginatedBranches = filteredBranches.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  console.log(paginatedBranches)
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(filteredBranches.length / itemsPerPage);

  return (
    <>
      <OverviewSection title="Branches" showExportButton={false} />

      <div className="grid bg-white p-4 rounded-lg shadow-sm">
        <div className="flex justify-between items-center">
          <h2 className="text-md font-medium text-gray-700">Branches</h2>

          <div className="flex items-center gap-4">
            <div className="relative">
              <FaSearch className="absolute left-3 top-3.5 text-gray-400" />
              <input
                type="text"
                placeholder="Search anything here"
                value={searchTerm}
                onChange={handleSearchChange}
                className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>

            <button className="flex items-center gap-2 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-100 transition">
              <FaFilter className="text-gray-500" />
              Filter
            </button>

            {/* Search and Create Section */}
            {showSearchAndCreate && (
              <div className="flex flex-wrap justify-between items-center space-y-4 md:space-y-0">
                <div className="flex items-center space-x-4">
                  <button
                    aria-label="Create New Location"
                    onClick={handleOpenModal}
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 w-full md:w-auto"
                  >
                    Create New Location
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <div className="flex flex-wrap justify-between items-center mb-4 shadow-lg p-4 bg-white rounded-lg space-y-4 lg:space-y-0">
        <div className="flex items-center space-x-2 flex-wrap">
          <button
            className={`p-2 transition ${
              viewMode === "grid" ? "bg-blue-600" : "bg-gray-300"
            } text-white rounded-lg hover:bg-blue-700 focus:outline-none`}
            onClick={() => setViewMode("grid")}
            aria-label="Grid View"
          >
            <FaTh />
          </button>
          <button
            className={`p-2 transition ${
              viewMode === "table" ? "bg-blue-600" : "bg-gray-300"
            } text-white rounded-lg hover:bg-blue-700 focus:outline-none`}
            onClick={() => setViewMode("table")}
            aria-label="Table View"
          >
            <FaList />
          </button>

          <div className="relative inline-block text-left">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="flex items-center space-x-2 p-2 border border-gray-300 rounded-lg bg-white text-gray-700 hover:bg-gray-100 transition w-full md:w-auto"
              aria-label="Sort"
            >
              <PiFunnel className="text-lg" />
              <span>Sort by</span>
              {isOpen ? (
                <IoCaretUpOutline className="text-sm" />
              ) : (
                <IoCaretDownOutline className="text-sm" />
              )}
            </button>

            {isOpen && (
              <div className="absolute z-10 mt-2 w-48 bg-white rounded-lg shadow-lg dark:bg-gray-800">
                <ul className="py-1 text-sm text-gray-700 dark:text-gray-200">
                  {[
                    "date",
                    "high-performance",
                    "name",
                    "issues",
                    "engagement",
                    "rewards",
                  ].map((item) => (
                    <li
                      key={item}
                      onClick={() => handleSelect(item)}
                      className="cursor-pointer px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      {item.charAt(0).toUpperCase() +
                        item.slice(1).replace("-", " ")}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center space-x-4 flex-wrap justify-end w-full md:w-auto">
          <button
            onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
            disabled={currentPage === 1}
            className="p-2 bg-gray-300 rounded-lg hover:bg-gray-400 transition disabled:opacity-50"
            aria-label="Previous Page"
          >
            <FaLongArrowAltLeft />
          </button>
          <span className="text-gray-700 font-medium">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() =>
              handlePageChange(Math.min(currentPage + 1, totalPages))
            }
            disabled={currentPage === totalPages}
            className="p-2 bg-gray-300 rounded-lg hover:bg-gray-400 transition disabled:opacity-50"
            aria-label="Next Page"
          >
            <FaLongArrowAltRight />
          </button>

          <button
            aria-label="See All"
            className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition flex items-center"
          >
            See All <IoCaretDownOutline className="ml-2" />
          </button>
        </div>
      </div> */}

        {/* Branch Details Section */}
        {viewMode === "table" ? (
          <BranchTable paginatedBranches={paginatedBranches} />
        ) : (
          <BranchGrid paginatedBranches={paginatedBranches} />
        )}

        {/* Modal Component */}
        <CreateBranchModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSubmit={handleFormSubmit}
        />
      </div>
    </>
  );
}

export default Branches;

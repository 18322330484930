import React, { useEffect, useState, useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import PublicLayout from "./PublicLayout";
import DashboardLayout from "./DashboardLayout";
import Homepage from "./pages/Homepage";
import Login from "./pages/Login/Login";
import SignUp from "./pages/SignUp/SignUp";
import Pricing from "./pages/Pricing/Pricing";
import Features from "./pages/Features/Features";
import DashboardPage from "./New-Dashboard/components/DashboardPage";
import Reviews from "./New-Dashboard/components/Reviews";
import Feedback from "./New-Dashboard/components/Feedback";
import Reward from "./New-Dashboard/components/Reward";
import Sentiment from "./New-Dashboard/components/Sentiment";
import Analytics from "./New-Dashboard/components/Analytics";
import TeamPerformance from "./New-Dashboard/components/TeamPerformance";
import Form from "./components/Form/Form";
import Emoji from "./pages/Emojis/Emoji";
import FeedbackPage from "./pages/Feedback-Page/FeedbackPage";
import NFCReaderComponent from "./components/NFCReader/NFCReader";
import MultiStepForm from "./components/MultiStep-Form/MultiStepForm";
import { AuthContext } from "./context/AuthContext";
import Success from "./components/PaymentStatus/Success";
import Failed from "./components/PaymentStatus/Failed";
import {
  CompanyProfile,
  TeamManagement,
  AddEmployee,
  CardSetup,
} from "./companydashboard/index";
import SignupSuccess from "./pages/SignUp/SignUpSuccess";
import AuthenticatedNavbar from "./components/AuthenticatedNavbar/AuthNavbar";
import SuccessPage from "./components/Form/Success";
import LoadingDots from "./Loader";
import ReviewPrompt from "./pages/Emojis/PositiveReview";
import NegativeReviewPrompt from "./pages/Emojis/NegativeReview";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TermsOfService from "./pages/TermsOfService/TermsOfService";
import AccountSettings from "./New-Dashboard/components/Setting";
import {
  Members,
  Billing,
  Notification,
  Language,
  Account,
} from "./New-Dashboard/components/AccountSettings/index";
import EmployeeRating from "./pages/Emojis/EmployeeRating";
import Blog from "./pages/Blog/Blog";
import BlogDetails from "./pages/Blog/BlogDetails";
import Profile from "./HQDashboard/pages/Profile";
import Settings from "./HQDashboard/pages/Settings";
import Dashboard from "./HQDashboard/pages/Dashboard";
import HQDashboardLayout from "./HQDashboardLayout";
import HQAnalytics from "./HQDashboard/pages/Analytics";
import PaymentPage from "./New-Dashboard/components/AccountSettings/PaymentDetailsPage";
import Branches from "./HQDashboard/pages/Branches";
// import Survey from "./HQDashboard/pages/Survey";
import Customers from "./pages/Customers/Customers";
import CompanyDetails from "./pages/Emojis/CompanyDetails";
import MobilePreviewPage from "./New-Dashboard/components/MobilePreviewPage";
import HelpAndSupport from "./New-Dashboard/components/HelpAndSupport";
import HelpDetails from "./New-Dashboard/components/HelpDetails";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import OtpVerification from "./pages/ForgotPassword/Otp";
import ForgetPassword from "./pages/ForgotPassword";
import CreateAccount from "./New-Dashboard/components/CreateAccount/CreateAccount";
import Branch from "./New-Dashboard/components/Branch";
import AccountTypePage from "./pages/SignUp/AccountTypePage";
import BookingPage from "./pages/SignUp/BookingPage";
import BranchDashboard from "./HQDashboard/pages/BranchDashboard";
import Messages from "./HQDashboard/pages/Messages";
import WhyZola from "./pages/WhyZola/WhyZola";
// import Pricing from "./pages/Pricing/Pricing";
import Employees from "./New-Dashboard/components/Employees";
import EmployeeProfile from "./New-Dashboard/components/EmployeeProfile";
import CompanyEmployees from "./New-Dashboard/components/CompanyEmployees";
import Survey from "./New-Dashboard/components/Survey";
import SurveyDetail from "./New-Dashboard/components/SurveyDetail";
import MobileSurvey from "./pages/SurveyPreview/MobileSurvey";
import MobileSurveyPrompt from "./mobile/pages/MobileSurvey";
import Payment from "./New-Dashboard/components/Payment/Payment";
import Subscriptions from "./New-Dashboard/components/Subscriptions/Subscriptions";
import CustomerTable from "./New-Dashboard/components/CustomerTable";
import Companies from "./New-Dashboard/components/Companies";
import SignUp2 from "./pages/SignUp/SignUp2";
import Login2 from "./pages/Login/Login2";
import CompanyDetail from "./New-Dashboard/components/CompanyDetail";
import ScrollToTop from "./ScrollToTop";
import AIInsights from "./New-Dashboard/components/AIInsights";
import RequestFeedback from "./New-Dashboard/components/RequestFeedback";
import AIChat from "./New-Dashboard/components/AIChat";
import SurveyAnalysis from "./New-Dashboard/components/SurveyAnalysis";
import AnalyticsDashboard from "./New-Dashboard/components/Surveyanalytics/Analytics";
import SuccessSurveyMessage from "./pages/SurveyPreview/SuccessSurveyMessage";

function App() {
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const paymentStatus = "success"; // or 'failure'

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && !currentUser?.token) {
      // Only set if currentUser is not already set
      setCurrentUser({ token }); // Replace this with actual user data
    }
    setIsCheckingAuth(false); // Adding a delay for the loader
  }, [currentUser, setCurrentUser]); // Include currentUser to check if it's already set

  if (isCheckingAuth) {
    return;
  }

  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            currentUser ? <Navigate to="/dashboard" /> : <PublicLayout />
          }
        >
          <Route index element={<Homepage />} />
          {/* {<Route path="/login" element={<Login />} />} */}
          {/* {<Route path="/signup" element={<SignUp />} />} */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/account-type" element={<AccountTypePage />} />
          <Route path="/book" element={<BookingPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogDetails />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/why-zola" element={<WhyZola />} />
          <Route path="/features" element={<Features />} />
          <Route path="/scan" element={<NFCReaderComponent />} />
          <Route path="/success" element={<Success status={paymentStatus} />} />
          <Route path="/failed" element={<Failed status={paymentStatus} />} />
        </Route>

        <Route
          path="/dashboard/*"
          element={currentUser ? <DashboardLayout /> : <Navigate to="/" />}
        >
          <Route index element={<DashboardPage />} />
          <Route path="reviews" element={<Reviews />} />
          <Route path="feedback" element={<Feedback />} />
          <Route path="reward" element={<Reward />} />
          <Route path="sentiment" element={<Sentiment />} />
          <Route path="analytics" element={<Analytics />} />
          <Route path="team-performance" element={<TeamPerformance />} />
          <Route path="mobile-preview" element={<MobilePreviewPage />} />
          <Route path="customer-data" element={<CustomerTable />} />
          <Route path="companyprofile" element={<CompanyProfile />} />
          <Route path="teammanagement" element={<TeamManagement />} />
          <Route path="addemployee" element={<AddEmployee />} />
          <Route path="create-account" element={<CreateAccount />} />
          <Route path="payment" element={<Payment />} />
          <Route path="subscriptions" element={<Subscriptions />} />
          <Route path="help" element={<HelpAndSupport />} />
          <Route path="companyemployees" element={<CompanyEmployees />} />
          <Route path="help/:id" element={<HelpDetails />} />
          <Route path="employees" element={<Employees />} />
          <Route path="surveyanalytics" element={<AnalyticsDashboard/>} />
          <Route path="companies" element={<Companies />} />
          <Route path="companies/:id" element={<CompanyDetail />} />
          <Route path="ai-insights" element={<AIInsights />} />
          <Route path="ai-chat" element={<AIChat />} />
          <Route path="request-feedback" element={<RequestFeedback />} />
          <Route
            path="employee-profile/:employeeId"
            element={<EmployeeProfile />}
          />
          <Route path="survey" element={<Survey />} />
          <Route path="survey-analysis" element={<SurveyAnalysis />} />
          <Route path="survey/:surveyId" element={<SurveyDetail />} />
          <Route path="branch" element={<Branch />} />
          <Route path="setting/*" element={<AccountSettings />}>
            <Route index element={<Account />} />
            <Route path="notification" element={<Notification />} />
            <Route path="language" element={<Language />} />
            <Route path="billing" element={<Billing />} />
            <Route path="update-details" element={<PaymentPage />} />
            {/* <Route path="*" element={<Navigate to="" />} />  */}
          </Route>
        </Route>

        {/* Route for the HQ Dashboard as per now */}
        <Route
          path="/hqdashboard/*"
          element={
            currentUser ? <HQDashboardLayout /> : <Navigate to="/login" />
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="analytics" element={<HQAnalytics />} />
          <Route path="profile" element={<Profile />} />
          <Route path="settings" element={<Settings />} />
          <Route path="branches" element={<Branches />} />
          <Route path="messages" element={<Messages />} />
          {/* <Route path="survey" element={<Survey />} /> */}
          <Route
            path="branchdashboard/:branchId"
            element={<BranchDashboard />}
          />
        </Route>

        {/* Route for SignupSuccess with AuthenticatedNavbar */}
        <Route
          path="/signup-success"
          element={
            <>
              <AuthenticatedNavbar />
              <SignupSuccess />
            </>
          }
        />
        <Route
          path="/feedback/:company_username/:employee_username/:channel?"
          element={<CompanyDetails />}
        />
        <Route
          path="/employee_rating/:company_username/:employee_username/:channel?"
          element={<EmployeeRating />}
        />
        <Route
          path="/emoji/:company_username/:employee_username/:channel?"
          element={<Emoji />}
        />
        <Route
          path="/feedback/:company_username/:employee_username/:emoji/:channel?"
          element={<FeedbackPage />}
        />
        <Route
          path="/review-prompt/:company_username/:employee_username/:emoji/:channel?"
          element={<ReviewPrompt />}
        />
        <Route
          path="/negative-review-prompt/:company_username/:employee_username/:emoji/:channel?"
          element={<NegativeReviewPrompt />}
        />
        <Route
          path="/survey/:company_username/:channel?"
          element={<MobileSurvey />}
        />
        <Route
          path="/survey/success"
          element={<SuccessSurveyMessage />}
        />
        <Route
          path="/feedback/:company_username/:employee_username/:emoji/:channel?/make-a-zola-review"
          element={<Form />}
        />

        <Route path="/success-page" element={<SuccessPage />} />
        <Route path="forgotpassword" element={<ForgetPassword />} />
        <Route path="otppage" element={<OtpVerification />} />

        {/* Route for MultiStepForm with AuthenticatedNavbar */}
        <Route
          path="/setup-form"
          element={
            <>
              <AuthenticatedNavbar />
              <MultiStepForm />
            </>
          }
        />

        {/* Catch-all route to redirect unknown paths to home */}
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/mobile-survey/:company_username/:channel?" element={<MobileSurveyPrompt />} />
      </Routes>
    </div>
  );
}

export default App;

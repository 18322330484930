import { Link, useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import PerformanceDashboard from "./TeamPerformance/PerformanceDashboard";
import OverallPerformance from "./TeamPerformance/OverallPerformance";
import EmployeeOverallPerformance from "./TeamPerformance/EmployeeOverallPerformance";
import Overview from "./Overview";
import FeedbackSection from "./Analytics/FeedbackSection";
import AnalyticsStatCard from "./AnalyticsStatCards";
import { toPng, toCanvas } from "html-to-image";
import { jsPDF } from "jspdf";

const Analytics = () => {
  const [feedbackData, setFeedbackData] = useState([]);
  const { isExpired } = useOutletContext();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      window.location.href = "/login";
    }

    const getFeedBackData = async () => {
      try {
        const response = await fetch(
          "https://zola.technology/api/company/feedback/list-categorised/",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setFeedbackData(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching feedback data:", error);
      }
    };

    getFeedBackData();
  }, []);

  // Retrieve userData from localStorage
  const userData = JSON.parse(localStorage.getItem("userData"));

  // Get company_type from userData (default to an empty string if userData is null)
  const companyType = userData?.company_type || "";

    const handleExport = async (format) => {
      const element = document.getElementById("exportable-analytics-content");
  
      if (format === "pdf") {
        const pdf = new jsPDF();
        const canvas = await toCanvas(element);
        const imgData = canvas.toDataURL("image/png");
        pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
        pdf.save("analytics.pdf");
      } else if (format === "doc") {
        const htmlContent = element.innerHTML;
        const blob = new Blob([htmlContent], { type: "application/msword" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "analytics.doc";
        link.click();
      } else if (format === "image") {
        const dataUrl = await toPng(element);
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "analytics.png";
        link.click();
      }
    };

  return (
    <div className="md:p-2 bg-[#F5F8FF] dark:bg-gray-900" id="exportable-analytics-content">
      <div className={`relative ${isExpired ? "blur-sm" : ""}`}>
        <Overview title="Analytics" onExportClick={handleExport} />

        <FeedbackSection />

        <OverallPerformance />

        <AnalyticsStatCard />

        {companyType === "team" && <EmployeeOverallPerformance />}
        <PerformanceDashboard />

        <div className="grid gap-2"></div>
      </div>
      {isExpired && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-80 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-8 text-center shadow-lg max-w-md">
            <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
              Subscription Expired
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-6">
              Your subscription has expired. Please renew to regain full access
              to the dashboard.
            </p>
            <Link to="/dashboard/setting/billing">
              <button
                aria-label="Go to Billing"
                className="bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-600 transition-colors"
              >
                Go to Billing
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Analytics;

import { useContext, useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosNotificationsOutline } from "react-icons/io";
import { FaChevronDown, FaSearch } from 'react-icons/fa';
import { AuthContext } from '../../../context/AuthContext';

const Topbar = ({ isClosing, mobileOpen, setMobileOpen, logout }) => {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const dropdownRef = useRef(null);  // Ref for dropdown

  const handleSwitchDashboard = () => {
    navigate("/dashboard", { replace: true });
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  let userData = JSON.parse(localStorage.getItem('userData'));

  return (
    <div className="flex items-center justify-between px-6 py-4 lg:bg-white border-b border-[#EAEAEC] shadow-md dark:shadow-lg dark:bg-gray-900 dark:border-gray-700 xs:bg-[#f5f8ff] md:bg-[#f5f8ff]">  
      <div className={`lg:flex items-center text-3xl ${searchVisible ? 'flex' : 'hidden'}`}>
        <FaSearch className="text-gray-400 text-xl mr-2 ml-8" />
        <input
          type="text"
          placeholder="Search anything here"
          className="w-full outline-none bg-transparent text-sm text-gray-500"
        />
      </div>

      {!searchVisible && (
        <button
          onClick={() => setSearchVisible(true)}
          className="lg:hidden ml-8 p-2 text-gray-500 focus:outline-none"
          aria-label="Open search"
        >
          <FaSearch className="text-xl" />
        </button>
      )}

      <div className="flex items-center space-x-4">
        <button
          aria-label="Notifications"
          className="p-2 text-gray-700 dark:text-gray-300"
        >
          <IoIosNotificationsOutline className="text-3xl" />
        </button>

        <div className="h-8 w-[2px] bg-gray-400 hidden lg:block"></div>

        <div className="relative" ref={dropdownRef}>
          <button
            onClick={toggleDropdown}
            className="flex items-center space-x-2 focus:outline-none"
          >
            <img
              src={userData.profile_image}
              alt="User Avatar"
              className="w-8 h-8 rounded-full object-cover"
            />
            <span className="hidden lg:block text-gray-800 dark:text-white">{userData.username}</span>
            <FaChevronDown className="text-gray-600 dark:text-gray-400" />
          </button>

          {dropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg dark:bg-gray-800 dark:border-gray-700 z-50">
              <ul className="py-2">
                <li>
                  <button
                    onClick={handleSwitchDashboard}
                    className="block w-full px-4 py-2 text-center text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-700"
                  >
                    Switch to Dashboard
                  </button>
                </li>
                <li>
                  <button 
                    className="block w-full text-center px-4 py-2 text-sm text-red-500 hover:bg-gray-100 dark:hover:bg-gray-700"
                    onClick={() => logout()}
                  >
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Topbar;

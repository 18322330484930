import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentDetailsForm from './PaymentDetailsForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PaymentCard from './PaymentCard';

// Load your Stripe publishable key
const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

const PaymentPage = () => {
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false); // State to control showing the form
  const [cards, setCards] = useState([]); // State to hold fetched payment methods

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      const token = localStorage.getItem("token");
  
      try {
        setLoading(true);
        const response = await fetch('https://zola.technology/api/company/payment-methods/list/', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          throw new Error('Failed to fetch payment methods');
        }
  
        const data = await response.json();
        setCards(data);
        if (data.length === 0) {
          setShowForm(true);
        }
      } catch (error) {
        console.error(`Error: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentMethods();
  }, [])

  const handlePaymentMethodReceived = async (paymentMethodId) => {
    const token = localStorage.getItem("token");
    
    try {
      setLoading(true);
      const response = await fetch('https://zola.technology/api/company/stripe-payment-details/update/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ payment_method_id: paymentMethodId }),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Something went wrong.');
      }
      
      toast.success('Payment method updated successfully!');
      setShowForm(false); // Switch back to showing the card after updating
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Elements stripe={stripePromise}>
      {showForm || cards.length === 0 ? (
          <PaymentDetailsForm onPaymentMethodReceived={handlePaymentMethodReceived} />
        ) : (
          cards.map((card) => (
            <PaymentCard 
              key={card.id}
              card={card}
              onAddCardClick={() => setShowForm(true)}
            />
          ))
        )}
      </Elements>
      <ToastContainer />
    </>
  );
};

export default PaymentPage;

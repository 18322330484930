import React, { useState } from "react";
import {
  FaEdit,
  FaRobot,
  FaSort,
  FaSortDown,
  FaSortUp,
  FaTrash,
} from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";

const data = [
  {
    id: 1,
    name: "Isagi Yoichi",
    phone: "+001293752665",
    createdDate: "25 Dec 2023",
  },
  {
    id: 2,
    name: "Alexandro Bernard",
    phone: "+3991408219119",
    createdDate: "05 Jul 2023",
  },
  {
    id: 3,
    name: "Nagi Seishiro",
    phone: "+120307669859",
    createdDate: "10 Jan 2020",
  },
  {
    id: 4,
    name: "Lily Alexa",
    phone: "+4141383140744",
    createdDate: "12 May 2021",
  },
  {
    id: 5,
    name: "Romanov Ely",
    phone: "+1347328879201",
    createdDate: "12 Jul 2021",
  },
  {
    id: 6,
    name: "Kitty Pup",
    phone: "+9261135296406",
    createdDate: "12 Apr 2021",
  },
  {
    id: 7,
    name: "Shasa Brown",
    phone: "+220198915198",
    createdDate: "23 Jun 2021",
  },
  {
    id: 8,
    name: "Orik Pion",
    phone: "+010723184455",
    createdDate: "25 Dec 2023",
  },
  {
    id: 9,
    name: "Kaiser Brown",
    phone: "+2953356960479",
    createdDate: "08 May 2021",
  },
];

function RequestFeedback() {
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortedData, setSortedData] = useState(data);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

  // Toggle modal visibility
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const sortData = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }

    const sorted = [...sortedData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setSortedData(sorted);
    setSortConfig({ key, direction });
  };

  const totalPages = Math.ceil(sortedData.length / itemsPerPage);

  const displayedData = sortedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handlers
  const handleDelete = (id) => {
    alert(`Delete row with id: ${id}`);
  };

  const handleEdit = (id) => {
    alert(`Edit row with id: ${id}`);
  };

  const handleRequest = (id) => {
    alert(`Send feedback request for id: ${id}`);
  };

  return (
    <div className="lg:p-6 bg-gray-50 min-h-screen">
      <div className="w-full max-w-7xl mx-auto bg-white p-6 rounded-md shadow-md">
        {/* Header */}
        <div className="flex flex-col md:flex-row gap-4 justify-between items-center mb-6">
          <div>
            <h2 className="text-2xl font-semibold">Requested Feedback</h2>
            <p className="text-gray-500">Your requests for feedback.</p>
          </div>
          <div>
            <button
              onClick={toggleModal}
              className="bg-blue-600 text-white px-6 py-3 rounded-md flex items-center space-x-2 hover:bg-blue-700"
            >
              <IoMdAdd className="text-white text-lg" />
              <span>Request Feedback</span>
            </button>
          </div>
        </div>

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto border-separate border-spacing-y-2">
            <thead className="">
              <tr>
                <th className="px-4 py-3 text-left text-sm font-medium text-gray-700">
                  <div className="flex items-center gap-2">No</div>
                </th>
                <th className="px-4 py-3 text-left text-sm font-medium text-gray-700">
                  <div className="flex items-center gap-2">
                    Name
                    <button
                      onClick={() => sortData("name")}
                      className="text-gray-600 hover:text-gray-800"
                    >
                      <FaSort className="text-lg" />
                    </button>
                  </div>
                </th>
                <th className="px-4 py-3 text-left text-sm font-medium text-gray-700">
                  <div className="flex items-center gap-2">
                    Phone Number
                    <button
                      onClick={() => sortData("phone")}
                      className="text-gray-600 hover:text-gray-800"
                    >
                      <FaSort className="text-lg" />
                    </button>
                  </div>
                </th>
                <th className="px-4 py-3 text-left text-sm font-medium text-gray-700">
                  <div className="flex items-center gap-2">
                    Created Date
                    <button
                      onClick={() => sortData("createdDate")}
                      className="text-gray-600 hover:text-gray-800"
                    >
                      <FaSort className="text-lg" />
                    </button>
                  </div>
                </th>
                <th className="px-4 py-3 text-center text-sm font-medium text-gray-700">
                  Request Feedback
                </th>
                <th className="px-4 py-3 text-center text-sm font-medium text-gray-700">
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {displayedData.map((item, index) => (
                <tr key={item.id} className="hover:bg-gray-50">
                  <td data-cell="No" className="px-4 py-3 text-gray-700 ">
                    {(currentPage - 1) * itemsPerPage + index + 1}
                  </td>
                  <td data-cell="Name" className="px-4 py-3 text-gray-700 ">
                    <div className="flex items-center space-x-3">
                      <img
                        src={`https://ui-avatars.com/api/?name=${item.name}`}
                        alt={item.name}
                        className="w-8 h-8 rounded-full"
                      />
                      <span>{item.name}</span>
                    </div>
                  </td>
                  <td data-cell="Phone Number" className="px-4 py-3 text-gray-700 ">{item.phone}</td>
                  <td data-cell="Created Date" className="px-4 py-3 text-gray-700 ">
                    {item.createdDate}
                  </td>
                  <td data-cell="Request Feedback" className="px-4 py-3 md:text-center">
                    <button
                      onClick={() => handleRequest(item.id)}
                      className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
                    >
                      Send Request
                    </button>
                  </td>
                  <td data-cell="Action" className="px-4 py-3 md:text-center ">
                    <div className="flex justify-center items-center space-x-3">
                      <button
                        onClick={() => handleEdit(item.id)}
                        className="text-blue-500 hover:text-blue-700"
                      >
                        <FaEdit />
                      </button>
                      <button
                        onClick={() => handleDelete(item.id)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="flex justify-between items-center mt-4">
            {/* Left Section: Showing Entries */}
            <div className="text-sm text-gray-500">
              Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
              {Math.min(currentPage * itemsPerPage, sortedData.length)} of{" "}
              {sortedData.length} entries
            </div>

            {/* Center Section: Pagination */}
            <div className="flex items-center space-x-2">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                className="flex items-center justify-center w-8 h-8 bg-gray-100 text-gray-600 rounded-md hover:bg-gray-200 transition"
              >
                &lt;
              </button>

              {currentPage > 3 && (
                <>
                  <button
                    onClick={() => setCurrentPage(1)}
                    className="flex items-center justify-center w-8 h-8 bg-gray-100 text-gray-600 rounded-md hover:bg-gray-200 transition"
                  >
                    1
                  </button>
                  <span className="text-gray-600">...</span>
                </>
              )}

              {Array.from(
                { length: Math.min(3, totalPages) },
                (_, i) => currentPage - 1 + i
              )
                .filter((page) => page > 0 && page <= totalPages)
                .map((page) => (
                  <button
                    key={page}
                    onClick={() => setCurrentPage(page)}
                    className={`flex items-center justify-center w-8 h-8 ${
                      currentPage === page
                        ? "bg-blue-600 text-white"
                        : "bg-gray-100 text-gray-600"
                    } rounded-md hover:bg-gray-200 transition`}
                  >
                    {page}
                  </button>
                ))}

              {currentPage < totalPages - 2 && (
                <>
                  <span className="text-gray-600">...</span>
                  <button
                    onClick={() => setCurrentPage(totalPages)}
                    className="flex items-center justify-center w-8 h-8 bg-gray-100 text-gray-600 rounded-md hover:bg-gray-200 transition"
                  >
                    {totalPages}
                  </button>
                </>
              )}

              <button
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
                className="flex items-center justify-center w-8 h-8 bg-gray-100 text-gray-600 rounded-md hover:bg-gray-200 transition"
              >
                &gt;
              </button>
            </div>

            {/* Right Section: Entries Dropdown */}
            <div className="flex items-center">
              <span className="text-sm text-gray-500 mr-2">Show</span>
              <select
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(Number(e.target.value))}
                className="border border-gray-300 text-sm text-gray-600 rounded-md focus:ring-blue-500 focus:border-blue-500 transition"
              >
                {[8, 16, 24, 32].map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30 backdrop-blur-sm">
          <div className="bg-white w-11/12 max-w-lg p-6 rounded-lg shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Request Feedback</h2>
              <button
                className="text-gray-500 hover:text-gray-800"
                onClick={toggleModal}
              >
                ✕
              </button>
            </div>
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Message
              </label>
              <textarea
                className="w-full border border-gray-300 rounded-lg p-2 focus:ring-blue-500 focus:outline-none"
                rows="5"
                placeholder="Type Message"
              ></textarea>
            </div>
            <button className="flex items-center gap-2 bg-black text-white px-4 py-2 rounded-md hover:bg-gray-800 mb-4">
              <FaRobot />
              <span>Use AI</span>
            </button>
            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300"
                onClick={toggleModal}
              >
                Cancel
              </button>
              <button className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700">
                Send
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RequestFeedback;

import { useState, useEffect } from "react";
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { addFeedback } from "../../api";
import { useRating } from "../../context/RatingContext";

const shortenCompanyName = (fullCompanyName) => {
  let mainName = fullCompanyName.split(" - ")[0];
  if (mainName === fullCompanyName) {
    mainName = fullCompanyName.split(",")[0];
  }
  return mainName;
};

const Form = () => {
  const { company_username, employee_username, emoji, channel } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { selectedExperienceRating, selectedTeamRating } = location.state || {};

  console.log(selectedExperienceRating, selectedTeamRating);
  // Extract rating from URL parameters
  const queryParams = new URLSearchParams(location.search);
  const employeeFeedback = queryParams.get("feedback");

  const [rating, setRating] = useState(""); // Original rating
  const [phoneNumber, setPhoneNumber] = useState("");
  const [textInfo, setTextInfo] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [message, setMessage] = useState("");
  const [emojiMessage, setEmojiMessage] = useState("");
  const [employeeFeedbackState, setEmployeeFeedbackState] = useState(
    employeeFeedback || ""
  );
  const [employeeName, setEmployeeName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [preferredBusinessName, setPreferredBusinessName] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [imageLoading, setImageLoading] = useState(true);
  const { employeeRating } = useRating();
  const feedbackType = "direct_feedback";

  // Channel mapping
  const channelMapping = {
    n: "nfc_card",
    w: "whatsapp",
    q: "qr_code",
    s: "sms",
    l: "link",
  };

  // Extract and resolve the channel from query params
  const resolvedChannel = channelMapping[channel || "n"] || "NFC";

  useEffect(() => {
    console.log("Resolved Channel:", resolvedChannel);
    // Example: Use the resolvedChannel value for logging or further logic
  }, [resolvedChannel]);

  useEffect(() => {
    const fetchBusinessName = async () => {
      try {
        const response = await axios.get(
          `https://zola.technology/api/company/get/${company_username}`
        );
        const preferredName = response.data.preferred_business_name;
        setPreferredBusinessName(preferredName);
        const fullCompanyName = response.data.company_name;
        const shortenedName = shortenCompanyName(fullCompanyName);
        setCompanyName(shortenedName);
        setProfileImage(response.data.profile_image);
      } catch (error) {
        console.error("Error fetching company profile:", error);
      }
    };
    fetchBusinessName();
  }, [company_username]);

  useEffect(() => {
    const getEmployeeName = async () => {
      try {
        const response = await axios.get(
          `https://zola.technology/api/company/employees/get/${company_username}/${employee_username}/employee-name/`
        );
        setEmployeeName(response.data.name); // Ensure 'response.data.name' matches the API's returned field
      } catch (error) {
        console.error("Error fetching employee profile:", error);
      }
    };

    getEmployeeName();
  }, [company_username, employee_username]); // Include dependencies to run the effect when params change

  const emojiMapping = {
    "😡": "angry",
    "😞": "sad",
    "😐": "neutral",
  };
  
  const emojiRatings = {
    angry: 1,
    sad: 2,
    neutral: 3,
  };
  
  const emojiMessages = {
    angry:
      "We’re sorry to hear that your experience didn’t meet expectations. Please share your feedback so we can address the issue and make things right for you.",
    sad:
      "It seems we may have fallen short. Your input is invaluable to us—please let us know how we can improve and better serve you in the future.",
    neutral:
      "We appreciate your time and thoughts. Let us know how we can enhance your experience or address any concerns you may have.",
  };
  
  
  useEffect(() => {
    // console.log("Emoji received:", emoji); // Debugging
  
    const mappedEmoji = emojiMapping[emoji]; // Map the received emoji to a key
  
    if (mappedEmoji && emojiRatings[mappedEmoji]) {
      setRating(emojiRatings[mappedEmoji].toString());
      setEmojiMessage(emojiMessages[mappedEmoji]);
    }
  }, [emoji]);
  

  const handleSubmit = async (event) => {
    event.preventDefault();

    const feedbackData = {
      author_name: authorName,
      phone_number: phoneNumber,
      text_info: textInfo,
      rating: parseInt(selectedExperienceRating, 10),
      employee_rating: selectedTeamRating, // Add employee rating here
      // employee_feedback: employeeFeedbackState, // Add employee feedback here
      username: company_username,
      employee_username: employee_username,
      feedback_type: feedbackType,
      feedback_channel: resolvedChannel, // Include the resolved channel
    };
    console.log(feedbackData);

    try {
      await addFeedback(feedbackData);
      setMessage("Feedback submitted successfully!");
      setRating("");
      setPhoneNumber("");
      setTextInfo("");
      setAuthorName("");

      // Navigate to success page with the rating passed in state
      navigate("/success-page", { state: { rating: parseInt(rating, 10) } });
    } catch (error) {
      setMessage(`Failed to submit feedback: ${error.message}`);
    }
  };

  return (
    <section
      className="p-6"
      //  className="flex flex-col items-center justify-center min-h-screen p-6"
    >
      {/* Business Info Section */}
      <div className="flex items-center justify-center gap-2">
        {/* Profile Image */}
        <div className="relative">
          {imageLoading && (
            <div className="w-16 h-16 bg-gray-200 rounded-full mb-4 animate-pulse"></div>
          )}
          {profileImage && (
            <img
              src={profileImage}
              alt="Company Profile"
              className={`w-16 h-16 rounded-full object-contain mb-4 border-4 border-blue-300 shadow-lg transition-opacity duration-500 ${
                imageLoading ? "opacity-0" : "opacity-100"
              }`}
              onLoad={() => setImageLoading(false)} // Handle image load
              onError={() => setImageLoading(false)} // Handle image error
            />
          )}
        </div>
        {/* Business Name */}
        <h1 className="text-xl text-center md:text-2xl lg:text-3xl font-bold text-gray-800 mb-2">
          {preferredBusinessName || companyName}
        </h1>
      </div>
      <div className=" bg-white rounded-lg">
        <h1 className="text-lg font-semibold mb-4 text-center text-gray-800">
          Share your feedback
        </h1>
        {emojiMessage && (
          <p className="mb-4 text-center text-gray-600">{emojiMessage}</p>
        )}
        {message && <p className="mb-4 text-center text-red-500">{message}</p>}
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div>
              <textarea
                value={textInfo}
                onChange={(e) => setTextInfo(e.target.value)}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 text-lg"
                placeholder="Your Feedback"
                rows="4"
              ></textarea>
            </div>

            <div>
              <label className="block text-sm font-normal text-gray-700 mb-2">
                Phone Number
                {/* <span className="text-xs font-light">(optional)</span> */}
              </label>
              <PhoneInput
                country={"us"}
                value={phoneNumber}
                onChange={setPhoneNumber}
                inputStyle={{
                  width: "100%",
                  borderRadius: "0.375rem",
                  borderColor: "#D1D5DB",
                  boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                  paddingLeft: "3rem",
                  height: "2.5rem",
                  display: "block",
                  border: "2px solid rgba(0, 0, 0, 0.1)",
                  outline: "none",
                  backgroundColor: "rgba(230, 230, 230, 0.6)",
                  fontSize: "1.1rem",
                  transition: ".3s",
                }}
              />
            </div>

            <div>
              <label className="block text-sm text-gray-700 mb-2">
                Your Name
              </label>
              <input
                type="text"
                value={authorName}
                onChange={(e) => setAuthorName(e.target.value)}
                required
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 text-lg"
                placeholder="Your Name"
              />
            </div>

            <p className="text-center text-gray-500 text-sm">
              (This information will not be used for any other purpose than
              contacting you)
            </p>

            <button
              type="submit"
              className="w-full py-3 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 text-lg"
              aria-label="Send Feedback"
            >
              Send To Management
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Form;

import React from "react";
import AreaChart from "./SurveyChart";
import Overview from "../Overview";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import AnalyticsCards from "./AnalyticsCards";
import ResponsesList from "../ResponsesList";
const AnalyticsDashboard = () => {
  return (
    <div className="bg-gray-100 p-8 min-h-screen flex flex-col">
      {/* Header */}
      <Overview title="Analytics" />

      {/* Main Layout */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 flex-grow">
        {/* NPS Chart and Left Content */}
        <div className="lg:col-span-2 flex flex-col space-y-8">
          {/* NPS Score & Chart */}
          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold text-gray-800">
                Net Promoter Score: <span className="text-blue-600">46.5</span>
              </h2>
            </div>
            <div className="h-64">
              <AreaChart />
            </div>
          </div>

          {/* Country Stats */}
            <AnalyticsCards/>

          {/* Themes Table */}
          <div className="bg-white p-6 rounded-lg shadow">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-lg font-semibold text-gray-800">Themes</h3>
              <button className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700">
                Download Data
              </button>
            </div>
            <table className="w-full text-left text-sm border-collapse">
              <thead>
                <tr className="border-b bg-gray-50">
                  <th className="py-3 px-4 font-semibold text-gray-600">
                    Themes
                  </th>
                  <th className="py-3 px-4 font-semibold text-gray-600">
                    Change
                  </th>
                  <th className="py-3 px-4 font-semibold text-gray-600">
                    Resp. %
                  </th>
                  <th className="py-3 px-4 font-semibold text-gray-600">NPS</th>
                </tr>
              </thead>
              <tbody>
                {[
                  { theme: "Staff", change: "+48.2", resp: "20%", nps: 35 },
                  { theme: "Equipment", change: "-4.8", resp: "11%", nps: 25 },
                  {
                    theme: "Atmosphere",
                    change: "+48.2",
                    resp: "10%",
                    nps: 50,
                  },
                  {
                    theme: "Cleanliness",
                    change: "+48.2",
                    resp: "9%",
                    nps: 40,
                  },
                  { theme: "Community", change: "+48.2", resp: "6%", nps: 45 },
                ].map((item, index) => (
                  <tr key={index} className="border-b">
                    <td className="py-3 px-4">{item.theme}</td>
                    <td
                      className={`py-3 px-4 ${
                        item.change.startsWith("-")
                          ? "text-red-500"
                          : "text-green-500"
                      }`}
                    >
                      {item.change}
                    </td>
                    <td className="py-3 px-4">{item.resp}</td>
                    <td className="py-3 px-4">
                      <div className="relative w-full bg-gray-200 rounded-full h-2">
                        <div
                          className="absolute top-0 left-0 h-2 bg-blue-600 rounded-full"
                          style={{ width: `${item.nps}%` }}
                        ></div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Responses Section */}
        <div className="rounded-lg shadow flex flex-col h-screen">
        <ResponsesList />
        </div>
      </div>
    </div>
  );
};

export default AnalyticsDashboard;

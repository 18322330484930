import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
import Topbar from "./topbar/Topbar";
import { AuthContext } from "../../context/AuthContext";
import { FaSignOutAlt } from "react-icons/fa";

const MainLayout = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const { logout } = useContext(AuthContext);
  const location = useLocation(); 

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    document.documentElement.classList.toggle('dark');
  };
  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);

  // Check if the current route matches /messages
  const isMessagesRoute = location.pathname.includes("/messages");
  
    const handleLogout = () => {
      // Call your logout function here
      logout();
      setIsLogoutModalVisible(false); // Close modal after logging out
    };
  
  return (
    <div className={`flex w-full min-h-screen ${theme === 'dark' ? 'dark' : ''}`}>
      {/* Sidebar */}
      <Sidebar 
        mobileOpen={mobileOpen} 
        setMobileOpen={setMobileOpen} 
        theme={theme}
        toggleTheme={toggleTheme}
      />

      {/* Main Content */}
      <div 
        className={`flex-1 flex flex-col transition-all duration-300 
          ${mobileOpen ? "ml-64" : "ml-0"} lg:ml-64`}
      >
        {/* Topbar */}
        <Topbar 
          isClosing={isClosing} 
          mobileOpen={mobileOpen} 
          setMobileOpen={setMobileOpen}
          logout={() => setIsLogoutModalVisible(true)}
        />

        {/* Children */}
        <main
          className={`flex-1 ${
            isMessagesRoute ? "" : "p-6 bg-[#F5F8FF] dark:bg-gray-900"
          }`}
        >
          {children}
          {/* Logout Confirmation Modal */}
                    {isLogoutModalVisible && (
                      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                        <div className="bg-white rounded-lg shadow-lg p-6 w-[300px] md:w-[400px]">
                          <div className="flex flex-col items-center">
                            <FaSignOutAlt size={40} className="text-blue-500 mb-4" />
                            <h2 className="text-lg font-semibold text-gray-800 mb-2">Logout</h2>
                            <p className="text-sm text-gray-600 mb-6">Are you sure?</p>
                            <div className="flex space-x-4">
                              <button
                                aria-label="Cancel"
                                onClick={() => setIsLogoutModalVisible(false)}
                                className="px-4 py-2 w-full bg-blue-500 text-white rounded hover:bg-blue-600"
                              >
                                Cancel
                              </button>
                              <button
                                aria-label="Logout"
                                onClick={handleLogout}
                                className="px-4 py-2 w-full border border-gray-300 text-gray-700 rounded hover:bg-gray-100"
                              >
                                Logout
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    
        </main>
      </div>
    </div>
  );
};

export default MainLayout;

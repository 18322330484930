import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import image from './carousel1.jpg';

function GetReviews() {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeOut' } },
  };

  return (
    <motion.div
      className="bg-[#f8f9fe] rounded-3xl p-4 sm:p-6 md:px-16 md:py-24 max-w-[1340px] mx-auto flex flex-col md:flex-row items-center justify-between h-auto my-8 md:my-24 font-figtree"
      ref={ref}
      variants={containerVariants}
      initial="hidden"
      animate={controls}
    >
      {/* Text Section */}
      <motion.div
        className="w-full md:w-1/2 space-y-3 md:space-y-4 text-center md:text-left px-4 md:px-0"
        variants={itemVariants}
      >
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-[38px] font-semibold leading-snug">
          Get Reviews & Feedback
        </h2>
        <ul className="space-y-1 md:space-y-2 text-lg sm:text-xl md:text-2xl font-normal">
          <li className="text-gray-600">• 10X your monthly public reviews</li>
          <li className="text-gray-600">• Track negative trends internally</li>
          <li className="text-gray-600">• Resolve customer issues in real-time</li>
        </ul>
        {/* Adjust the button styling for mobile if needed */}
        {/* <button className="mt-4 px-4 py-3 sm:px-6 sm:py-4 bg-[#28008f] text-white rounded-lg">Show Me</button> */}
      </motion.div>

      {/* Image Section */}
      <motion.div
        className="md:w-1/2 flex justify-center relative mt-6 md:mt-0"
        variants={itemVariants}
      >
        <img src={image} alt="Carousel Image" className="rounded-lg" />
      </motion.div>
    </motion.div>
  );
}

export default GetReviews;
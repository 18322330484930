import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Companies() {
  const [allCompanies, setAllCompanies] = useState([]);  // Store all companies
  const [filteredCompanies, setFilteredCompanies] = useState([]);  // Store filtered companies
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const PAGE_SIZE = 10;
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const fetchAllCompanies = async () => {
    try {
      let page = 1;
      let allData = [];
      let totalPages = 1;

      while (page <= totalPages) {
        const response = await axios.get(
          `https://zola.technology/api/company/get-all/`,
          {
            params: {
              page,
              page_size: 100,  // Fetch large chunks to minimize API calls
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { data, total_pages } = response.data;
        totalPages = total_pages;
        allData = [...allData, ...data];  // Accumulate data
        page++;
      }

      setAllCompanies(allData);
      setFilteredCompanies(allData);  // Initially show all data
    } catch (err) {
      console.error("Failed to fetch companies:", err);
    }
  };

  useEffect(() => {
    fetchAllCompanies();
  }, []);

  // Filter companies based on search query
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = allCompanies.filter((company) => {
      return (
        company.first_name.toLowerCase().includes(query) ||
        company.last_name.toLowerCase().includes(query) ||
        company.company_name.toLowerCase().includes(query) ||
        company.username.toLowerCase().includes(query)
      );
    });

    setFilteredCompanies(filtered);
    setCurrentPage(1);  // Reset to the first page of search results
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(filteredCompanies.length / PAGE_SIZE)) {
      setCurrentPage(page);
    }
  };

  const handleCompanyDetails = (company) => {
    navigate(`/dashboard/companies/${company.id}`, { state: company });
  };

  // Paginate the filtered results
  const paginatedCompanies = filteredCompanies.slice(
    (currentPage - 1) * PAGE_SIZE,
    currentPage * PAGE_SIZE
  );

  const totalFilteredPages = Math.ceil(filteredCompanies.length / PAGE_SIZE);

  return (
    <div className="p-6">
      <div className="mb-4 flex justify-end">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Search companies..."
          className="border border-gray-300 rounded-md px-4 py-2 focus:ring-2 focus:ring-blue-300"
          aria-label="Search for a company by name, username, or company name"
        />
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full table-auto border-collapse border border-gray-200">
          <thead className="bg-gray-100 text-gray-600 text-[16px]">
            <tr>
              <th className="px-4 py-2 text-left font-semibold border-b border-gray-300">S/N</th>
              <th className="px-4 py-2 text-left font-semibold border-b border-gray-300">Name</th>
              <th className="px-4 py-2 text-left font-semibold border-b border-gray-300">Company Name</th>
              <th className="px-4 py-2 text-left font-semibold border-b border-gray-300">Username</th>
              <th className="px-4 py-2 text-left font-semibold border-b border-gray-300">Actions</th>
            </tr>
          </thead>
          <tbody>
            {paginatedCompanies.length > 0 ? (
              paginatedCompanies.map((company, index) => (
                <tr
                  key={company.id}
                  className="bg-white hover:bg-gray-50 transition-colors duration-200 text-gray-800"
                >
                  <td className="px-4 py-3 text-sm border-b border-gray-200">
                    {index + 1 + (currentPage - 1) * PAGE_SIZE}
                  </td>
                  <td className="px-4 py-3 text-sm border-b border-gray-200">
                    {`${company.first_name} ${company.last_name}`}
                  </td>
                  <td className="px-4 py-3 text-sm border-b border-gray-200">
                    {company.company_name}
                  </td>
                  <td className="px-4 py-3 text-sm border-b border-gray-200">
                    {company.username}
                  </td>
                  <td className="px-4 py-3 flex items-center gap-2 border-b border-gray-200">
                    <button
                      onClick={() => handleCompanyDetails(company)}
                      className="bg-blue-500 text-white px-4 py-2 rounded-md shadow hover:bg-blue-600"
                    >
                      Details
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center py-6 text-gray-500">
                  No companies match the search query.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-center items-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 mr-2 bg-gray-200 rounded-md hover:bg-gray-300 disabled:opacity-50"
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalFilteredPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalFilteredPages}
          className="px-4 py-2 ml-2 bg-gray-200 rounded-md hover:bg-gray-300 disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Companies;

import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import {
  Members,
  Billing,
  Notification,
  Language,
  Account,
} from "../components/AccountSettings/index";
import { IoPersonCircleOutline } from "react-icons/io5";
import { IoGlobeOutline } from "react-icons/io5";
import PaymentPage from "./AccountSettings/PaymentDetailsPage";
import PasswordSettings from "./AccountSettings/PasswordSettings";
import RewardAmountUpdater from "./RewardSection/AddReward";
import SubmitCardsComponent from "./Payment/CardPayment";

const AccountSettings = () => {
  const location = useLocation();
  // Enhanced logic to detect the active tab
  const activeTab = (path) =>
    location.pathname === path ||
    (path === "/dashboard/setting/" &&
      location.pathname === "/dashboard/setting");

  // Retrieve userData from localStorage
  const userData = JSON.parse(localStorage.getItem("userData"));

  // Get company_type from userData (default to an empty string if userData is null)
  const companyType = userData?.company_type || "";

  return (
    <div className="min-h-screen md:p-4 flex flex-col">
      {/* Title Section */}
      <div className="w-full  ">
        <h2 className="text-[24px] font-medium text-[#282A3E] mb-8">
          Settings
        </h2>
      </div>

      {/* Tabs Navigation */}
      <div className="w-full md:w-2/3 rounded-xl mb-6">
        <div className="flex flex-wrap justify-around p-2 md:p-4 gap-2 md:gap-4">
          <Link
            to="/dashboard/setting/"
            className={`flex-grow text-center py-2 md:py-3 px-2 text-base md:text-lg font-medium transition-all duration-300 rounded-full ${
              activeTab("/dashboard/setting/")
                ? "bg-white text-gray-900 shadow-xl"
                : "text-gray-500"
            }`}
          >
            Account
          </Link>

          <Link
            to="/dashboard/setting/billing"
            className={`flex-grow text-center py-2 md:py-3 px-2 text-base md:text-lg font-medium transition-all duration-300 rounded-full ${
              activeTab("/dashboard/setting/billing")
                ? "bg-white text-gray-900 shadow-xl"
                : "text-gray-500"
            }`}
          >
            Billing
          </Link>

          <Link
            to="/dashboard/setting/update-details"
            className={`flex-grow text-center py-2 md:py-3 px-2 text-base md:text-lg font-medium transition-all duration-300 rounded-full ${
              activeTab("/dashboard/setting/update-details")
                ? "bg-white text-gray-900 shadow-xl"
                : "text-gray-500"
            }`}
          >
            Payment Details
          </Link>

          <Link
            to="/dashboard/setting/security"
            className={`flex-grow text-center py-2 md:py-3 px-2 text-base md:text-lg font-medium transition-all duration-300 rounded-full ${
              activeTab("/dashboard/setting/security")
                ? "bg-white text-gray-900 shadow-xl"
                : "text-gray-500"
            }`}
          >
            Security
          </Link>
          {companyType === "team" && (
            <Link
              to="/dashboard/setting/reward"
              className={`flex-grow text-center py-2 md:py-3 px-2 text-base md:text-lg font-medium transition-all duration-300 rounded-full ${
                activeTab("/dashboard/setting/reward")
                  ? "bg-white text-gray-900 shadow-xl"
                  : "text-gray-500"
              }`}
            >
              Reward
            </Link>
          )}
          {/* <Link
            to="/dashboard/setting/cards"
            className={`flex-grow text-center py-2 md:py-3 px-2 text-base md:text-lg font-medium transition-all duration-300 rounded-full ${
              activeTab("/dashboard/setting/cards")
                ? "bg-white text-gray-900 shadow-xl"
                : "text-gray-500"
            }`}
          >
            Add Card
          </Link> */}
        </div>
      </div>

      {/* Dynamic Content Section */}
      <div className="w-full md:w-2/3 bg-white rounded-lg  p-4 md:p-8">
        <Routes>
          <Route path="/" element={<Account />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/language" element={<Language />} />
          <Route path="/members" element={<Members />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/update-details" element={<PaymentPage />} />
          <Route path="/security" element={<PasswordSettings />} />
          <Route path="/reward" element={<RewardAmountUpdater />} />
          <Route path="/cards" element={<SubmitCardsComponent />} />
        </Routes>
      </div>
    </div>
  );
};

export default AccountSettings;

import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const PerformanceDashboard = () => {
  const [filter, setFilter] = useState(
    localStorage.getItem("filter") || "week"
  );
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "bar",
      toolbar: {
        show: false, // Hides the toolbar
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: false, // Disables data labels
    },
    tooltip: {
      enabled: true,
      theme: "dark", // Makes the tooltip dark
    },
    xaxis: {
      categories: [], // Placeholder for categories from API
    },
    yaxis: {
      min: 0, // Ensures y-axis always starts from 0
      labels: {
        formatter: (val) => Math.round(val), // Formats the y-axis labels
      },
      max: 1000, // Adjusts the y-axis maximum dynamically based on data
    },
    fill: {
      colors: ["#7a5af8"], // Sets the bar color
    },
  });

  const [chartSeries, setChartSeries] = useState([
    {
      name: "Total taps",
      data: [], // Placeholder for data from API
    },
  ]);

  const fetchPerformanceData = async (currentFilter) => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/performance/get-spread-taps/?filter=${currentFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching performance data:", error);
      return { periods: [] };
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchPerformanceData(filter);

      // Map API data to chart format
      const sortedPeriods = data.periods.sort(
        (a, b) => new Date(a.period) - new Date(b.period)
      );

      const categories = sortedPeriods.map((item) => {
        if (filter === "year") {
          // Extract and format month as first 3 letters (e.g., "Jan", "Apr")
          const parts = item.period.split(" ");
          return parts.length > 2 ? parts[2].slice(0, 3) : item.period;
        } else if (filter === "week") {
          // Extract day name for week filter
          return item.period.split(" ")[0];
        }
        return item.period; // Fallback to original format
      });

      const tapsData = sortedPeriods.map((item) => item.taps);

      // Update chart options and series
      setChartOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: categories,
        },
        yaxis: {
          ...prevOptions.yaxis,
          min: 0, // Ensures y-axis always starts from 0
          max: Math.max(...tapsData) + 4, // Dynamically set y-axis max
        },
      }));

      setChartSeries([
        {
          name: "Total taps",
          data: tapsData,
        },
      ]);
    };

    fetchData();
  }, [filter]);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "filter") {
        setFilter(event.newValue || "week");
      }
    };

    // Listen for `localStorage` changes from other tabs
    window.addEventListener("storage", handleStorageChange);

    // Fallback: Poll for `localStorage` changes in the same tab
    const interval = setInterval(() => {
      const currentFilter = localStorage.getItem("filter");
      if (currentFilter !== filter) {
        setFilter(currentFilter || "week");
      }
    }, 500); // Check every 500ms (adjust as needed)

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      clearInterval(interval);
    };
  }, [filter]);

  return (
    <div className="flex p-6 justify-center items-center bg-white rounded-lg">
      <div className="w-full">
        <h2 className="text-lg font-medium mb-4">Total Taps</h2>
        <div className="w-[95%] md-w-full h-[300px] lg:h-[400px]">
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={350}
            width="100%" // Ensures the chart takes the full width of the container
          />
        </div>
      </div>
    </div>
  );
};

export default PerformanceDashboard;

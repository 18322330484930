import React, { useState } from "react";
import ZolaLogo from "../../HQDashboard/components/sidebar/Layer_1.png";

function AIChat() {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [chatStarted, setChatStarted] = useState(false);

  const handleSend = () => {
    if (inputValue.trim()) {
      if (!chatStarted) setChatStarted(true); // Start chat on first message
      setMessages([...messages, { text: inputValue, sender: "user" }]);
      setTimeout(() => {
        setMessages((prev) => [
          ...prev,
          { text: `Generating questions for: ${inputValue}`, sender: "ai" },
        ]);
      }, 1000);
      setInputValue("");
    }
  };

  return (
    <div className="flex flex-col justify-start min-h-screen bg-gray-50 p-6">
      {/* Header */}
      <div className="w-full max-w-4xl">
        <h1 className="text-2xl font-semibold text-gray-800">New Request</h1>
        <p className="text-sm text-gray-500 mt-1">
          Select from our suggestions or type your problem
        </p>
      </div>

      {/* Main Content */}
      <div className="flex flex-col items-center justify-center max-w-7xl w-full flex-grow px-6">
        {!chatStarted && (
          <div className="flex flex-col items-center justify-center w-full">
            {/* Logo and Greeting */}
            <img
              src={ZolaLogo}
              alt="Zola Logo"
              className="h-12 w-48 mb-4 object-contain"
            />
            <h2 className="text-xl font-bold text-gray-800">
              Hi, <span className="text-blue-600">Zola.AI</span> here
            </h2>
            <p className="text-sm text-gray-500 mt-2">
              Choose from my selected prompts
            </p>

            {/* Problems Section */}
            <div className="w-full max-w-2xl bg-white shadow-md rounded-lg p-6 mt-8">
              <h3 className="text-sm font-medium text-gray-600 flex items-center mb-4">
                <span className="w-6 h-6 bg-blue-100 text-blue-600 rounded-full flex items-center justify-center mr-2">
                  ⓘ
                </span>
                Problems
              </h3>

              {/* Prompts */}
              <div className="space-y-4">
                <button
                  onClick={() => {
                    setInputValue(
                      "Please write four questions about my restaurant"
                    );
                    setChatStarted(true);
                    handleSend(); // Automatically send the selected prompt
                  }}
                  className="w-full text-left px-4 py-3 border rounded-lg text-gray-700 bg-gray-50 hover:bg-gray-100 transition"
                >
                  Please write Survey questions about Restaurant
                </button>
                <button
                  onClick={() => {
                    setInputValue(
                      "Please write four questions about my clinic"
                    );
                    setChatStarted(true);
                    handleSend(); // Automatically send the selected prompt
                  }}
                  className="w-full text-left px-4 py-3 border rounded-lg text-gray-700 bg-gray-50 hover:bg-gray-100 transition"
                >
                  Please write Survey questions about Clinic
                </button>
              </div>
            </div>
          </div>
        )}

        {chatStarted && (
          <div className="w-full max-w-2xl flex-grow overflow-y-auto space-y-4 mt-4">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`flex ${
                  message.sender === "user" ? "justify-end" : "justify-start"
                }`}
              >
                <div
                  className={`${
                    message.sender === "user"
                      ? "bg-blue-600 text-white"
                      : "bg-gray-200 text-gray-800"
                  } px-4 py-2 rounded-lg max-w-sm`}
                >
                  {message.text}
                </div>
              </div>
            ))}
          </div>
        )}
        {/* Footer Input */}
        <div className="w-full max-w-2xl bg-white shadow-md rounded-lg bottom-0 mt-8">
          <div className="flex items-center bg-white border rounded-lg shadow-md p-4">
            <input
              type="text"
              placeholder="Write here"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              className="flex-grow border-none outline-none text-gray-600 placeholder-gray-400"
            />
            <button
              onClick={handleSend}
              className="text-gray-500 hover:text-blue-500 transition"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.752 11.168l-7.31 4.2a1 1 0 01-1.44-1.109l1.396-8.148a4 4 0 013.377-3.352l6.722-1.185a2 2 0 012.366 2.366l-1.185 6.722a4 4 0 01-3.352 3.377l-8.148 1.396a1 1 0 01-1.109-1.44l4.2-7.31m1.248-1.248L12 3m1.248 1.248l1.504 1.504M12 12l1.248-1.248M12 12l1.504 1.504M3 21h18"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AIChat;

import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

function SuccessSurveyMessage() {
  const navigate = useNavigate();

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gray-50 dark:bg-gray-900 px-6">
      <motion.div
        className="text-center bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg max-w-lg"
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <h1 className="text-3xl font-bold text-blue-600 mb-4">
          🎉 Thank You!
        </h1>
        <p className="text-gray-700 dark:text-gray-300 text-lg mb-6">
          Your feedback has been successfully submitted. We appreciate your time and effort in helping us improve!
        </p>
        <button
          onClick={() => navigate("/")}
          className="px-6 py-3 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 transition"
        >
          Go to Home
        </button>
      </motion.div>
    </div>
  );
}

export default SuccessSurveyMessage;

import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { FaRegStar } from "react-icons/fa";

const ReviewSection = () => {
    const [reviewsCount, setReviewsCount] = useState(0);
    const [rating, setRating] = useState(0);
    
    const fetchReviews = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(
          "https://zola.technology/api/company/reviews/list",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch reviews");
        }
        const data = await response.json();
        console.log(data)
        setReviewsCount(data.reviews || 0);
        setRating(data.rating || 0);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    useEffect(() => {
      fetchReviews();
    }, []);

  const reviewsData = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "Reviews",
        data: [50, 70, 60, 90, 100, 80, 110],
        backgroundColor: "#007bff",
        borderRadius: 4,
      },
    ],
  };

  const reviewsOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        display: false,
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        display: false,
      },
    },
  };

  return (
    <div
      className={`bg-white relative shadow-sm rounded-xl p-6 flex flex-col gap-2`}
    >
      {/* Header Section */}
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <span className="bg-yellow-100 text-yellow-500 p-2 rounded-full">
            <FaRegStar />
          </span>
          <h4 className="text-lg font-semibold text-gray-700">Total Reviews</h4>
        </div>
      </div>

      {/* Data Section */}
      <div className="flex flex-row lg:flex-col justify-between gap-4">
        <div className="flex items-center gap-4">
          <h2 className="text-4xl font-bold text-gray-900">{reviewsCount}</h2>
          <p className="bg-blue-100 text-blue-500 px-2 py-1 rounded-md text-sm font-medium inline-block">
            {/* +10.4% */}
            {rating}
          </p>
        </div>

        <div className="flex flex-col lg:flex-row items-end gap-4">
          {/* Bar Chart */}
          <div className="h-20">
            <Bar data={reviewsData} options={reviewsOptions} />
          </div>

          {/* Footer Text */}
          <p className="text-sm text-end md:text-start text-gray-500">
            Increase in {reviewsCount} reviews in the last one year.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReviewSection;

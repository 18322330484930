import axios from "axios";
import React, { useEffect, useState } from "react";
import { FiUpload } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import ColorPicker from "./ColorPicker";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { MdPeopleAlt } from "react-icons/md";
import CoverPictureModal from "./CoverPictureModal";
import { SlPeople } from "react-icons/sl";
import zola from "./zola.png";
import zolaLight from "./zola-light.png";

const isColorDark = (color) => {
  const hex = color.replace("#", "");

  // Convert 3-character hex to 6-character hex
  const normalizedHex =
    hex.length === 3
      ? hex
          .split("")
          .map((char) => char + char)
          .join("")
      : hex;

  if (normalizedHex.length !== 6) {
    console.error("Invalid hex color:", color);
    return false; // Default to light if invalid
  }

  // Parse the hex color to get RGB values
  const r = parseInt(normalizedHex.substr(0, 2), 16);
  const g = parseInt(normalizedHex.substr(2, 2), 16);
  const b = parseInt(normalizedHex.substr(4, 2), 16);

  // Calculate relative luminance
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // Return true if the color is dark
  return luminance < 128;
};

function MobilePreview() {
  // State for images and messages
  const [companyLogo, setCompanyLogo] = useState(
    "https://via.placeholder.com/100x100"
  );
  const [coverImage, setCoverImage] = useState(
    "https://via.placeholder.com/300x200"
  );
  const [welcomeMessage, setWelcomeMessage] = useState("Hi there 👋");
  const [visitMessage, setVisitMessage] = useState(
    "Thanks for being our guest today!"
  );

  // State for colors and buttons
  const [bgColor, setBgColor] = useState("gray"); // Default background color
  const [textColor, setTextColor] = useState("");
  const [selectedTextColor, setSelectedTextColor] = useState("white"); // Default text color
  const [newButtonLabel, setNewButtonLabel] = useState("");
  const [buttons, setButtons] = useState([
    {
      label: "Leave Us A Review",
      visible: true,
      isDefault: true,
      bgColor: "#0000FF",
      isHidden: false,
    },
    {
      label: "Follow Us On Social Media",
      visible: true,
      isDefault: false,
      bgColor: "#0000FF",
      isHidden: false,
    },
    {
      label: "Take Our Survey",
      visible: true,
      isDefault: false,
      bgColor: "#0000FF",
      isHidden: false,
      fancyId: "survey-button",
    },
  ]);

  // State for modals, files, and updating
  const [showModal, setShowModal] = useState(false);
  const [newCoverImage, setNewCoverImage] = useState("");
  const [file, setFile] = useState(null);
  const [isFirstTimeUser, setIsFirstTimeUser] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [mobilePreviewExists, setMobilePreviewExists] = useState(false);

  // State for social media URLs
  const [facebookUrl, setFacebookUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");

  // State for color selection and icons
  const [selectedColor, setSelectedColor] = useState("");
  const [showSocialIcons, setShowSocialIcons] = useState(false);

  const [fonts, setFonts] = useState([]);
  const [selectedFont, setSelectedFont] = useState("");

  const API_KEY = process.env.REACT_APP_FONT_PICKER_API_KEY;
  const FONTS_API = `https://www.googleapis.com/webfonts/v1/webfonts?key=${API_KEY}`;

  // Fetch Google Fonts
  useEffect(() => {
    const fetchFonts = async () => {
      try {
        const response = await axios.get(FONTS_API);
        console.log(response.data.items);
        setFonts(response.data.items); // Google Fonts are in the `items` array
      } catch (error) {
        console.error("Error fetching Google Fonts:", error);
      }
    };

    fetchFonts();
  }, []);

  // Handle Global Color Change for All Buttons
  const handleGlobalColorChange = (color) => {
    setSelectedColor(color);
    setButtons((prevButtons) =>
      prevButtons.map((button) => ({
        ...button,
        bgColor: color,
      }))
    );
  };

  const handleTextColorClick = (color) => {
    setSelectedTextColor(color); // Update the selected text color
  };

  // Function to handle background color change
  const handleBgColorClick = (color) => {
    setBgColor(color);
  };

  const handleButtonChange = (index, newValue) => {
    setButtons((prevButtons) => {
      const updatedButtons = [...prevButtons];
      updatedButtons[index] = { ...updatedButtons[index], label: newValue };
      return updatedButtons;
    });
  };

  const toggleButtonVisibility = (index) => {
    setButtons((prevButtons) =>
      prevButtons.map((button, i) =>
        i === index
          ? { ...button, visible: !button.visible, isHidden: button.visible }
          : button
      )
    );
  };

  // const handleColorClick = (color) => {
  //   setSelectedColor(color);
  //   setBgColor(color);
  // };

  // State initialization
  const [isFontLoaded, setIsFontLoaded] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .get("https://zola.technology/api/company/profile/", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const data = response.data;
        setCompanyLogo(
          data.profile_image || "https://via.placeholder.com/100x100"
        );
        setSelectedFont(data.font_family);
        setIsFontLoaded(true); // Mark font as loaded

        // Persist the fetched font family in localStorage for persistence across reloads
        localStorage.setItem("selectedFont", data.font_family);
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
        setIsFontLoaded(true); // Allow fallback to render
      });
  }, []);

  useEffect(() => {
    const fetchFeedbackMobilePage = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          "https://zola.technology/api/company/feedback/mobile/page/",
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const data = response.data;
        console.log(data);
        setIsFirstTimeUser(!data);
        setMobilePreviewExists(!!data);
        setCompanyLogo(
          data.company_logo || "https://via.placeholder.com/100x100"
        );
        setCoverImage(
          data.cover_image || "https://via.placeholder.com/300x200"
        );
        setWelcomeMessage(data.welcome_message || "Hi there 👋");
        setVisitMessage(
          data.visit_message || "Thanks for being our visitor today!"
        );
        setSelectedTextColor(data.text_color || "#FFFFFF");

        // Extract the background color of the first button
        const firstButtonColor = data.buttons?.button_1?.bgColor || "#FFFFFF";
        setBgColor(data.bg_color || "#FFFFFF");
        setSelectedColor(firstButtonColor);

        // Extract buttons and social media URLs
        const fetchedButtons = [];
        let socialLinks = { facebook: "", instagram: "", twitter: "" };

        Object.values(data.buttons || {}).forEach((btn) => {
          fetchedButtons.push({
            label: btn.label,
            isDefault: btn.isDefault,
            bgColor: btn.bgColor || "#0000FF",
            visible: !btn.isHidden, // Use isHidden to determine visibility
            isHidden: btn.isHidden, // Preserve hidden state
          });

          // Check if this button has social media links
          if (btn.label === "Follow Us On Social Media" && btn.socialMedia) {
            socialLinks = {
              facebook: btn.socialMedia.facebook || "",
              instagram: btn.socialMedia.instagram || "",
              twitter: btn.socialMedia.twitter || "",
            };
          }
        });

        setButtons(fetchedButtons);
        setFacebookUrl(socialLinks.facebook);
        setInstagramUrl(socialLinks.instagram);
        setTwitterUrl(socialLinks.twitter);
      } catch (error) {
        console.error("Error fetching feedback mobile page:", error);
      }
    };

    fetchFeedbackMobilePage();
  }, []);

  // Function to create a new feedback mobile page
  const createFeedbackMobilePage = async () => {
    const token = localStorage.getItem("token");

    // Layout data for the feedback mobile page
    const layoutData = {
      cover_image: coverImage,
      welcome_message: welcomeMessage,
      visit_message: visitMessage,
      bg_color: bgColor,
      text_color: selectedTextColor,
      buttons: buttons.reduce((acc, btn, index) => {
        acc[`button_${index + 1}`] = {
          label: btn.label,
          bgColor: btn.bgColor,
          isDefault: btn.isDefault,
          isHidden: btn.isHidden || false, // Ensure isHidden is included
        };

        // Add social media URLs for "Follow Us On Social Media"
        if (btn.label === "Follow Us On Social Media") {
          acc[`button_${index + 1}`].socialMedia = {
            facebook: facebookUrl,
            instagram: instagramUrl,
            twitter: twitterUrl,
          };
        }

        return acc;
      }, {}),
    };

    // Font family update data
    const fontFamilyUpdateData = {
      font_family: selectedFont, // Assuming 'selectedFont' holds the new font family value
    };

    try {
      // Update font family
      await axios.patch(
        "https://zola.technology/api/company/update/",
        fontFamilyUpdateData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Font family updated successfully");

      // Proceed with creating the feedback mobile page
      const response = await axios.post(
        "https://zola.technology/api/company/feedback/mobile/create-page/",
        layoutData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Feedback mobile page created successfully");
      console.log("Feedback mobile page created successfully:", response.data);
    } catch (error) {
      toast.error(
        "Error updating font family or creating feedback mobile page"
      );
      console.error("Error:", error);
    }
  };

  const updateFeedbackMobilePage = async () => {
    const token = localStorage.getItem("token");

    // Layout data for the feedback mobile page
    const layoutData = {
      company_logo: companyLogo,
      cover_image: coverImage,
      welcome_message: welcomeMessage,
      visit_message: visitMessage,
      text_color: selectedTextColor,
      bg_color: bgColor,
      buttons: buttons.reduce((acc, btn, index) => {
        acc[`button_${index + 1}`] = {
          label: btn.label,
          bgColor: btn.bgColor,
          isDefault: btn.isDefault,
          isHidden: btn.isHidden || false, // Ensure isHidden is included
        };

        // Add social media URLs for "Follow Us On Social Media"
        if (btn.label === "Follow Us On Social Media") {
          acc[`button_${index + 1}`].socialMedia = {
            facebook: facebookUrl,
            instagram: instagramUrl,
            twitter: twitterUrl,
          };
        }

        return acc;
      }, {}),
    };

    // Font family update data
    const fontFamilyUpdateData = {
      font_family: selectedFont, // Assuming 'selectedFont' holds the new font family value
    };

    try {
      // Update font family
      await axios.patch(
        "https://zola.technology/api/company/update/",
        fontFamilyUpdateData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Proceed with updating the feedback mobile page
      const response = await axios.patch(
        "https://zola.technology/api/company/feedback/mobile/update/",
        layoutData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Feedback mobile page updated successfully");

      // Update local state with server response
      const updatedButtons = Object.values(response.data.buttons || {}).map(
        (btn) => ({
          label: btn.label,
          bgColor: btn.bgColor,
          isDefault: btn.isDefault,
          isHidden: btn.isHidden,
          visible: !btn.isHidden,
        })
      );

      setButtons(updatedButtons);
      setCompanyLogo(response.data.company_logo || companyLogo);
      setCoverImage(response.data.cover_image || coverImage);
      setWelcomeMessage(response.data.welcome_message || welcomeMessage);
      setVisitMessage(response.data.visit_message || visitMessage);
      setSelectedTextColor(response.data.text_color || selectedTextColor);
      setBgColor(response.data.bg_color || bgColor);
    } catch (error) {
      toast.error("Error updating font family or feedback mobile page");
      console.error("Error updating feedback mobile page:", error);
    }
  };

  const handleCoverImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCoverImage(reader.result);
        setNewCoverImage("");
      };
      reader.readAsDataURL(selectedFile);
      setShowModal(false);
    }
  };

  const addNewButton = () => {
    const newButton = {
      label: `New Button ${buttons.length + 1}`, // Auto-label the button
      visible: true, // Default visibility
      isDefault: false, // New buttons are not default
      isHidden: false,
    };

    setButtons((prevButtons) => [...prevButtons, newButton]);
  };

  // Function to delete a button by index
  const deleteButton = (index) => {
    setButtons((prevButtons) => prevButtons.filter((_, i) => i !== index));
  };

  const handleSavePreview = () => {
    if (isUpdating) return;
    createFeedbackMobilePage();
    setMobilePreviewExists(true);
  };

  const handleUpdatePreview = () => {
    if (isFirstTimeUser) return;
    updateFeedbackMobilePage();
  };

  const predefinedColors = [
    "white",
    "red",
    "orange",
    "yellow",
    "green",
    "blue",
    "indigo",
    "purple",
    "pink",
    "gray",
    "brown",
    "teal",
  ];

  const predefinedTextColors = [
    "white",
    "black",
    "red",
    "orange",
    "yellow",
    "green",
    "blue",
    "indigo",
    "purple",
    "pink",
    "gray",
    "brown",
  ];

  // Load font from localStorage on component mount if available
  useEffect(() => {
    const storedFont = localStorage.getItem("selectedFont");
    if (storedFont) {
      setSelectedFont(storedFont);
    }
  }, []);

  // Dynamically update the link to Google Fonts based on selectedFont
  useEffect(() => {
    if (selectedFont) {
      const linkId = "google-font-link";
      let linkElement = document.getElementById(linkId);

      if (!linkElement) {
        linkElement = document.createElement("link");
        linkElement.id = linkId;
        linkElement.rel = "stylesheet";
        document.head.appendChild(linkElement);
      }

      linkElement.href = `https://fonts.googleapis.com/css2?family=${selectedFont.replace(
        " ",
        "+"
      )}&display=swap`;
    }
  }, [selectedFont]); // Re-run the effect when selectedFont changes

  // Handle font change if the user selects a new font manually
  const handleFontChange = (event) => {
    const selectedFontFamily = event.target.value;
    setSelectedFont(selectedFontFamily);

    // Persist the selected font in localStorage
    localStorage.setItem("selectedFont", selectedFontFamily);
  };

  // Determine dynamic text color
  const dynamicTextColor = isColorDark(bgColor) ? "#FFFFFF" : "#000000";

  console.log("Logo Source:", isColorDark(bgColor) ? zolaLight : zola);

  return (
    <div className="flex w-full flex-col lg:flex-row min-h-screen md:p-6 transition duration-300">
      <div className="lg:w-1/2 w-full rounded-lg p-4 md:p-6 bg-white dark:bg-gray-700 transition duration-300">
        <h2 className="text-lg md:text-2xl font-semibold mb-6 text-gray-800 dark:text-gray-200">
          Customize Your mobile review page
        </h2>

        <div className="mb-4">
          <label className="block font-medium mb-2 text-gray-700 dark:text-gray-300">
            Company Logo
          </label>
          <img
            src={companyLogo}
            alt="Company Logo"
            className="w-24 h-24 rounded-full border-4 border-gray-200 dark:border-gray-600 object-contain"
          />
        </div>

        <div className="mb-4">
          <label className="block font-medium mb-2 text-gray-700 dark:text-gray-300">
            Cover Picture
          </label>
          <button
            aria-label="Change Cover Picture"
            onClick={() => setShowModal(true)}
            className="w-full px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300 transition duration-200 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500"
          >
            <FiUpload className="inline mr-2" /> Change Cover Picture
          </button>
        </div>

        <CoverPictureModal
          showModal={showModal}
          setShowModal={setShowModal}
          onUpload={handleCoverImageChange}
        />

        <div className="mb-4">
          <label htmlFor="font-select" className="block mb-2">
            Choose a font:
          </label>
          <select
            id="font-select"
            value={selectedFont}
            onChange={handleFontChange}
            className="border px-4 py-2 rounded-md w-full"
          >
            <option value="">Select a font</option>
            {fonts.map((font) => (
              <option key={font.family} value={font.family}>
                {font.family}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block font-medium mb-2 text-gray-700 dark:text-gray-300">
            Enter Header Text
          </label>
          <input
            type="text"
            value={welcomeMessage}
            onChange={(e) => setWelcomeMessage(e.target.value)}
            className="w-full px-4 py-2 border rounded-md  dark:bg-gray-600 dark:text-gray-200 transition duration-200"
          />
          <span className="text-sm font-extralight">Click to edit text</span>
        </div>

        <div className="mb-6">
          <label className="block font-medium mb-2 text-gray-700">
            Background Color
          </label>

          <div className="flex flex-col md:flex-row items-start md:items-center gap-2 ">
            <div className="flex items-center gap-2">
              <input
                type="color"
                value={bgColor}
                onChange={(e) => setBgColor(e.target.value)}
                className="p-0 h-10 border-none outline-none cursor-pointer"
                aria-label="Color picker"
              />

              <div className="flex items-center border rounded-md px-3 py-2 bg-gray-100 dark:bg-gray-800">
                <div
                  className="w-6 h-6 rounded-full"
                  style={{ backgroundColor: bgColor }}
                  title={bgColor}
                ></div>
                <input
                  type="text"
                  value={bgColor}
                  onChange={(e) => setBgColor(e.target.value)}
                  placeholder="Enter color (#RRGGBB)"
                  className="border-none bg-transparent text-gray-800 dark:text-white w-24 outline-none"
                  aria-label="Hex color input"
                />
              </div>
            </div>

            <div className="flex items-center gap-2">
              {predefinedColors.map((color, index) => (
                <button
                  aria-label={`Select ${color} color`}
                  key={index}
                  onClick={() => handleBgColorClick(color)}
                  title={color} // Tooltip to show hex code
                  className={`w-5 h-5 rounded-full transition-all transform ${
                    bgColor === color
                      ? "ring-2 ring-offset-2 ring-blue-500 scale-110"
                      : "hover:scale-105"
                  }`}
                  style={{ backgroundColor: color }}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="mb-4">
          <label className="block font-medium mb-2 text-gray-700 dark:text-gray-300">
            Enter Body Text
          </label>
          <textarea
            value={visitMessage}
            onChange={(e) => setVisitMessage(e.target.value)}
            rows={4}
            placeholder="Hello nice to have you with us"
            className="w-full px-4 py-3 border rounded-md bg-white dark:bg-gray-800 dark:text-gray-200 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
          />
        </div>

        <div className="mb-6">
          <label className="block font-medium mb-2 text-gray-700">
            Text Color
          </label>
          <div className="flex flex-col md:flex-row gap-4 items-start md:items-center">
            <div className="flex items-center gap-2">
              <input
                type="color"
                value={selectedTextColor}
                onChange={(e) => handleTextColorClick(e.target.value)}
                className="h-10 w-10 p-0 border-none outline-none cursor-pointer"
                aria-label="Select color"
                title="Select a color"
              />
            <div className="flex items-center border rounded-md px-3 py-2 bg-gray-100 dark:bg-gray-800">
              <div
                className="w-6 h-6 rounded-full mr-2"
                style={{ backgroundColor: selectedTextColor }}
                title={selectedTextColor}
              ></div>
              <input
                type="text"
                value={selectedTextColor}
                onChange={(e) => handleTextColorClick(e.target.value)}
                placeholder="Enter HEX (#FFFFFF)"
                className="border-none bg-transparent text-gray-800 dark:text-white w-24 outline-none"
                aria-label="Hex color input"
              />
            </div>
            </div>


            <div className="flex items-center gap-2">
              {predefinedTextColors.map((color, index) => (
                <button
                  key={index}
                  onClick={() => handleTextColorClick(color)}
                  className={`w-5 h-5 rounded-full transition-all transform ${
                    selectedTextColor === color
                      ? "ring-2 ring-offset-2 ring-blue-500 scale-110"
                      : "hover:scale-105"
                  }`}
                  style={{ backgroundColor: color }}
                  aria-label={`Select text color ${color}`}
                  title={color}
                />
              ))}
            </div>
          </div>
          <p className="text-sm text-gray-500 mt-2">
            Use the color picker, enter a HEX code (e.g., #000000 for black), or choose from predefined colors.
          </p>
        </div>

        <div className="mb-6">
          <label className="block font-medium mb-2 text-gray-700 dark:text-gray-300">
            Action Buttons
          </label>
          {buttons && buttons.length > 0 ? (
            buttons.map((button, index) => (
              <div
                key={index}
                className="mb-6 border rounded-md p-4 bg-white dark:bg-gray-700 relative"
              >
                <h3 className="text-lg font-semibold mb-2 text-gray-800 dark:text-gray-200">
                  Button {index + 1}
                </h3>

                <div className="mb-4">
                  <label className="block font-medium text-gray-700 dark:text-gray-300">
                    {button.isDefault || index === 1
                      ? "Button Text"
                      : "Enter Button Text"}
                  </label>
                  {button.isDefault || index === 1 ? (
                    <p className="w-full px-3 py-2 text-gray-800 dark:text-gray-200 rounded-md bg-transparent border-none">
                      {button.label}
                    </p>
                  ) : (
                    <>
                      <input
                        type="text"
                        value={button.label}
                        onChange={(e) =>
                          handleButtonChange(index, e.target.value)
                        }
                        className="w-full px-3 py-2 rounded-md border dark:bg-gray-600 dark:text-gray-200 transition duration-200"
                        placeholder="Enter button text"
                      />
                      <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                        Click to edit text
                      </p>
                    </>
                  )}
                </div>

                {button.label === "Follow Us On Social Media" && (
                  <>
                    <div className="mb-4">
                      <label className="block font-medium text-gray-700 dark:text-gray-300">
                        Enter facebook URL
                      </label>
                      <input
                        type="text"
                        value={facebookUrl}
                        onChange={(e) => setFacebookUrl(e.target.value)}
                        className="w-full px-3 py-2 rounded-md border dark:bg-gray-600 dark:text-gray-200 transition duration-200"
                        placeholder="https://facebook.com/your-profile"
                      />
                      <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                        Click to edit URL
                      </p>
                    </div>
                    <div className="mb-4">
                      <label className="block font-medium mb-2 text-gray-700 dark:text-gray-300">
                        Enter Instagram URL
                      </label>
                      <input
                        type="url"
                        value={instagramUrl}
                        onChange={(e) => setInstagramUrl(e.target.value)}
                        placeholder="https://instagram.com/your-profile"
                        className="w-full px-3 py-2 rounded-md border dark:bg-gray-600 dark:text-gray-200 transition duration-200"
                      />
                      <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                        Click to edit URL
                      </p>
                    </div>

                    <div className="mb-4">
                      <label className="block font-medium mb-2 text-gray-700 dark:text-gray-300">
                        Enter Twitter URL
                      </label>
                      <input
                        type="url"
                        value={twitterUrl}
                        onChange={(e) => setTwitterUrl(e.target.value)}
                        placeholder="https://twitter.com/your-profile"
                        className="w-full px-3 py-2 rounded-md border dark:bg-gray-600 dark:text-gray-200 transition duration-200"
                      />
                      <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                        Click to edit URL
                      </p>
                    </div>
                  </>
                )}

                {!button.isDefault && (
                  <div className="flex items-center mt-2">
                    <input
                      type="checkbox"
                      checked={button.visible}
                      onChange={() => toggleButtonVisibility(index)}
                      className="w-5 h-5 text-blue-500 border-gray-300 rounded focus:ring-blue-400 focus:ring-2 dark:border-gray-600"
                    />
                    <label className="ml-2 text-gray-800 dark:text-gray-200">
                      Show Button
                    </label>
                  </div>
                )}

                {!button.isDefault &&
                  button.label !== "Follow Us On Social Media" && (
                    <button
                      onClick={() => deleteButton(index)}
                      className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded-md hover:bg-red-600 transition"
                    >
                      Delete
                    </button>
                  )}

                {button.isDefault && (
                  <span className="text-sm px-3 py-1 mt-2 bg-blue-100 text-blue-600 rounded-full dark:bg-blue-800 dark:text-blue-200">
                    Default
                  </span>
                )}
              </div>
            ))
          ) : (
            <p>No action buttons available.</p>
          )}
          <div className="flex justify-start my-4">
            <button
              aria-label="Add New Button"
              onClick={addNewButton}
              className="flex items-center justify-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition"
            >
              <MdPeopleAlt /> Add Button
            </button>
          </div>
          <div className="flex flex-col gap-2 mt-4">
            <label className="block font-medium mb-2 text-gray-700 dark:text-gray-300">
              Action Buttons
            </label>

            <div className="flex flex-col md:flex-row gap-2 items-start md:items-center">
              <div className="flex items-center gap-2">
                <input
                  type="color"
                  value={selectedColor}
                  onChange={(e) => handleGlobalColorChange(e.target.value)}
                  className="h-10 w-10 p-0 border-none outline-none cursor-pointer"
                  aria-label="Select button color"
                  title="Select a color"
                />
                <div className="flex items-center border rounded-md px-3 py-2 bg-gray-100 dark:bg-gray-800">
                  <div
                    className="w-6 h-6 rounded-full mr-2"
                    style={{ backgroundColor: selectedColor }}
                    title={selectedColor}
                  ></div>
                  <input
                    type="text"
                    value={selectedColor}
                    onChange={(e) => handleGlobalColorChange(e.target.value)}
                    placeholder="#000000"
                    className="border-none bg-transparent text-gray-800 dark:text-white w-24 outline-none"
                    aria-label="Hex color input"
                  />
                </div>
              </div>


              <div className="flex items-center gap-2">
                {predefinedColors.map((color, idx) => (
                  <button
                    key={idx}
                    onClick={() => handleGlobalColorChange(color)}
                    className={`w-5 h-5 rounded-full transition-all transform ${
                      selectedColor === color
                        ? "ring-2 ring-offset-2 ring-blue-500 scale-110"
                        : "hover:scale-105"
                    }`}
                    style={{ backgroundColor: color }}
                    aria-label={`Select color ${color}`}
                    title={color}
                  />
                ))}
              </div>
            </div>

            <p className="text-sm text-gray-500 mt-2">
              Choose a color using the picker, enter a HEX code, or select from
              predefined colors.
            </p>
          </div>
        </div>

        <div className="flex items-center justify-between gap-4">
          {!mobilePreviewExists && (
            <button
              aria-label="Save Preview"
              onClick={handleSavePreview}
              disabled={isUpdating}
              className={`flex items-center px-4 py-2 ${
                isUpdating ? "bg-gray-400" : "bg-blue-500"
              } text-white rounded-md hover:bg-blue-600 transition duration-200`}
            >
              <MdPeopleAlt /> Save Preview
            </button>
          )}

          {mobilePreviewExists && (
            <button
              aria-label="Update Preview"
              aria-disabled={isFirstTimeUser}
              onClick={handleUpdatePreview}
              disabled={isFirstTimeUser}
              className={`flex items-center bg-blue-500 text-white py-2 px-4 rounded ${
                isFirstTimeUser ? "bg-gray-400" : ""
              }`}
            >
              <MdPeopleAlt /> Update Feedback Mobile Page
            </button>
          )}
        </div>

      </div>

      <div className="lg:w-1/2 flex justify-center sticky top-[1rem] h-screen">
        <div className="iphone-container">
          <div className="iphone">
            <div className="buttons">
              <div className="silent"></div>
              <div className="sleep"></div>
              <div className="vol-up"></div>
              <div className="vol-down"></div>
            </div>
            <div className="top">
              <div className="black-bar"></div>
              <div className="iphone-top"></div>
            </div>
            <div className="components">
              <div className="speaker"></div>
              <div className="camera">
                <div className="shine-left"></div>
                <div className="shine-right"></div>
              </div>
            </div>
            <div className="top-bar"></div>
            <div className="bottom-bar"></div>
            <div
              className="screen"
              style={{
                backgroundColor: bgColor,
                fontFamily: isFontLoaded ? selectedFont : "Arial, sans-serif",
              }}
            >
              <div className="relative">
                <img
                  src={coverImage}
                  alt="Company Cover"
                  className="w-full h-64 object-cover"
                />
                <img
                  src={companyLogo}
                  alt="Company Logo"
                  className="absolute -bottom-24 left-4 bg-white w-48 h-48 rounded-full border-4 border-gray-200 dark:border-gray-600 object-contain"
                />
              </div>
              <div
                className="mt-24 text-center px-4"
                style={{ color: selectedTextColor }}
              >
                <h1 className="text-3xl font-semibold mb-1">
                  {welcomeMessage}
                </h1>
                <p className="text-2xl mb-6">{visitMessage}</p>
              </div>
              <div className="flex flex-col items-center gap-4 mb-4 px-4">
                {Object.keys(buttons).map((key, index) => {
                  const button = buttons[key];

                  if (button.label === "Follow Us On Social Media") {
                    if (!facebookUrl && !instagramUrl && !twitterUrl) {
                      return null;
                    }
                  }

                  return (
                    button.visible && (
                      <button
                        aria-label={button.label}
                        key={index}
                        style={{
                          color: "#fff",
                          backgroundColor: button.bgColor,
                        }}
                        className={`p-4 mb-2 text-xl text-white rounded-md transition duration-200 hover:bg-blue-600`}
                        onClick={() => {
                          if (button.label === "Follow Us On Social Media") {
                            setShowSocialIcons((prev) => !prev);
                          }
                        }}
                      >
                        {button.label}
                      </button>
                    )
                  );
                })}
              </div>

              {showSocialIcons && (
                <div className="flex space-x-4 justify-center">
                  {facebookUrl && (
                    <a
                      href={facebookUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-center w-10 h-10 rounded-full bg-white transition-transform transform hover:scale-110 hover:shadow-xl"
                      style={{ backgroundColor: "rgba(255, 255, 255, 0.9)" }}
                    >
                      <FaFacebook className="text-blue-600 w-6 h-6" />
                    </a>
                  )}
                  {instagramUrl && (
                    <a
                      href={instagramUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-center w-10 h-10 rounded-full bg-white transition-transform transform hover:scale-110 hover:shadow-xl"
                      style={{ backgroundColor: "rgba(255, 255, 255, 0.9)" }}
                    >
                      <FaInstagram className="text-pink-600 w-6 h-6" />
                    </a>
                  )}
                  {twitterUrl && (
                    <a
                      href={twitterUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-center w-10 h-10 rounded-full bg-white transition-transform transform hover:scale-110 hover:shadow-xl"
                      style={{ backgroundColor: "rgba(255, 255, 255, 0.9)" }}
                    >
                      <FaXTwitter className="text-blue-400 w-6 h-6" />
                    </a>
                  )}
                </div>
              )}

              <footer
                className="absolute bottom-4 text-center w-full"
                style={{ color: dynamicTextColor }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1.5 }}
              >
                <p className="text-lg flex items-center justify-center gap-1">
                  Powered by{" "}
                  <img
                    src={isColorDark(bgColor) ? zolaLight : zola}
                    alt="Zola Logo"
                    className="w-28"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1.5 }}
                  />
                </p>
              </footer>
              <div className="stuff">
                <div className="service">
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                </div>
                <div className="battery">
                  <div className="nub"></div>
                  <div className="energy"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default MobilePreview;

import React from "react";
import Chart from "react-apexcharts";

const AreaChart = () => {
  const chartOptions = {
    chart: {
      type: "area",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      labels: {
        style: {
          colors: "#6B7280",
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 60,
      labels: {
        style: {
          colors: "#6B7280",
          fontSize: "12px",
        },
      },
    },
    colors: ["#3B82F6"],
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: ["#E0F2FE"],
        inverseColors: false,
        opacityFrom: 0.7,
        opacityTo: 0.1,
        stops: [0, 90, 100],
      },
    },
    markers: {
      size: 4,
      colors: ["#3B82F6"],
      strokeColors: "#fff",
      strokeWidth: 2,
    },
    tooltip: {
      theme: "light",
    },
  };

  const chartSeries = [
    {
      name: "NPS",
      data: [10, 20, 15, 25, 50, 30, 40, 35, 30, 20, 10, 5],
    },
  ];

  return <Chart options={chartOptions} series={chartSeries} type="area" height={300} />;
};

export default AreaChart;

import React, { useState } from "react";
import EmojiStarMobile from "./EmojiStarMobile";
import MobilePreview from "./MobilePreview";
import { Link } from 'react-router-dom';

import { GoArrowLeft } from "react-icons/go";

function MobilePreviewPage() {
  const [showEmojiStar, setShowEmojiStar] = useState(false);

  return (
    <>
    <div className=" items-center flex">
    <Link to="/dashboard" className="flex items-center text-[#282A3E] dark:text-white mt-[-15px]">
      <GoArrowLeft  className="w-12 h-6 p-0 "/>
      </Link>
      <div className="relative md:w-8/12 w-full h-10   rounded-full flex   bg-[#F5F8FF] mb-4">
        <label
          className="flex-1 flex items-center justify-center cursor-pointer transition-all duration-300 "
          onClick={() => setShowEmojiStar(false)}
        >
          <input
            type="radio"
            name="toggle"
            value="MobilePreview"
            className="sr-only peer"
            checked={!showEmojiStar}
            readOnly
          />
          <div
            className={`w-full h-full flex items-center justify-center rounded-[58px]
      peer-checked:bg-white  peer-checked:text-blue-500 text-gray-500 
      hover:bg-gray-200 transition-all duration-300 `}
      style={{
        boxShadow: showEmojiStar ? '' : '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814',
      }}
          >
            <span className="text-[16px] font-medium tracking-wide">
              Customize Preview
            </span>
          </div>
        </label>

        <label
          className="flex-1 flex items-center justify-center cursor-pointer transition-all duration-300"
          onClick={() => setShowEmojiStar(true)}
        >
          <input
            type="radio"
            name="toggle"
            value="EmojiStar"
            className="sr-only peer"
            checked={showEmojiStar}
            readOnly
          />
          <div
            className={`w-full h-full flex items-center justify-center rounded-[58px]
      peer-checked:bg-white  peer-checked:text-blue-500 text-gray-500 
      hover:bg-gray-200 transition-all duration-300`}
         style={{
      boxShadow: showEmojiStar ? '0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814' : '',
    }}
          >
            <span className="text-[16px]  font-medium tracking-wide">
              Review method
            </span>
          </div>
        </label>
      </div>
      </div>
     

      {/* Component Display */}
      <div className="">
        {showEmojiStar ? <EmojiStarMobile /> : <MobilePreview />}
      </div>
    
    </>
  );
}

export default MobilePreviewPage;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { HiSparkles } from "react-icons/hi";

function AIInsights() {
  const [insights, setInsights] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentTime, setCurrentTime] = useState("");
  const [companyFirstName, setCompanyFirstName] = useState("");

  useEffect(() => {
    // Load insights from localStorage on component mount
    const savedInsights = localStorage.getItem("insights");
    if (savedInsights) {
      setInsights(savedInsights);
    }
  }, []);

  useEffect(() => {
    // Update time and fetch company name when the component mounts
    const updateTime = () => {
      const now = new Date();
      const formattedTime = now.toLocaleString("en-US", {
        month: "short", // Aug
        day: "2-digit", // 21
        year: "numeric", // 2025
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      setCurrentTime(formattedTime);
    };

    updateTime(); // Set the initial time immediately
    const interval = setInterval(updateTime, 1000); // Update every second

    // Get company name from localStorage
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    const companyName = userData.company_name || "Default Company"; // Fallback if key is missing
    const firstName = companyName.split(" ")[0]; // Get first word of company name
    setCompanyFirstName(firstName);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const fetchInsights = async () => {
    setLoading(true); // Start loading
    try {
      const token = localStorage.getItem("token");
      console.log(token);

      const response = await axios.get(
        "https://zola.technology/api/ai-insights/company/get/",
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Ensure insights is set as a string if it's provided
      if (typeof response.data.insights === "string") {
        setInsights(response.data.insights);
        localStorage.setItem("insights", response.data.insights);
      } else {
        setInsights("No insights available or an unexpected format was received.");
      }
    } catch (err) {
      console.error(err.message || "Failed to fetch insights");
      setInsights({ error: "Failed to fetch insights. Please try again later." });
    } finally {
      setLoading(false); // End loading
    }
  };

  const renderFormattedInsights = () => {
    if (!insights || typeof insights !== "string") return null;

    const sections = insights.split("\n\n").map((section, index) => {
      if (section.startsWith("###")) {
        return (
          <h3
            key={index}
            className="text-lg font-semibold text-gray-800 mt-4 mb-2"
          >
            {section.replace("###", "").trim()}
          </h3>
        );
      } else if (section.startsWith("- ")) {
        return (
          <ul key={index} className="list-disc pl-6 space-y-2 text-gray-700">
            {section
              .split("\n")
              .filter((item) => item.startsWith("- "))
              .map((item, i) => (
                <li key={i}>{item.replace("- ", "").trim()}</li>
              ))}
          </ul>
        );
      } else if (section.startsWith("1. ")) {
        return (
          <ol key={index} className="list-decimal pl-6 space-y-2 text-gray-700">
            {section
              .split("\n")
              .filter((item) => /^[0-9]+\./.test(item))
              .map((item, i) => (
                <li key={i}>{item.replace(/^[0-9]+\.\s/, "").trim()}</li>
              ))}
          </ol>
        );
      } else {
        return (
          <p key={index} className="text-gray-600 leading-7">
            {section.trim()}
          </p>
        );
      }
    });

    return sections;
  };


  return (
    <div className="p-6 bg-white min-h-screen w-full max-w-6xl">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-800">AI Insights</h1>
          <p className="text-sm text-gray-500">
            Zola AI will be our feedback explorer
          </p>
        </div>

        <button
          onClick={fetchInsights}
          className="px-4 py-2 text-blue-500 border border-blue-500 rounded-lg hover:bg-blue-100 flex items-center gap-1"
        >
          {loading ? "Generating..." : "Generate Insights"}
          <HiSparkles className="text-blue-500" />
        </button>
      </div>

      <div className="flex flex-col lg:flex-row items-center justify-center gap-4 lg:gap-16 rounded-lg p-4 mb-6">
        <button className="flex items-center gap-2 px-4 py-2 text-gray-600 border border-gray-300 rounded-lg hover:bg-gray-100 transition">
          <span className="text-lg">‹</span>
          <span className="text-sm font-medium">Previous Insights</span>
        </button>

        <div className="flex items-center gap-2 px-4 py-2 border border-gray-300 rounded-lg text-gray-600">
          <span className="text-lg">📅</span>
          <span className="text-sm font-medium">
      {currentTime} – Summary for {companyFirstName}
    </span>
          {/* <span className="text-gray-500 text-sm">▼</span> */}
        </div>

        <button className="flex items-center gap-2 px-4 py-2 text-gray-600 border border-gray-300 rounded-lg hover:bg-gray-100 transition">
          <span className="text-sm font-medium">Next Insights</span>
          <span className="text-lg">›</span>
        </button>
      </div>

      <div className="bg-gray-50 border border-gray-200 shadow-sm rounded-lg p-6">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">Summary</h2>

        {loading && (
          <p className="text-gray-600 leading-7">Generating insights... Please wait.</p>
        )}

        {!loading && insights && renderFormattedInsights()}

        {!loading && !insights && (
          <p className="text-gray-600 leading-7">
            Click the "Generate Insights" button above to fetch the latest AI
            insights for your business.
          </p>
        )}
      </div>
    </div>
  );
}

export default AIInsights;
import axios from "axios";
import { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import emoji from "./emoji.png";
import emojiSvg from "./emoji.svg";
import stars from "./stars.png";
import starsSvg from "./stars.svg";

const shortenCompanyName = (fullCompanyName) => {
  let mainName = fullCompanyName.split(" - ")[0];
  if (mainName === fullCompanyName) {
    mainName = fullCompanyName.split(",")[0];
  }
  return mainName;
};

function EmojiStarMobile() {
  const [selection, setSelection] = useState("emojis"); // Track selected option ("emoji" or "star")
  const [selectedStars, setSelectedStars] = useState(0); // Track selected stars
  const [preferredBusinessName, setPreferredBusinessName] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [companyData, setCompanyData] = useState([]);
  const [imageLoading, setImageLoading] = useState(true);
  const [selectedRating, setSelectedRating] = useState(0); // default to 0 or another valid rating

  const [questions, setQuestions] = useState([
    { id: 1, question: "How was your experience with our team member?" },
    { id: 2, question: "How was your experience today?" },
  ]);

  const emojis = ["😡", "😞", "😐", "😊", "😍"];
  // const stars = [1, 2, 3, 4, 5];

  const defaultQuestions = {
    team_member_question: "How was your experience with our team member?",
    company_question: "How was your experience today?",
  };

  useEffect(() => {
    const fetchCompanyProfile = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://zola.technology/api/company/profile/",
          { headers: { Authorization: `Bearer ${token}` } }
        );

        console.log(response.data);
        const preferredName = response.data.preferred_business_name;

        setCompanyData(response.data);
        setPreferredBusinessName(preferredName);
        const fullCompanyName = response.data.company_name;
        const shortenedName = shortenCompanyName(fullCompanyName);
        setCompanyName(shortenedName);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchQuestions = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://zola.technology/api/company/feedback/mobile/page/",
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const { team_member_question, company_question } = response.data;

        setQuestions([
          {
            id: 1,
            question:
              team_member_question || defaultQuestions.team_member_question,
          },
          {
            id: 2,
            question: company_question || defaultQuestions.company_question,
          },
        ]);
      } catch (error) {
        console.error("Error fetching questions:", error);
        toast.error("Failed to load feedback questions.");
      }
    };

    fetchCompanyProfile();
    fetchQuestions();
  }, []);

  const handleUpdateCompanyRating = async () => {
    try {
      const token = localStorage.getItem("token");
      const username = companyData.username;

      // Prepare the payload based on the selected rating
      const ratingPayload = {
        company_rate_style: selection === "emojis" ? "emojis" : "stars", // Adjust based on the selection
      };

      console.log(ratingPayload);
      // Send a PATCH request to update the company with the new rating style
      await axios.patch(
        `https://zola.technology/api/company/update/${username}`,
        ratingPayload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      toast.success("Company rating style updated successfully!");
      console.log("Company rating style updated successfully!");
    } catch (error) {
      toast.error("Error updating company rating style:", error);
      console.error("Error updating company rating style:", error);
    }
  };

  const handleUpdateQuestions = async () => {
    try {
      const token = localStorage.getItem("token");

      // Prepare payload ensuring defaults for empty questions
      const payload = {
        team_member_question:
          questions[0].question || defaultQuestions.team_member_question,
        company_question:
          questions[1].question || defaultQuestions.company_question,
      };

      await axios.patch(
        "https://zola.technology/api/company/feedback/mobile/update/",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Questions updated successfully!");
    } catch (error) {
      console.error("Error updating questions:", error);
      toast.error("Failed to update company questions.");
    }
  };

  // const addQuestion = () => {
  //   if (newQuestion.trim() === "") return;

  //   const updatedQuestions = [
  //     ...questions,
  //     { id: Date.now(), question: newQuestion.trim() },
  //   ];
  //   setQuestions(updatedQuestions);
  //   setNewQuestion("");

  //   handleUpdateQuestions();
  // };

  const handleFullUpdate = async () => {
    try {
      await handleUpdateCompanyRating(); // Step 1: Update rating style
      await handleUpdateQuestions();

      // toast.success("Company rating and feedback questions updated!");
    } catch (error) {
      toast.error("Failed to update rating or feedback questions.");
      console.error("Error during full update:", error);
    }
  };

  // const deleteQuestion = (id) => {
  //   const updatedQuestions = questions.filter((q) => q.id !== id);
  //   setQuestions(updatedQuestions);

  //   handleUpdateQuestions();
  // };

  const handleQuestionChange = (index, newQuestion) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].question = newQuestion;
    setQuestions(updatedQuestions); // Update the state with the new question
  };

  const handleRatingChange = (questionId, rating) => {
    setSelectedRating((prev) => ({
      ...prev,
      [questionId]: rating,
    }));
  };

  return (
    <div className="flex w-full flex-col lg:flex-row min-h-screen md:p-6 transition duration-300">
      <ToastContainer />

      <div className="lg:w-1/2 w-full rounded-lg p-6 bg-white dark:bg-gray-700 transition duration-300 flex flex-col items-center gap-8">
        <ul className="w-full">
          {questions.map((q, index) => (
            <li key={q.id} className="mb-6">
              <div className="border rounded-lg p-4 md:p-6 bg-white dark:bg-gray-700 shadow-md">
                {/* Descriptive Text */}
                <p className="text-gray-600 dark:text-gray-300 mb-2 text-sm">
                  {index === 0
                    ? "This question asks about the customer's experience with a team member. You can edit it to reflect your specific needs."
                    : "This question covers the overall customer experience. Feel free to customize it for your business."}
                </p>

                <input
                  type="text"
                  value={q.question}
                  onChange={(e) => handleQuestionChange(index, e.target.value)}
                  className="w-full p-3 border rounded-md bg-transparent dark:bg-gray-600 dark:text-gray-200 focus:ring-2 focus:ring-blue-500 transition duration-200"
                  placeholder={`Default: ${
                    index === 0
                      ? defaultQuestions.team_member_question
                      : defaultQuestions.company_question
                  }`}
                />
              </div>
            </li>
          ))}
        </ul>

        <h2 className="text-[20px] text-center font-medium text-[#6F707E] dark:text-gray-200">
          Choose your preferred review method
        </h2>

        <div className="flex justify-center items-center gap-2">
          <img
            src={emojiSvg}
            alt="Emoji Image"
            onClick={() => setSelection("emojis")}
            className={`flex flex-col items-center justify-center px-4 py-4 rounded-[16px] border-[1px] border-[#EAEAEC] font-semibold text-[20px] transition-all h-40 bg-white
            ${
              selection === "emojis"
                ? "border-blue-500 text-blue-600 shadow-lg"
                : "border-gray-200 text-gray-600 hover:bg-blue-50 hover:border-blue-400"
            }`}
          />

          <img
            src={starsSvg}
            alt="Star Image"
            onClick={() => setSelection("stars")}
            className={`flex flex-col items-center justify-center px-4 py-4 rounded-[16px] border-[1px] border-[#EAEAEC] font-semibold text-[20px] transition-all h-40 bg-white
            ${
              selection === "stars"
                ? "border-blue-500 text-blue-600 shadow-lg"
                : "border-gray-200 text-gray-600 hover:bg-blue-50 hover:border-blue-400"
            }`}
          />
        </div>

        <div className="mt-4">
          <button
            aria-label="Update"
            onClick={handleFullUpdate}
            className="px-24 py-2 bg-blue-600 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-blue-700 hover:scale-105 transition-all"
          >
            Update
          </button>
        </div>
      </div>

      <div className="lg:w-1/2 flex justify-center sticky top-[1rem] h-screen">
        <div className="iphone-container">
          <div className="iphone bg-white">
            <div className="buttons">
              <div className="silent"></div>
              <div className="sleep"></div>
              <div className="vol-up"></div>
              <div className="vol-down"></div>
            </div>
            <div className="top"></div>
            <div className="components">
              <div className="speaker"></div>
              <div className="camera">
                <div className="shine-left"></div>
                <div className="shine-right"></div>
              </div>
            </div>
            <div className="top-bar"></div>
            <div className="bottom-bar"></div>
            <div className="h-full w-full mt-16 flex justify-center items-center flex-col">
              <div className="flex items-center justify-center gap-2">
                <div className="relative">
                  {imageLoading && (
                    <div className="w-16 h-16 bg-gray-200 rounded-full mb-4 animate-pulse"></div>
                  )}
                  {companyData.profile_image && (
                    <img
                      src={companyData.profile_image}
                      alt="Company Profile"
                      className={`w-16 h-16 rounded-full object-contain mb-4 border-4 border-blue-300 shadow-lg transition-opacity duration-500 ${
                        imageLoading ? "opacity-0" : "opacity-100"
                      }`}
                      onLoad={() => setImageLoading(false)} // Handle image load
                      onError={() => setImageLoading(false)} // Handle image error
                    />
                  )}
                </div>
                <h1 className="text-xl text-center font-bold text-gray-800 mb-2">
                  {preferredBusinessName || companyName}
                </h1>
              </div>
              {questions.map((q) => (
                <div
                  key={q.id}
                  className="flex flex-col w-full text-center px-6 gap-4 items-center justify-center"
                >
                  <h4 className="text-2xl font-bold text-gray-900 text-center">
                    {q.question}
                  </h4>
                  <div className="flex justify-start items-center gap-2 mb-4">
                    {selection === "emojis"
                      ? ["😡", "😞", "😐", "😊", "😍"].map((emoji, index) => (
                          <span
                            key={index}
                            className={`text-4xl cursor-pointer ${
                              selectedRating[q.id] === emoji
                                ? "ring-2 ring-blue-500"
                                : ""
                            }`}
                            onClick={() => handleRatingChange(q.id, emoji)}
                          >
                            {emoji}
                          </span>
                        ))
                      : [1, 2, 3, 4, 5].map((star) => (
                          <FaStar
                            key={star}
                            size={40}
                            className={`cursor-pointer ${
                              selectedRating[q.id] >= star
                                ? "text-yellow-500"
                                : "text-gray-300"
                            }`}
                            onClick={() => handleRatingChange(q.id, star)}
                          />
                        ))}
                  </div>
                </div>
              ))}

              <div className="stuff">
                <div className="service">
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                </div>
                <div className="absolute top-[11px] right-[32px] h-[16px] w-[32px] border border-white rounded-[2px] flex items-center z-[200]">
                  <div className="absoulte bg-white top-[4px] right-[-4px] h-[6px] w-[2px] rounded-sm"></div>
                  <div className="absolute bg-black left-[1px] h-[12px] w-[18px] rounded-sm"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmojiStarMobile;

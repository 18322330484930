import React, { useContext, useEffect, useState } from "react";
import { FaExclamationTriangle, FaBuilding } from "react-icons/fa";
import { TiArrowSortedDown } from "react-icons/ti";
import { PiGitBranchBold } from "react-icons/pi";
import { PiUsersFill } from "react-icons/pi";

import { BranchContext } from "../context/BranchContext";

import { HiChartBar } from "react-icons/hi";
import ReactApexChart from "react-apexcharts";
import { color } from "framer-motion";
import { data } from "autoprefixer";
import cover from "../assets/cover.png";
import { useMediaQuery } from "@mui/material";
import { fontSize, height, padding } from "@mui/system";
import FeedbackAnalytics from "./Analytics/FeedbackAnalytics";
import StatsCard from "./Analytics/StatsCard";
import BranchPerformanceOverview from "./Analytics/BranchPerformanceOverview ";
import EmployeePerformanceOverview from "./Analytics/EmployeePerformance";
import TotalTapsChart from "./Analytics/TotalTaps";
import OverviewSection from "../components/overview/OverviewSection";

const HQAnalytics = () => {
  const [selectedBarTime, setSelectedBarTime] = useState("This Month");
  const [selectedPieTime, setSelectedPieTime] = useState("This Month");
  const [isBarDropdownOpen, setBarDropdownOpen] = useState(false);
  const [isPieDropdownOpen, setPieDropdownOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const timelines = ["This Month", "Last 3 Months", "Last 6 Months"];
  const { branches, employeeTaps } = useContext(BranchContext);
  const [branch, setBranch] = useState(null);
  const [branchData, setBranchData] = useState(null);
  const [allBranchData, setAllBranchData] = useState(null);
  const [error, setError] = useState(null);
  // Retrieve token from local storage

  useEffect(() => {
    const fetchBranchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("No token found");
        return;
      }

      try {
        // Fetch data from the first endpoint
        const response1 = await fetch(
          "https://zola.technology/api/hq-company/branches/get-data/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response1.ok) {
          throw new Error(`Error: ${response1.status}`);
        }

        const branchData = await response1.json();

        // Fetch data from the second endpoint
        const response2 = await fetch(
          "https://zola.technology/api/hq-company/list/paginated-or-all-branch-companies/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response2.ok) {
          throw new Error(`Error: ${response2.status}`);
        }

        const allBranchData = await response2.json();

        // You can update states for both data sets
        setBranchData(branchData); // Update state for the first fetch
        setAllBranchData(allBranchData); // Update state for the second fetch
      } catch (error) {
        setError(error.message);
      }
    };

    fetchBranchData();
  }, []); // Empty dependency array to run only on mount

  console.log(branchData?.total_negative_feedback_count, "set state");
  console.log(allBranchData, "all branch");

  // Data for the bar chart
  const employeeData = branches
    ? branches.map((branch) => ({
        name: branch.name,
        employees: branch.employees,
      }))
    : [];

  // ApexCharts barOptions and barSeries
  const barOptions = {
    chart: {
      type: "bar",

      width: isSmallScreen ? "100%" : "100%",
    },
    plotbarOptions: {
      bar: {
        borderRadius: 0,
        dataLabels: {
          position: "top",
        },
        columnWidth: isSmallScreen ? "30%" : "50%", // Increase space between columns on small screens
      },
    },

    colors: ["rgba(66, 100, 223, 0.39)"],

    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: employeeData.map((data) => data.name),
      position: "bottom",
      labels: {
        offsetY: 0,
        rotate: 0, // Set rotation to 0 to display labels horizontally
        style: {
          fontSize: isSmallScreen ? "10px" : "12px", // Smaller font size on small screens
        },
      },
      axisBorder: {
        show: !isSmallScreen, // Hide x-axis line on small screens
      },
      axisTicks: {
        show: !isSmallScreen, // Hide tick marks on small screens
      },
      barHeight: "70%", // Adjust the bar height to space out the bars
    },
    yaxis: {
      labels: {
        formatter: (val) => val,
      },
    },
    tooltip: {
      y: {
        formatter: (val) => val,
      },
    },
    grid: {
      show: !isSmallScreen, // Hide grid lines on small screens
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            width: "118%",
            offsetX: 5,
          },
          xaxis: {
            labels: {
              rotate: 0,
              style: {
                fontSize: "8px",
              },
            },
          },
          axisBorder: {
            show: false, // Hide x-axis line on small screens
          },
          axisTicks: {
            show: false, // Hide tick marks on small screens
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "30%", // Increase space between columns on small screens
          },
        },
        grid: {
          show: false, // Hide grid lines on small screens
        },
      },
    ],
  };

  const barSeries = [
    {
      name: "Employees",
      data: employeeData?.map((data) => data.employees) || [],
    },
  ];

  const colors = ["#E52777", "#00C459", "#FFBC07", "#209EFF", "#A577FF"];
  // Data for the pie chart
  const performanceData =
    allBranchData?.map((branch, index) => ({
      id: index,
      value: branch.performance_score || 0,
      label: branch.preferred_business_name,
      color: colors[index % colors.length],
    })) || [];

  // Sort the performance data by value in descending order and slice the top 5 entries
  // Fallback to an empty array if performanceData is undefined or null
  const top5PerformanceData = performanceData?.slice(0, 5) || [];

  // Calculate total value for percentage calculation with a fallback to 1 to avoid division by zero
  const totalValue =
    top5PerformanceData.reduce((sum, item) => sum + (item?.value || 0), 0) || 1;

  // Extract values, labels, and percentages for the donut chart with fallbacks
  const donutSeries = top5PerformanceData.map((data) => data?.value || 0);
  const donutLabels = top5PerformanceData.map(
    (data) => data?.label || "Unknown"
  );
  const donutPercentages = top5PerformanceData.map((data) => {
    const value = data?.value || 0;
    return ((value / totalValue) * 100).toFixed(2);
  });

  // Base width for the legend marker
  const baseWidth = isSmallScreen ? 4 : 5; // You can adjust this value as needed

  // ApexCharts options for the donut chart
  const donutOptions = {
    chart: {
      type: "donut",
      height: 250,
      offsetX: -100,
    },
    labels: donutLabels,
    plotOptions: {
      pie: {
        donut: {
          size: isSmallScreen ? "65%" : "65%", // Adjust the size of the donut chart

          labels: {
            show: true,
            total: {
              show: true,
              label: "Top 5 Locations",
              formatter: function () {
                // Use Unicode spaces to simulate line breaks
                return "";
              },
              style: {
                fontSize: isSmallScreen ? "16px" : "16px", // Adjust the font size if needed
                fontWeight: 800,
                color: "#000", // Customize the color as needed
              },
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 6, // Increase the stroke width to 4
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 500,
            offsetX: 0,
            offsetY: 5,
          },

          legend: {
            position: "bottom",
            offsetY: 5,
            offsetX: 0,
            fontSize: "8px", // Smaller font size for small screens
            height: 200,
            itemPadding: {
              horizontal: 2,
              vertical: 2,
            },
            labels: {
              fontSize: "14px", // Smaller font size for small screens
              offsetX: 0,
            },
            itemMargin: {
              horizontal: 2,
              vertical: 2,
            },
          },
        },
      },
    ],
    legend: {
      position: "right",
      offsetY: 0,
      offsetX: isSmallScreen ? 0 : 0, // Adjust the offset on small screens
      with: 300,
      height: 240,
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },

      formatter: function (seriesName, opts) {
        const index = opts.seriesIndex;
        const percentage = donutPercentages[index];
        const color = top5PerformanceData[index].color;
        const finalWidth = baseWidth * percentage;
        return `
          <div class="custom-legend-item">
            <div class="legend-marker" style="width: ${finalWidth}px; background-color: ${color};">
              ${percentage}%
            </div>
            ${seriesName}
          </div>
        `;
      },
    },
    fill: {
      colors: top5PerformanceData?.map((data) => data.color),
    },
  };

  return (
    <div className="dark:bg-gradient-to-b from-[#1F2937] to-[#2D3748] min-h-screen">
      <OverviewSection title="Analytics" showExportButton={true} />
      <FeedbackAnalytics />
      <div className="p-6 bg-white rounded-lg shadow-md mb-8">
        <h2 className="text-lg font-medium mb-4">
          Branch performance overview
        </h2>
        <BranchPerformanceOverview/>
      </div>
      <StatsCard />
      <EmployeePerformanceOverview />
      <TotalTapsChart />
    </div>
  );
};

export default HQAnalytics;

import React from "react";
import { Bar } from "react-chartjs-2";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";

const AnalyticsCards = () => {
  // Inline data for the cards
  const cardData = [
    {
      country: "United States",
      score: "50.7",
      change: "-15%",
      prev: "Prev 60.9",
      bars: [20, 40, 60, 80, 100],
      indicator: "down",
    },
    {
      country: "Canada",
      score: "41.9",
      change: "+33%",
      prev: "Prev 31.8",
      bars: [10, 30, 50, 70, 90],
      indicator: "up",
    },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {cardData.map((stat, index) => {
        // Chart data for the bar graph
        const data = {
          labels: Array(stat.bars.length).fill(""), // Empty labels for cleaner graph
          datasets: [
            {
              data: stat.bars,
              backgroundColor: stat.bars.map(
                (_, i) => (i === stat.bars.length - 1 ? "#6B46C1" : "#E9D8FD") // Purple for the last bar
              ),
              borderRadius: 8, // Rounded corners for bars
              barPercentage: 0.8, // Thicker bars
            },
          ],
        };

        // Chart options for styling
        const options = {
          plugins: { legend: { display: false } },
          scales: {
            x: { display: false }, // Hide x-axis
            y: { display: false }, // Hide y-axis
          },
          maintainAspectRatio: false,
        };

        return (
          <div
            key={index}
            className="bg-white p-6 rounded-lg shadow flex justify-between items-center"
          >
            {/* Left Section */}
            <div className="flex flex-col">
              <h3 className="text-lg font-semibold text-gray-800">
                {stat.country}
              </h3>

              <div className="flex items-center">
                <p className="text-3xl font-bold text-gray-800">
                  {stat.score}
                  <span className={`text-sm ${stat.indicator === "up" ? (
                    "text-green-500"
                  ) : (
                    "text-red-500"
                  )} mb-2`}>
                    vs {stat.prev}
                  </span>
                </p>
              </div>
              <p className="text-xs text-gray-500 mt-1 flex">
                <span
                  className={`flex items-center text-sm ${
                    stat.change.startsWith("-")
                      ? "text-red-500"
                      : "text-green-500"
                  }`}
                >
                  {stat.indicator === "up" ? (
                    <FaArrowUp className="mr-1" />
                  ) : (
                    <FaArrowDown className="mr-1" />
                  )}
                  {stat.change}
                </span>
                vs last month
              </p>
            </div>

            {/* Right Section - Bar Graph */}
            <div className="relative w-24 h-12">
              <Bar data={data} options={options} />
              {/* Circular Indicator */}
              {stat.indicator === "down" ? (
                <div className="absolute -top-3 right-0 bg-red-500 text-white text-xs w-4 h-4 flex items-center justify-center rounded-full">
                  <FaArrowDown />
                </div>
              ) : (
                <div className="absolute -top-3 right-0 bg-green-500 text-white text-xs w-4 h-4 flex items-center justify-center rounded-full">
                  <FaArrowUp />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AnalyticsCards;

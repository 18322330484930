import React from 'react';
import NavbarMenu from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer-V3';
import { Outlet } from 'react-router-dom';

const PublicLayout = () => {
  return (
    <div className="relative">
      <NavbarMenu />
      <div className="absolute md:top-24 w-full">
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

export default PublicLayout;
import Home from "./Home/Home";
import EffectiveReview from "./Landing-Page-Card/EffectiveReview";
import Reputation from "./Reputation/Reputation";
// import CustomerExperience from "./Landing-Page-Card/CustomerExperience";
import Testimonials from "./Testimonials/Testimonials";
import WhyZola from "./WhyZola";
import Clients from "./Clients/Clients";
import CollectReviews from "./CollectReviews/CollectReviews";
import RealTime from "./RealTime/RealTime";
// import Insights from "./Features-Insights/Insights";
// import HomeDashboard from "./Home-Dashboard/HomeDashboard";
// import Mask from "./Mask/Mask";
// import HowItWorks from "./How-It-Works/HowItWorks";
// import CentralizeFeedback from "./Centralize-Feedback/CentralizeFeedback";
// import DataDrivenCard from "./Data-Driven-Card/DataDrivenCard";
import CustomerExperience from "./CustomerExperience/CustomerExperience";
import WhyChooseZola from "./WhyChooseZola/WhyChooseZola";
import RealTimeAnalytics from "./RealTime/RealTimeAnalytics";
import TestimonialComponent from "./Testimonials-v2/Testimonials";
import ZolaInfoWithCarousel from "./AutoCarousel/AutoCarousel";
import CustomerResults from "./CustomerResults/CustomerResult";
import Performance from "./Perfomance/Performance";
import HowItWorks from "./HowItWorks/HowItWorks";
import GetReviews from "./GetReviews/GetReviews";
import ClearlyTrack from "./ClearlyTrack/ClearlyTrack";
import Testimonial from "./Testimonial/Testimonial";
import Ready from "./Ready/Ready";
import KeyBenefits from "./KeyBenefits/KeyBenefits";
import LogoCarousel from "./LogoCarousel/LogoCarousel"
import Chatbot from "../components/Chatbot/Chatbot";
import Features from "./Features2/Features";
function Homepage() {
  
  return (
    <>
      <Home />
      <LogoCarousel />
      <HowItWorks />
      {/* <Features/> */}
      <CustomerResults />
     
      <KeyBenefits />
      <Performance />
      <GetReviews />
      <ClearlyTrack />
      
      
     
      
      {/* {<ClearlyTrack />} */}
      <Testimonial />
      <Ready />
      <Chatbot />
      {/* <Clients />
      <ZolaInfoWithCarousel />
      <RealTimeAnalytics />
      <CollectReviews />
      <RealTime />
      <CustomerExperience />
      <WhyChooseZola />
      <TestimonialComponent /> */}
      {/* <WhyZola />
      <HomeDashboard />
      <Insights />
      <EffectiveReview />
      <CustomerExperience />
      <Testimonials />
      <Reputation /> */}
    </>
  )
}

export default Homepage
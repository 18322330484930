import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useTimeRange } from "../../../context/TimeRangeContext";

function OverallPerformance() {
  const { timeRange } = useTimeRange();
  const [isDarkMode, setIsDarkMode] = useState(false); // Manage dark mode state

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Taps",
        data: [], // Initialize with an empty array
      },
    ],
    options: {
      chart: {
        height: 300,
        type: "area",
        toolbar: {
          show: false,
        },
        background: "transparent", // Background for the chart
      },
      xaxis: {
        categories: [], // Initialize with an empty array
        labels: {
          rotate: -45,
          style: {
            colors: isDarkMode ? "#FFFFFF" : "#333333", // Dynamic text color
          },
        },
      },
      yaxis: {
        labels: {
          formatter: (value) => value.toFixed(0),
          style: {
            colors: isDarkMode ? "#FFFFFF" : "#333333", // Dynamic text color
          },
        },
      },
      stroke: {
        curve: "straight",
        width: 2,
      },
      markers: {
        size: 5,
        colors: ["#3366FF"],
        strokeColors: "#fff",
        strokeWidth: 2,
      },
      grid: {
        borderColor: isDarkMode ? "#555555" : "#e7e7e7", // Dynamic grid line color
        strokeDashArray: 5,
      },
      tooltip: {
        theme: isDarkMode ? "dark" : "light", // Dynamic tooltip theme
      },
      colors: ["#3366FF"],
      fill: {
        type: "gradient",
        gradient: {
          shade: isDarkMode ? "dark" : "light", // Gradient shade for dark mode
          type: "vertical",
          shadeIntensity: 0.5,
          gradientToColors: ["#99CCFF"],
          inverseColors: false,
          opacityFrom: 0.9,
          opacityTo: 0.3,
          stops: [0, 100],
        },
      },
    },
  });

  const fetchPerformanceData = async (filter) => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `https://zola.technology/api/company/performance/get-spread-taps/?filter=${filter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching performance data:", error);
      return { periods: [] };
    }
  };

  useEffect(() => {
    // Dynamically determine dark mode based on a class or context (optional customization)
    const root = document.documentElement;
    const darkModeEnabled = root.classList.contains("dark");
    setIsDarkMode(darkModeEnabled);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchPerformanceData(timeRange);

      // Extract periods and taps from the API response
      const tapsData = data.periods.map((item) => item.taps);
      const categories = data.periods.map((item) => item.period);

      // Find the maximum number of taps for the y-axis range
      const maxValue = Math.max(...tapsData);
      const tickInterval = Math.ceil(maxValue / 10);

      // Update the chart data
      setChartData((prevData) => ({
        ...prevData,
        series: [
          {
            name: "Taps",
            data: tapsData,
          },
        ],
        options: {
          ...prevData.options,
          chart: {
            ...prevData.options.chart,
            background: isDarkMode ? "#1F2937" : "transparent", // Dynamic background color
          },
          xaxis: {
            ...prevData.options.xaxis,
            categories: categories,
            labels: {
              ...prevData.options.xaxis.labels,
              style: {
                colors: isDarkMode ? "#FFFFFF" : "#333333",
              },
            },
          },
          yaxis: {
            ...prevData.options.yaxis,
            min: 0,
            max: maxValue + tickInterval,
            tickAmount: 10,
            labels: {
              ...prevData.options.yaxis.labels,
              formatter: (value) => value.toFixed(0),
              style: {
                colors: isDarkMode ? "#FFFFFF" : "#333333",
              },
            },
          },
          grid: {
            ...prevData.options.grid,
            borderColor: isDarkMode ? "#555555" : "#e7e7e7",
          },
          tooltip: {
            ...prevData.options.tooltip,
            theme: isDarkMode ? "dark" : "light",
          },
        },
      }));
    };

    fetchData();
  }, [timeRange, isDarkMode]);

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md mb-8">
      <h2 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-4">
        Performance Overview
      </h2>
      <div className="w-[95%] md:w-full ">
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="area"
        height={300}
      />
      </div>
    </div>
  );
}

export default OverallPerformance;

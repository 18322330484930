import React, { useEffect, useState } from "react";
import Overview from "./Overview";
import AreaChart from "./Surveyanalytics/SurveyChart";

function SurveyAnalysis() {
  const [npsData, setNpsData] = useState([]);
  const [csatData, setCsatData] = useState([]);
  const [error, setError] = useState(null);

  const fetchNPSData = async (filter = "month") => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `https://zola.technology/api/company/surveys/get-data/nps/?filter=${filter}r`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();

      // Transform the data into cardData format
      const transformedData = [
        {
          label: "Total Responses",
          value: (
            data.promoters.count +
            data.passives.count +
            data.detractors.count
          ).toLocaleString(),
          change: `${data.nps_change.total_change > 0 ? "+" : ""}${
            data.nps_change.total_change
          }`,
          comparison: `vs last ${filter}`,
          changeColor:
            data.nps_change.type === "increase"
              ? "text-green-500"
              : "text-red-500",
        },
        {
          label: "Promoters",
          value: data.promoters.count.toLocaleString(),
          change: `${data.promoters.percentage.toFixed(1)}%`,
          comparison: `of total`,
          changeColor: "text-green-500",
        },
        {
          label: "Detractors",
          value: data.detractors.count.toLocaleString(),
          change: `${data.detractors.percentage.toFixed(1)}%`,
          comparison: `of total`,
          changeColor: "text-green-500",
        },
        {
          label: "Passives",
          value: data.passives.count.toLocaleString(),
          change: `${data.passives.percentage.toFixed(1)}%`,
          comparison: `of total`,
          changeColor: "text-gray-500",
        },
        {
          label: "NPS Score",
          value: data.nps_score.toFixed(1),
          change: `${data.nps_change.percentage_change.toFixed(1)}%`,
          comparison: `vs last ${filter}`,
          changeColor:
            data.nps_change.type === "increase"
              ? "text-green-500"
              : "text-red-500",
        },
      ];

      setNpsData(transformedData);
    } catch (error) {
      console.error("Error fetching NPS data:", error);
      setError("Failed to fetch NPS data");
    }
  };

  const fetchCSATData = async (filter = "month") => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `https://zola.technology/api/company/surveys/get-data/csat/?filter=${filter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();

      console.log(data)
      const transformedCSATData = [
        {
          label: "Total Responses",
          value: data.total_responses.toLocaleString(),
          change: `${data.csat_change.total_change > 0 ? "+" : ""}${
            data.csat_change.total_change
          }`,
          comparison: `vs last ${filter}`,
          changeColor:
            data.csat_change.type === "increase"
              ? "text-green-500"
              : "text-red-500",
        },
        {
          label: "Satisfied Responses",
          value: data.satisfied_responses.toLocaleString(),
          // change: `${(
          //   (data.satisfied_responses / data.total_responses) *
          //   100
          // ).toFixed(1)}%`,
          // comparison: "of total",
          // changeColor: "text-green-500",
        },
        {
          label: "Neutral Responses",
          value: data.neutral_responses.toLocaleString(),
          // change: `${(
          //   (data.neutral_responses / data.total_responses) *
          //   100
          // ).toFixed(1)}%`,
          // comparison: "of total",
          // changeColor: "text-gray-500",
        },
        {
          label: "Dissatisfied Responses",
          value: data.dissatisfied_responses.toLocaleString(),
          // change: `${(
          //   (data.dissatisfied_responses / data.total_responses) *
          //   100
          // ).toFixed(1)}%`,
          // comparison: "of total",
          // changeColor: "text-red-500",
        },
        {
          label: "CSAT Score",
          value: data.csat_score.toFixed(1),
          change: `${data.csat_change.percentage_change.toFixed(1)}%`,
          comparison: `vs last ${filter}`,
          changeColor:
            data.csat_change.type === "increase"
              ? "text-green-500"
              : "text-red-500",
        },
      ];

      setCsatData(transformedCSATData);
    } catch (error) {
      console.error("Error fetching CSAT data:", error);
      setError("Failed to fetch CSAT data");
    }
  };

  useEffect(() => {
    fetchNPSData();
    fetchCSATData();
  }, []);

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div>
      <Overview showExportButton={false} />
      {/* NPS Section */}
      <div className="mb-8">
        <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-100 mb-4">
          Net Promoter Score (NPS)
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
          {npsData.map((item, index) => (
            <div
              key={index}
              className="p-4 space-y-2 bg-white dark:bg-gray-800 rounded-lg text-center flex flex-col justify-between"
            >
              <h3 className="text-sm font-semibold text-gray-500 dark:text-gray-400">
                {item.label}
              </h3>
              <p className="text-2xl font-bold text-gray-900 dark:text-gray-100">
                {item.value}
              </p>
              <div className="text-sm flex items-center justify-center space-x-2">
                <span className={`font-medium ${item.changeColor}`}>
                  {item.change}
                </span>
                <span className="text-gray-500 dark:text-gray-400">
                  {item.comparison}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* CSAT Section */}
      <div>
        <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-100 mb-4">
          Customer Satisfaction Score (CSAT)
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
          {csatData.map((item, index) => (
            <div
              key={index}
              className="p-4 space-y-2 bg-white dark:bg-gray-800 rounded-lg text-center flex flex-col justify-between"
            >
              <h3 className="text-sm font-semibold text-gray-500 dark:text-gray-400">
                {item.label}
              </h3>
              <p className="text-2xl font-bold text-gray-900 dark:text-gray-100">
                {item.value}
              </p>
              <div className="text-sm flex items-center justify-center space-x-2">
                <span className={`font-medium ${item.changeColor}`}>
                  {item.change}
                </span>
                <span className="text-gray-500 dark:text-gray-400">
                  {item.comparison}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SurveyAnalysis;

import React, { useState, useEffect } from "react";
import {
  IoMdAddCircleOutline,
  IoIosRadioButtonOff,
  IoIosCheckmarkCircle,
  IoMdList,
} from "react-icons/io";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaExclamationTriangle } from "react-icons/fa";

import { MdVerifiedUser } from "react-icons/md";

function Survey() {
  const [title, setTitle] = useState("");
  const [questions, setQuestions] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // For the popup modal
  const [currentQuestion, setCurrentQuestion] = useState({
    text: "",
    type: "",
    options: ["", "", "", ""], // Default 4 options for single/multiple choice
  });
  const [creationMode, setCreationMode] = useState(""); // "ai" or "manual"
  // const [aiQuestions] = useState([
  //   {
  //     text: "How satisfied are you with our service?",
  //     type: "single",
  //     options: ["Very Satisfied", "Satisfied", "Neutral", "Dissatisfied"],
  //   },
  //   { text: "What can we improve?", type: "paragraph", options: [] },
  //   {
  //     text: "Would you recommend us to others?",
  //     type: "single",
  //     options: ["Yes", "No"],
  //   },
  // ]);
  const [aiPrompt, setAiPrompt] = useState("");
  const [aiQuestions, setAiQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [showPopUp, setShowPopUp] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState({
    id: null,
    active: false,
  });
  const handleGenerateAIQuestions = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token"); // Fetch token from local storage

      // Construct the URL with the aiPrompt as a query parameter
      const url = `https://zola.technology/api/company/surveys/ai/generate-questions/?industry=${encodeURIComponent(
        aiPrompt
      )}`;

      const response = await fetch(url, {
        method: "GET", // Use GET since the API expects it
        headers: {
          Authorization: `Bearer ${token}`, // Pass token in the Authorization header
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch AI-generated questions");
      }

      const data = await response.json();
      console.log(data);

      // Set the AI-generated questions to state
      setAiQuestions(data.questions);
    } catch (error) {
      console.error("Failed to generate questions:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAIQuestions = async (prompt) => {
    const response = await axios.post(
      "https://your-ai-api.com/generate",
      { prompt },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  };

  const handleRegenerateQuestions = () => {
    setAiPrompt(""); // Clear the current prompt for a new one
    setAiQuestions([]); // Clear the current questions
  };

  const handleSubmitAIQuestions = async () => {
    const token = localStorage.getItem("token");
    const surveyData = {
      title: "This survey aims to gather insightful feedback.",
      questions: aiQuestions.map((q) => ({
        question_type: q.type === "multiple_choice" ? "multi_choice" : q.type,
        question_text: q.question,
        choices: q.choices || [],
      })),
    };
    console.log(surveyData);
    try {
      const response = await fetch(
        "https://zola.technology/api/company/surveys/add-survey/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(surveyData),
        }
      );

      if (response.ok) {
        setIsModalOpen(false); // Close the modal
        toast.success("Survey saved successfully.");
      } else {
        alert("Failed to save survey. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting survey:", error);
      alert("An error occurred while saving the survey.");
    }
  };

  const handleModeSelection = (mode) => {
    setCreationMode(mode);
  };

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    getAllSurveys();
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setCurrentQuestion({ text: "", type: "", options: ["", "", "", ""] }); // Reset current question
  };

  const updateQuestionType = (type) => {
    setCurrentQuestion((prev) => ({
      ...prev,
      type,
      options: type === "paragraph" ? [] : ["", "", "", ""],
    }));
  };

  const addQuestion = () => {
    if (!currentQuestion.text || !currentQuestion.type) {
      alert("Please fill in the question and select a type.");
      return;
    }
    setQuestions((prev) => [...prev, currentQuestion]);
    setCurrentQuestion({ text: "", type: "", options: ["", "", "", ""] });
  };

  const saveCurrentQuestion = () => {
    if (!currentQuestion.text || !currentQuestion.type) {
      alert("Please complete the question details before saving.");
      return;
    }
    setQuestions((prev) => [...prev, currentQuestion]);
    toggleModal(); // Close modal
  };

  const saveSurvey = async () => {
    const formattedQuestions = questions.map((q) => ({
      question_text: q.text,
      question_type: q.type === "single" ? "single_choice" : q.type,
      choices: q.type === "paragraph" ? [] : [...q.options], // Save choices as an array
    }));

    const surveyData = { title, questions: formattedQuestions };

    try {
      const response = await axios.post(
        "https://zola.technology/api/company/surveys/add-survey/",
        surveyData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Survey saved successfully!");
      getAllSurveys();
      setTitle("");
      setQuestions([]);
      setIsModalOpen(false); // Close the modal
      toast.success("Survey saved successfully.");
    } catch (error) {
      console.error("Error saving survey:", error);
      toast.error("Failed to save survey.");
    }
  };

  const getAllSurveys = async () => {
    try {
      const response = await axios.get(
        "https://zola.technology/api/company/surveys/get-all/",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setSurveys(response.data);
    } catch (error) {
      console.error("Error fetching surveys:", error);
      console.log("Failed to fetch surveys.");
    }
  };

  const handleToggle = async (active, surveyId) => {
    const activateEndpoint = `https://zola.technology/api/company/surveys/survey/activate/?survey_id=${surveyId}`;
    const deactivateEndpoint = `https://zola.technology/api/company/surveys/survey/deactivate/?survey_id=`;

    try {
      if (active) {
        // Find the currently active survey
        const currentlyActiveSurvey = surveys.find((survey) => survey.active);

        // Deactivate the currently active survey if it exists
        if (currentlyActiveSurvey) {
          await axios.patch(
            `${deactivateEndpoint}${currentlyActiveSurvey.id}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }

        // Activate the selected survey
        await axios.patch(
          activateEndpoint,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Update the state to reflect changes
        setSurveys((prevSurveys) =>
          prevSurveys.map((survey) => ({
            ...survey,
            active: survey.id === surveyId,
          }))
        );

        toast.success("Survey activated successfully.");
      } else {
        // Deactivate the selected survey
        await axios.patch(
          `${deactivateEndpoint}${surveyId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Update the state to reflect changes
        setSurveys((prevSurveys) =>
          prevSurveys.map((survey) =>
            survey.id === surveyId ? { ...survey, active: false } : survey
          )
        );

        toast.success("Survey deactivated successfully.");
      }
    } catch (error) {
      console.error("Error updating survey status:", error);
      toast.error("Failed to update survey status.");
    }
  };

  const handleCheckboxChange = (active, surveyId) => {
    setSelectedSurvey({ id: surveyId, active }); // Store the survey ID and active state
    setShowPopUp(true); // Show the popup
  };

  const handleConfirmToggle = async () => {
    const { id, active } = selectedSurvey; // Retrieve the stored survey ID and active state
    await handleToggle(active, id); // Call the existing function
    setShowPopUp(false); // Close the popup
  };

  const paginatedSurveys = surveys.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(surveys.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  return (
    <>
      <div className="p-6 bg-white">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold text-gray-800">Survey</h2>
          <button
            onClick={toggleModal}
            className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition"
          >
            + Create New
          </button>
        </div>

        <table className="min-w-full table-auto border-separate border-spacing-y-2">
          <thead>
            <tr>
              <th className="py-3 px-4 border-b text-left">No</th>
              <th className="py-3 px-4 border-b text-left">Survey Title</th>
              <th className="py-3 px-4 border-b text-left">Created Date</th>
              <th className="py-3 px-4 border-b text-left">Status</th>
              <th className="py-3 px-4 border-b text-center">Action</th>
              <th className="py-3 px-4 border-b text-center">Active</th>
            </tr>
          </thead>
          <tbody>
            {paginatedSurveys.map((survey, index) => (
              <tr key={survey.id} className="hover:bg-gray-50">
                <td className="py-3 px-4 border-b">
                  {String(index + 1).padStart(2, "0")}
                </td>
                <td className="py-3 px-4 border-b text-ellipsis whitespace-nowrap overflow-hidden">
                  {survey.title}
                </td>
                <td className="py-3 px-4 border-b">{survey.created_at}</td>
                <td className="py-3 px-4 border-b">
                  <span
                    className={`px-4 py-1 rounded-full text-sm font-medium ${
                      survey.active
                        ? "bg-green-100 text-green-600"
                        : "bg-yellow-100 text-yellow-600"
                    }`}
                  >
                    {survey.active ? "Active" : "Inactive"}
                  </span>
                </td>
                <td className="py-3 px-4 border-b text-center">
                  <button
                    onClick={() => navigate(`/dashboard/survey/${survey.id}`)}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    👁
                  </button>
                </td>
                <td className="py-3 px-4 border-b text-center">
                  <input
                    type="checkbox"
                    className="theme-checkbox"
                    checked={survey.active}
                    onChange={(e) =>
                      handleCheckboxChange(e.target.checked, survey.id)
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination */}
        <div className="flex justify-between items-center mt-4">
          {/* Left Section: Showing Entries */}
          <div className="text-sm text-gray-500">
            Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
            {Math.min(currentPage * itemsPerPage, surveys.length)} of{" "}
            {surveys.length} entries
          </div>

          {/* Center Section: Pagination */}
          <div className="flex items-center space-x-2">
            <button
              onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
              className="flex items-center justify-center w-8 h-8 bg-gray-100 text-gray-600 rounded-md hover:bg-gray-200 transition"
            >
              &lt;
            </button>

            {Array.from(
              { length: Math.min(3, totalPages) },
              (_, i) => currentPage - 1 + i
            )
              .filter((page) => page > 0 && page <= totalPages)
              .map((page) => (
                <button
                  key={page}
                  onClick={() => handlePageChange(page)}
                  className={`flex items-center justify-center w-8 h-8 ${
                    currentPage === page
                      ? "bg-blue-600 text-white"
                      : "bg-gray-100 text-gray-600"
                  } rounded-md hover:bg-gray-200 transition`}
                >
                  {page}
                </button>
              ))}

            <button
              onClick={() =>
                handlePageChange(Math.min(currentPage + 1, totalPages))
              }
              className="flex items-center justify-center w-8 h-8 bg-gray-100 text-gray-600 rounded-md hover:bg-gray-200 transition"
            >
              &gt;
            </button>
          </div>

          {/* Right Section: Entries Dropdown */}
          <div className="flex items-center">
            <span className="text-sm text-gray-500 mr-2">Show</span>
            <select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              className="border border-gray-300 text-sm text-gray-600 rounded-md focus:ring-blue-500 focus:border-blue-500 transition"
            >
              {[8, 16, 24, 32].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50 p-8 md:p-0">
          <div className="bg-white rounded-lg w-full max-w-3xl p-8 shadow-lg relative">
            {/* Modal Header */}
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold text-gray-900">
                {creationMode
                  ? creationMode === "ai"
                    ? "AI-Generated Questions"
                    : "Create Survey Manually"
                  : "How do you want to create your survey?"}
              </h2>
              <button
                onClick={toggleModal}
                className="text-gray-500 hover:text-gray-700 transition duration-150"
                aria-label="Close Modal"
              >
                ✕
              </button>
            </div>

            {/* Initial Mode Selection */}
            {!creationMode && (
              <div className="flex flex-col space-y-4">
                <button
                  onClick={() => handleModeSelection("ai")}
                  className="px-6 py-3 bg-green-600 text-white font-semibold rounded-lg shadow-md hover:bg-green-700 transition"
                >
                  Use AI to Generate Questions
                </button>
                <button
                  onClick={() => handleModeSelection("manual")}
                  className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition"
                >
                  Create Manually
                </button>
              </div>
            )}

            {/* AI-Generated Questions Section */}
            {creationMode === "ai" && (
              <div className="h-[75vh] flex flex-col bg-white dark:bg-gray-900 rounded-lg shadow-lg p-4 md:p-6 space-y-4">
                {/* Scrollable AI Generated Questions */}
                <div className="flex-grow overflow-y-scroll space-y-4 p-2">
                  {aiQuestions && aiQuestions.length > 0 ? (
                    <>
                      <h2 className="text-xl md:text-2xl font-bold text-gray-800 dark:text-gray-100 mb-4">
                        Customer Survey
                      </h2>
                      {aiQuestions.map((question, index) => (
                        <div
                          key={`ai-question-${index}`}
                          className="p-3 md:p-4 bg-gray-100 dark:bg-gray-800 rounded-lg border border-gray-300 dark:border-gray-700 shadow"
                        >
                          <h4 className="font-semibold text-md md:text-lg text-gray-800 dark:text-gray-200">
                            {index + 1}. {question.question}
                          </h4>
                          {question.type === "numeric_choice" ||
                          question.type === "multiple_choice" ? (
                            <ul className="list-disc pl-6 mt-2 md:mt-3 text-gray-700 dark:text-gray-300">
                              {question.choices.map((choice, idx) => (
                                <li
                                  key={`option-${index}-${idx}`}
                                  className="mb-1 md:mb-2"
                                >
                                  {choice}
                                </li>
                              ))}
                            </ul>
                          ) : question.type === "paragraph" ? (
                            <textarea
                              className="w-full mt-2 md:mt-3 p-2 md:p-3 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-200"
                              placeholder="Write your answer here..."
                              rows={3}
                            ></textarea>
                          ) : null}
                        </div>
                      ))}
                    </>
                  ) : (
                    <p className="text-gray-500 dark:text-gray-400">
                      No AI-generated questions yet. Enter a prompt and click
                      "Generate".
                    </p>
                  )}
                </div>

                {/* Input and Buttons at the Bottom */}
                <div className="pt-4 border-t border-gray-300 dark:border-gray-700 flex flex-col space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                      Use AI to effortlessly create your surveys. Enter a topic
                      or prompt below to get started:
                    </label>
                    <input
                      type="text"
                      value={aiPrompt}
                      onChange={(e) => setAiPrompt(e.target.value)}
                      className="mt-2 block w-full p-2 md:p-3 border border-gray-300 dark:border-gray-700 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-gray-200 sm:text-sm"
                      placeholder="Type your prompt here..."
                    />
                  </div>
                  <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
                    <button
                      onClick={handleGenerateAIQuestions}
                      className="w-full sm:w-auto px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition"
                    >
                      Generate
                    </button>
                    <button
                      onClick={() => setCreationMode(null)}
                      className="w-full sm:w-auto px-6 py-3 bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-gray-200 font-semibold rounded-lg shadow-md hover:bg-gray-400 dark:hover:bg-gray-600 transition"
                    >
                      Go Back
                    </button>
                    {aiQuestions && aiQuestions.length > 0 && (
                      <>
                        <button
                          onClick={handleSubmitAIQuestions}
                          className="w-full sm:w-auto px-6 py-3 bg-green-500 text-white font-semibold rounded-lg shadow-md hover:bg-green-600 transition"
                        >
                          Submit
                        </button>
                        <button
                          onClick={handleRegenerateQuestions}
                          className="w-full sm:w-auto px-6 py-3 bg-red-600 text-white font-semibold rounded-lg shadow-md hover:bg-red-700 transition"
                        >
                          Clear
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* Manual Question Creation Section */}
            {creationMode === "manual" && (
              <>
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50 p-8 md:p-0">
                  <div className="bg-white rounded-lg w-full max-w-3xl p-8 shadow-lg relative">
                    {/* Modal Header */}
                    <div className="flex justify-between items-center mb-6">
                      <h2 className="text-xl font-semibold text-gray-900">
                        Create Survey
                      </h2>
                      <button
                        onClick={toggleModal}
                        className="text-gray-500 hover:text-gray-700 transition duration-150"
                        aria-label="Close Modal"
                      >
                        ✕
                      </button>
                    </div>

                    <div className="space-y-6">
                      {/* Title Input Field */}
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Survey Title
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Survey Title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-500 placeholder-gray-400"
                        />
                      </div>

                      {/* Survey Type Dropdown */}
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Survey Type
                        </label>
                        <select
                          value={currentQuestion.type}
                          onChange={(e) => updateQuestionType(e.target.value)}
                          className="w-full px-4 py-2 bg-white border border-gray-300 rounded-lg focus:outline-none placeholder-gray-400"
                        >
                          <option value="">Select Question Type</option>
                          <option value="paragraph">Paragraph</option>
                          <option value="single">Single Choice Question</option>
                          <option value="multi_choice">
                            Multiple Choice Question
                          </option>
                        </select>
                      </div>

                      {/* Question Input Field */}
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Question
                        </label>
                        <input
                          type="text"
                          placeholder="Write Question here."
                          value={currentQuestion.text}
                          onChange={(e) =>
                            setCurrentQuestion((prev) => ({
                              ...prev,
                              text: e.target.value,
                            }))
                          }
                          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-500 placeholder-gray-400"
                        />
                      </div>

                      {/* Options Input Fields */}
                      {["single", "multi_choice"].includes(
                        currentQuestion.type
                      ) && (
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-2">
                            Options
                          </label>
                          <div className="grid grid-cols-2 gap-4">
                            {currentQuestion.options.map((option, index) => (
                              <div
                                key={index}
                                className="flex items-center gap-2"
                              >
                                <input
                                  type="text"
                                  placeholder={`Option No. ${index + 1}`}
                                  value={option}
                                  onChange={(e) =>
                                    setCurrentQuestion((prev) => {
                                      const updatedOptions = [...prev.options];
                                      updatedOptions[index] = e.target.value;
                                      return {
                                        ...prev,
                                        options: updatedOptions,
                                      };
                                    })
                                  }
                                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-500 placeholder-gray-400"
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {/* Add Question Button */}
                      <button
                        onClick={addQuestion}
                        className="px-6 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-green-700 transition"
                      >
                        Add Question
                      </button>
                    </div>

                    {/* Added Questions Section */}
                    <div className="mt-6">
                      <h4 className="text-lg font-semibold mb-4">
                        Added Questions
                      </h4>
                      <div className="space-y-4 max-h-60 overflow-y-auto border border-gray-200 rounded-lg p-4 bg-gray-50">
                        {questions.map((q, idx) => (
                          <div
                            key={idx}
                            className="p-4 border border-gray-300 rounded-lg bg-white shadow-sm"
                          >
                            <div className="flex justify-between items-center">
                              <h5 className="text-md font-medium text-gray-800">
                                {`Q${idx + 1}: ${q.text}`}
                              </h5>
                              <span className="px-3 py-1 text-sm font-medium text-white rounded-lg bg-blue-500">
                                {q.type === "single"
                                  ? "Single Choice"
                                  : q.type === "multi_choice"
                                  ? "Multiple Choice"
                                  : "Paragraph"}
                              </span>
                            </div>
                            {q.type !== "paragraph" && q.options.length > 0 && (
                              <div className="mt-2">
                                <h6 className="text-sm font-semibold text-gray-700">
                                  Options:
                                </h6>
                                <ul className="list-disc pl-5 text-gray-600 text-sm mt-1">
                                  {q.options.map((option, optionIdx) => (
                                    <li key={optionIdx}>
                                      {option || `Option ${optionIdx + 1}`}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* Modal Footer */}
                    <div className="flex justify-between items-center mt-6 space-x-4">
                      <button
                        onClick={toggleModal}
                        className="px-6 py-3 border border-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 transition duration-150"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={saveSurvey}
                        className="px-6 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700 transition duration-150"
                      >
                        Save Survey
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {showPopUp && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
          <div
            className="bg-white rounded-xl p-6 w-[90%] max-w-sm text-center space-y-4"
            onClick={(e) => e.stopPropagation()}
          >
            {!selectedSurvey.active ? (
              <>
                <FaExclamationTriangle className="text-[#EE6F7C] text-5xl mx-auto" />
                <h2 className="text-2xl text-[#454861] font-semibold">
                  Deactivate Survey?
                </h2>
                <p>This action will deactivate the selected survey.</p>
              </>
            ) : (
              <>
                <MdVerifiedUser className="text-[#6fee84] text-5xl mx-auto" />
                <h2 className="text-2xl text-[#454861] font-semibold">
                  Activate Survey?
                </h2>
                <p>
                  This action will activate the selected survey and deactivate
                  any active survey.
                </p>
              </>
            )}
            <div className="flex justify-center space-x-4 mt-4">
              <button
                onClick={handleConfirmToggle}
                className="bg-[#0C4EF8] text-[#FEFEFF] font-semibold px-8 py-1 rounded-[4px] hover:bg-blue-700 transition"
              >
                Yes
              </button>
              <button
                className="border border-[#F7BCC2] text-[#E6283B] font-semibold px-8 py-1 rounded-[4px] hover:bg-red-600 hover:text-white transition"
                onClick={() => setShowPopUp(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Survey;

import React, { createContext, useState, useEffect } from "react";
import { useTimeRange } from "../../context/TimeRangeContext";

// Create the context
export const BranchContext = createContext();

// Context provider component
export const BranchProvider = ({ children }) => {
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [employeeTaps, setEmployeeTaps] = useState([]);
  const [tapsLoading, setTapsLoading] = useState(false);
  const [spreadTaps, setSpreadTaps] = useState([]);
  const [spreadTapsLoading, setSpreadTapsLoading] = useState(false);
  const [googleReviewsData, setGoogleReviewsData] = useState({
    total_reviews: 0,
    average_rating: 0,
  });

  const [filteredRatings, setFilteredRatings] = useState([]);
  const [filteredRatingsLoading, setFilteredRatingsLoading] = useState(false);


  const [reviewsLoading, setReviewsLoading] = useState(false);
  const [branchData, setBranchData] = useState({
    total_positive_feedback_count: 0,
    total_negative_feedback_count: 0,
    total_no_rating_feedback_count: 0,
    total_feedback_count: 0,
    total_employees: 0,
    total_branches: 0,
    average_performance_score: 0,
  });
  const [branchDataLoading, setBranchDataLoading] = useState(false);
  const { timeRange } = useTimeRange();

  // Fetch branches
  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `https://zola.technology/api/hq-company/list/paginated-or-all-branch-companies/?page=1&page_size=10&filter=${timeRange}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          const formattedBranches = data.data.map((branch) => ({
            id: branch.id,
            name: branch.company_name,
            employees: branch.total_employees,
            status: branch.subscription.status ? "Active" : "Pending",
            taps: branch.total_taps,
            issues: 0, // Placeholder for issues
            performance: branch?.performance_score || 0,
            feedbacks: branch?.total_feedback,
          }));
          setBranches(formattedBranches);
        } else {
          console.error("Failed to fetch branches");
        }
      } catch (error) {
        console.error("Error fetching branches:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBranches();
  }, [timeRange]);

  // Fetch employee taps
  useEffect(() => {
    const fetchEmployeeTaps = async ( page = 1, page_size = 10) => {
      setTapsLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `https://zola.technology/api/hq-company/analytics/get-branch-employee-taps/?filter=${timeRange}&page=${page}&page_size=${page_size}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setEmployeeTaps(data);
        } else {
          console.error("Failed to fetch employee taps");
        }
      } catch (error) {
        console.error("Error fetching employee taps:", error);
      } finally {
        setTapsLoading(false);
      }
    };

    fetchEmployeeTaps();
  }, [timeRange]);

  // Fetch Google reviews data
  useEffect(() => {
    const fetchGoogleReviews = async () => {
      setReviewsLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          "https://zola.technology/api/hq-company/branches/google-reviews/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setGoogleReviewsData({
            total_reviews: data.total_reviews || 0,
            average_rating: data.average_rating || 0,
          });
        } else {
          console.error("Failed to fetch Google reviews");
        }
      } catch (error) {
        console.error("Error fetching Google reviews:", error);
      } finally {
        setReviewsLoading(false);
      }
    };

    fetchGoogleReviews();
  }, []);

  // Fetch branch data (new endpoint)
  useEffect(() => {
    const fetchBranchData = async () => {
      setBranchDataLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          "https://zola.technology/api/hq-company/branches/get-data/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setBranchData({
            total_positive_feedback_count: data.total_positive_feedback_count || 0,
            total_negative_feedback_count: data.total_negative_feedback_count || 0,
            total_no_rating_feedback_count: data.total_no_rating_feedback_count || 0,
            total_feedback_count: data.total_feedback_count || 0,
            total_employees: data.total_employees || 0,
            total_branches: data.total_branches || 0,
            average_performance_score: data.average_performance_score || 0,
          });
        } else {
          console.error("Failed to fetch branch data");
        }
      } catch (error) {
        console.error("Error fetching branch data:", error);
      } finally {
        setBranchDataLoading(false);
      }
    };

    fetchBranchData();
  }, []);


  // Fetch spread taps data
  useEffect(() => {
    const fetchSpreadTaps = async () => {
      setSpreadTapsLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `https://zola.technology/api/hq-company/dashboard/branches/get-spread-taps/?filter=${timeRange}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setSpreadTaps(data || []);
        } else {
          console.error("Failed to fetch spread taps data");
        }
      } catch (error) {
        console.error("Error fetching spread taps data:", error);
      } finally {
        setSpreadTapsLoading(false);
      }
    };

    fetchSpreadTaps();
  }, [timeRange]);


  // Fetch filtered ratings
  useEffect(() => {
    const fetchFilteredRatings = async () => {
      setFilteredRatingsLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `https://zola.technology/api/hq-company/dashboard/branches/filtered-rating/?filter=${timeRange}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setFilteredRatings(data || []);
        } else {
          console.error("Failed to fetch filtered ratings");
        }
      } catch (error) {
        console.error("Error fetching filtered ratings");
      } finally {
        setFilteredRatingsLoading(false);
      }
    };

    fetchFilteredRatings();
  }, [timeRange]);

  return (
    <BranchContext.Provider
      value={{
        branches,
        loading,
        employeeTaps,
        tapsLoading,
        googleReviewsData,
        reviewsLoading,
        branchData,
        branchDataLoading,
        spreadTaps,
        spreadTapsLoading,
        filteredRatings,
        filteredRatingsLoading,
      }}
    >
      {children}
    </BranchContext.Provider>
  );
};

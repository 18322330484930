import { useEffect, useState } from "react";
import DashboardHeader from "./DashboardHeader";
import ReactApexChart from "react-apexcharts";
import SkeletonCard from "./SkeletonCard";
import { TotalRevenue, PerformanceDashboard } from "./TeamPerformance/index";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Link, useOutletContext } from "react-router-dom";
import OverallPerformance from "./TeamPerformance/OverallPerformance";
import Reward from "./Reward";

function TeamPerformance() {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;
  const { isExpired } = useOutletContext();
  const [isMobileScreen, setIsMobileScreen] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  useEffect(() => {
    async function fetchData() {
      try {
        const token = localStorage.getItem("token");
        const month = "month";
        const response = await fetch(
          `https://zola.technology/api/company/employees/performance/filter/get-filtered-taps/?filter=${month}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setEmployees(data.employees);
      } catch (error) {
        console.error("Error fetching the employees data:", error);
      } finally {
        setLoading(false);
      }
    }

    const handleResize = () => {
      setIsMobileScreen(window.matchMedia("(max-width: 768px)").matches);
    };

    window.addEventListener("resize", handleResize);
    fetchData();
    handleResize(); // Call once to set initial state

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const calculateWeeklyTaps = (periods) => {
    const weekTaps = Array(4).fill(0); // Assuming 4 weeks in a month

    periods.forEach((period) => {
      period.dates.forEach((dateObj) => {
        const weekNumber = Math.ceil(new Date(dateObj.date).getDate() / 7); // Calculate week number
        weekTaps[weekNumber - 1] += dateObj.taps;
      });
    });

    return weekTaps;
  };

  const calculateMonthlyTaps = (periods) => {
    // Sum all taps from the periods
    return periods.reduce((total, period) => {
      return (
        total + period.dates.reduce((sum, dateObj) => sum + dateObj.taps, 0)
      );
    }, 0);
  };

  const generateChartData = (periods) => {
    const weekTaps = calculateWeeklyTaps(periods);
    const categories = ["Week 1", "Week 2", "Week 3", "Week 4"];
    const maxValue = Math.max(...weekTaps);
    const yAxisMax = maxValue; // Add 5 to the maximum value for better spacing

    return {
      options: {
        chart: {
          type: "area",
          height: isMobileScreen ? 150 : 200,
          toolbar: { show: false },
        },
        stroke: {
          curve: "straight",
          width: 1.5,
        },
        colors: ["#00BA34"],
        dataLabels: { enabled: false },
        xaxis: {
          categories,
          title: {
            text: "Taps per Week",
            style: {
              fontSize: "16px",
              fontWeight: "medium",
              fontStyle: "italic",
            },
          },
        },
        yaxis: {
          max: yAxisMax,
          labels: {
            formatter: (val) => (val % 1 === 0 ? val : ""),
          },
        },
        grid: {
          borderColor: "#F1F2F3",
          strokeDashArray: 1,
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 100],
          },
        },
      },
      series: [
        {
          name: "Taps per Week",
          data: weekTaps,
        },
      ],
    };
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const currentItems = employees.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(employees.length / itemsPerPage);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonth = monthNames[new Date().getMonth()];
  const userData = JSON.parse(localStorage.getItem("userData"));
  const companyType = userData.company_type;

  return (
    <div className="p-2 text-black dark:bg-gray-800 dark:text-white min-h-screen xs:w-full lg:w-full">
      {loading ? (
        <div className="flex justify-center items-center min-h-screen mt-[-150px]">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div className={`relative ${isExpired ? "blur-sm" : ""}`}>
            <DashboardHeader title="Team Performance" />
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 w-full">
              {currentItems.map((employee) => {
                const monthlyTaps = calculateMonthlyTaps(employee.periods);
                const chartData = generateChartData(employee.periods);

                return (
                  <div
                    key={employee.id}
                    className="p-4 h-80 dark:border-gray-600 bg-white dark:bg-gray-700 rounded-[12px] lg:w-full xs:w-full"
                  >
                    <div className="flex justify-between items-center">
                      <div className="w-3/6 flex items-center">
                        <p className="w-[40px] h-[40px] rounded-full flex items-center justify-center text-white font-bold text-[12px] p-4 bg-blue-600">
                          {`${employee.name.split(" ")[0][0].toUpperCase()}${
                            employee.name.split(" ")[1]
                              ? employee.name.split(" ")[1][0].toUpperCase()
                              : ""
                          }`}
                        </p>
                        <h3 className="text-[14px] font-semibold ml-2 text-[#6F707E]">
                          {employee.name.split(" ")[0]}{" "}
                          {employee.name.split(" ")[1]}
                        </h3>
                      </div>
                    </div>
                    <div className="flex space-x-2 items-center">
                      <p className="text-[24px] font-semibold text-[#454861]">
                        {monthlyTaps}{" "}
                        <span className="text-[#454861] text-[16px]">Taps</span>{" "}
                      </p>
                    </div>
                    <div className="lg:w-full xs:w-full mb-4">
                      <ReactApexChart
                        options={chartData.options}
                        series={chartData.series}
                        type="area"
                        height={chartData.options.chart.height}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex justify-center items-center space-x-4 my-4">
              <button
                aria-label="Previous Page"
                className="flex items-center px-4 py-2 rounded bg-white dark:bg-gray-700 text-black dark:text-white border border-gray-300 shadow-sm hover:bg-gray-200"
                disabled={currentPage === 1}
                onClick={handlePrevious}
              >
                <FaArrowLeft className="mr-2" /> Previous
              </button>
              <div className="flex space-x-2">
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    aria-label={`Go to page ${index + 1}`}
                    key={index}
                    className={`px-4 py-2 mx-1 rounded border border-gray-300 shadow-sm ${
                      index + 1 === currentPage
                        ? "bg-blue-500 text-white"
                        : "hidden md:flex bg-white dark:bg-gray-700 text-black dark:text-white hover:bg-gray-200"
                    }`}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
              <button
                aria-label="Next Page"
                className="flex items-center px-4 py-2 rounded bg-white dark:bg-gray-700 text-black dark:text-white border border-gray-300 shadow-sm hover:bg-gray-200"
                disabled={currentPage === totalPages}
                onClick={handleNext}
              >
                Next <FaArrowRight className="ml-2" />
              </button>
            </div>
            {companyType === "team" && <TotalRevenue />}
            {companyType === "team" && <Reward />}
          </div>
        </>
      )}
      {isExpired && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-80 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-8 text-center shadow-lg max-w-md">
            <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
              Subscription Expired
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-6">
              Your subscription has expired. Please renew to regain full access
              to the dashboard.
            </p>
            <Link to="/dashboard/setting/billing">
              <button
                aria-label="Go to Billing"
                className="bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-600 transition-colors"
              >
                Go to Billing
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default TeamPerformance;


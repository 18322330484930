import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Pagination, Box, Button } from "@mui/material";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import { IoMdArrowDropup } from "react-icons/io";

// Register Chart.js modules
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const PerformanceChart = () => {
  const [branches, setBranches] = useState([]);
  const [timePeriod, setTimePeriod] = useState("Monthly");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchBranches = async () => {
      const token = localStorage.getItem("token"); // Retrieve token from localStorage
      try {
        const response = await fetch(
          "https://zola.technology/api/hq-company/list/paginated-or-all-branch-companies/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch locations");
        }
        const data = await response.json();
        setBranches(data || []);
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    fetchBranches();
  }, []);

 console.log(branches, "branches")

  const totalPages = Math.ceil(branches.length / itemsPerPage);
  const currentData = branches.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const data = {
    labels: currentData.map((branch) => branch.preferred_business_name),
    datasets: [
      {
        label: "Total Feedback",
        data: currentData.map((branch) => branch.performance_score),
        borderColor: "#34D399",
        pointRadius: 5,
        pointBackgroundColor: "#34D399",
        tension: 0.3,
        fill: true,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }

          const gradient = ctx.createLinearGradient(
            0,
            chartArea.top,
            0,
            chartArea.bottom
          );
          gradient.addColorStop(0, "rgba(52, 211, 153, 0.5)");
          gradient.addColorStop(1, "rgba(52, 211, 153, 0.1)");
          return gradient;
        },
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: (context) => `${context.raw} Total Feedback`,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
  };

  const handleTimePeriodChange = (event) => {
    setTimePeriod(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <section className="p-6 rounded-lg shadow-lg bg-white">
      <div>
        <div className="flex flex-wrap items-center justify-between gap-4 mb-4">
          <div className="flex items-center gap-2">
            <h2 className="text-sm sm:text-xl md:text-2xl font-normal">
              Location Performance Overview
            </h2>

            <div className="flex items-center text-green-500 gap-1">
              <IoMdArrowDropup className="text-xl sm:text-2xl" />
              <span className="text-base sm:text-lg font-medium">25%</span>
            </div>
          </div>

          <div className="w-full sm:w-auto">
            <select
              value={timePeriod}
              onChange={handleTimePeriodChange}
              className="w-full sm:w-auto bg-[#f5f7fa] rounded-lg font-medium text-gray-800 h-10 px-3 flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-transparent"
            >
              <option value="Monthly">Monthly</option>
              <option value="Weekly">Weekly</option>
            </select>
          </div>
        </div>

        <div className="w-full h-[250px] md:h-[300px] overflow-hidden">
          <Line data={data} options={options} />
        </div>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={3}
        >
          <Button
            startIcon={<ArrowBack />}
            onClick={() => handlePageChange(null, currentPage - 1)}
            disabled={currentPage === 1}
            sx={{ textTransform: "none", color: "#1976d2" }}
          >
            Previous
          </Button>

          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            variant="outlined"
            shape="rounded"
          />

          <Button
            endIcon={<ArrowForward />}
            onClick={() => handlePageChange(null, currentPage + 1)}
            disabled={currentPage === totalPages}
            sx={{ textTransform: "none", color: "#1976d2" }}
          >
            Next
          </Button>
        </Box>
      </div>
    </section>
  );
};

export default PerformanceChart;

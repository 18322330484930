import { motion } from 'framer-motion';
import { useState } from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';

export default function AccountTypePage() {
  const [hovered, setHovered] = useState('');

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      {/* Container */}
      <div className="max-w-4xl w-full p-8  rounded-lg ">
        <motion.h1
          className="text-3xl font-bold text-center text-gray-800 mb-8"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          Create Your Account
        </motion.h1>

        <motion.p
          className="text-center text-lg text-gray-600 mb-10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Are you setting up an account for a single-location business or a multi-location business?
        </motion.p>

        {/* Options */}
        <div className="flex justify-center flex-wrap gap-8">
          {/* Single Location Option */}
          <motion.div
            className={`p-6 w-80 bg-blue-50 rounded-lg shadow-md cursor-pointer transform transition-all duration-300 ${
              hovered === 'single' ? 'scale-105 shadow-xl' : ''
            }`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onMouseEnter={() => setHovered('single')}
            onMouseLeave={() => setHovered('')}
            onClick={() => console.log('Single Location selected')}
          >
            <Link to="/signup">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Single Location</h2>
            <p className="text-gray-600 mb-4">
              Ideal for businesses operating from a single physical location.
            </p>
            <div className="flex items-center justify-end text-blue-600">
              <span className="mr-2">Select</span>
              <FiArrowRight />
            </div>
            
            </Link>
          </motion.div>

          {/* Multi-Location Option */}
          <motion.div
            className={`p-6 w-80 bg-blue-50 rounded-lg shadow-md cursor-pointer transform transition-all duration-300 ${
              hovered === 'multi' ? 'scale-105 shadow-xl' : ''
            }`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onMouseEnter={() => setHovered('multi')}
            onMouseLeave={() => setHovered('')}
            onClick={() => console.log('Multi Location selected')}
          >
            <Link to='/book'>
              <h2 className="text-xl font-semibold text-gray-800 mb-4">Multi Location</h2>
              <p className="text-gray-600 mb-4">
                Best for businesses with multiple locations or franchises.
              </p>
              <div className="flex items-center justify-end text-blue-600">
                <span className="mr-2">Select</span>
                <FiArrowRight />
              </div>

            </Link>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

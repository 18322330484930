import { motion } from "framer-motion";
import { FcGoogle } from "react-icons/fc";
import { FaStar } from "react-icons/fa6";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import carousel1 from "./carousel1.webp";
import carousel2 from "./carousel2.webp";
import carousel3 from "./carousel3.webp";
import carousel4 from "./carousel4.webp";
import { PopupButton } from "react-calendly";

const images = [carousel1, carousel2, carousel3, carousel4];

const AutoCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  return (
    <div className="w-full h-auto lg:h-80">
      <Slider {...settings}>
        {images.map((src, index) => (
          <div key={index} className="w-full h-full">
            <motion.img
              src={src}
              alt={`Slide ${index + 1}`}
              className="w-full h-full object-cover"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              loading="lazy"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

function Home() {
  const words = ["Reviews", "Feedback", "Revenue", "Customers"];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  const navigate = useNavigate();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    navigate(`/signup?email=${encodeURIComponent(email)}`);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsVisible(false);
      setTimeout(() => {
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        setIsVisible(true);
      }, 300);
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <section className="flex flex-col lg:flex-row items-center justify-between h-auto lg:h-[80vh] bg-[#eff2fb] pt-24 md:pt-32 lg:pt-8 max-w-[1340px] lg:m-auto p-8 md:p-8 lg:p-10 rounded-3xl font-figtree">
      <div className="lg:w-1/2 w-full mb-8 lg:mb-0 text-left">
        <h2 className="text-4xl lg:text-6xl mb-4">
          Drive more{" "}
          <motion.span
            className="text-indigo-600 w-full lg:w-auto font-bold block md:inline-block"
            animate={{ y: isVisible ? 0 : 20, opacity: isVisible ? 1 : 0 }}
            transition={{ duration: 0.3 }}
            initial={{ y: 20, opacity: 0 }}
            key={currentWordIndex}
          >
            {words[currentWordIndex]}
          </motion.span>{" "}
          at your business
        </h2>
        <h3 className="text-lg md:text-xl lg:text-2xl font-normal mb-4 px-2 lg:px-0">
          Boost revenue, generate reviews, and track performance with Zola,
          where client interactions are quickly converted into valuable feedback
          in less than a minute.
        </h3>
        <form
          onSubmit={handleFormSubmit}
          className="flex flex-col md:flex-row md:items-center lg:space-y-0 lg:space-x-4 justify-center md:justify-start gap-4"
        >
          <input
            type="email"
            name="email"
            placeholder="name@email.com"
            className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600 w-full md:w-3/5"
          />
          <button
            aria-label="Get Free 7-day Trial Demo"
            type="submit"
            className="bg-indigo-600 text-white px-6 py-2 rounded-lg hover:bg-indigo-500 w-full md:w-2/5"
          >
            Get Started Today
          </button>
        </form>

        {/* Google Review Section */}
        <div className="mt-6 flex items-center gap-2 text-2xl">
          <FcGoogle className="text-3xl" />
          <div className="flex gap-1">
            <FaStar className="text-yellow-500" />
            <FaStar className="text-yellow-500" />
            <FaStar className="text-yellow-500" />
            <FaStar className="text-yellow-500" />
            <FaStar className="text-yellow-500" />
          </div>
        </div>

        {/* Schedule Demo Button */}
        <div className="mt-8">
          <PopupButton
            url="https://calendly.com/ericktshimpe959"
            rootElement={document.getElementById("root")}
            text="Schedule For A Demo"
            className="bg-teal-700 text-white px-6 py-3 rounded-lg hover:bg-teal-600 shadow-lg"
          />
        </div>
      </div>
      <div className="w-full mt-0 lg:w-1/2 p-4">
        <AutoCarousel />
      </div>
    </section>
  );
}

export default Home;
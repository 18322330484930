import { useState, useEffect } from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import useLocation
import logo from "./zola.png";
import "./Navbar.css";

export default function Navbar() {
  const [navbar, setNavbar] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation(); // Get the current location
  const navigate = useNavigate();

  const handleLinkClick = () => {
    setNavbar(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Check if the current path is '/login' or '/signup'
  const isLoginOrSignupPage =
    location.pathname === "/login" ||
    location.pathname === "/signup" ||
    location.pathname === "/account-type" ||
    location.pathname === "/forgot-password" ||
    location.pathname === "/blog";

  return (
    <nav
      className={`w-full top-0 fixed z-50 bg-white transition-all duration-300 ${
        scrolled ? "shadow-lg" : ""
      }`}
    >
      <div className="flex justify-between items-center px-2 md:px-8 py-3 mx-0 md:mx-8 md:py-5">
        <Link to="/" className="flex-shrink-0">
          <img
            src={logo}
            className="w-[160px] h-auto lg:w-[200px] lg:h-[44px] object-cover ml-[20px] my-2"
            alt="Zola Logo"
          />
        </Link>

        <div className="md:hidden">
          <button
            className="p-2 text-black rounded-md outline-none focus-none"
            onClick={() => setNavbar(!navbar)}
            aria-label="Toggle navigation menu"
          >
            {navbar ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </button>
        </div>

        <div
          className={`${
            navbar ? "block" : "hidden"
          } w-full md:w-auto bg-white md:bg-transparent absolute md:relative left-0 md:left-auto top-full md:top-auto right-0 md:right-auto px-4 py-6 md:py-0 md:flex items-center space-y-4 md:space-y-0 md:space-x-6 md:transition-none transition-all duration-300 ease-in-out ${
            scrolled ? "shadow-md md:shadow-none" : ""
          }`}
        >
          <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
            {!isLoginOrSignupPage && (
              <li className="text-black">
                <ScrollLink
                  to="how-it-works"
                  smooth={true}
                  duration={500}
                  onClick={handleLinkClick}
                  className="cursor-pointer"
                >
                  How it Works
                </ScrollLink>
              </li>
            )}
            {/* <li className="text-black">
              <Link to="/blog" onClick={handleLinkClick}>
                Blog
              </Link>
            </li> */}
            <li className="text-black">
              <Link to="/login" onClick={handleLinkClick}>
                Log in
              </Link>
            </li>
            <li className="text-black hover:text-blue-600">
              <button
                className="w-[150px] py-[8px] text-white flex-shrink-0 bg-indigo-600 hover:bg-indigo-500 rounded-lg"
                aria-label="Get started"
                onClick={() => navigate("/account-type")}
              >
                Get Started
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
import React, { useContext, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "tailwindcss/tailwind.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import { MdOutlineFrontHand } from "react-icons/md";
import IncentivesSection from "./reviewsincentives/IncentiveSection";
import ReviewsSection from "./reviewsincentives/ReviewsSection";
import { BranchContext } from "./../../context/BranchContext"; // Adjust the path as necessary
import { useTimeRange } from "../../../context/TimeRangeContext";


// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const FeedbackAnalytics = () => {
  const { filteredRatings, filteredRatingsLoading } = useContext(BranchContext);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const { timeRange } = useTimeRange();

  const transformPeriod = (period) => {
    if (timeRange === "week") {
      // Extract the day for week timeRange
      const day = period.split(" ")[0]; // e.g., "Monday"
      return day;
    } else if (timeRange === "year") {
      // Extract the month for year timeRange
      const month = period.split(" ")[0]; // e.g., "January"
      return month;
    }
    return period; // Default to original period
  };

  useEffect(() => {
    if (!filteredRatingsLoading && filteredRatings.length > 0) {
      // Transform periods based on the filter
      const labels = filteredRatings.map((item) => transformPeriod(item.period));
      const positiveFeedback = filteredRatings.map(
        (item) => item.positive_feedback_count
      );
      const neutralFeedback = filteredRatings.map(
        (item) => item.neutral_feedback_count
      );
      const negativeFeedback = filteredRatings.map(
        (item) => item.negative_feedback_count
      );

      // Set chart data
      setChartData({
        labels,
        datasets: [
          {
            label: "Positive",
            data: positiveFeedback,
            backgroundColor: "#00c853", // Green
          },
          {
            label: "Neutral",
            data: neutralFeedback,
            backgroundColor: "#ffc400", // Yellow
          },
          {
            label: "Negative",
            data: negativeFeedback,
            backgroundColor: "#d50000", // Red
          },
        ],
      });
    }
  }, [filteredRatings, filteredRatingsLoading, timeRange]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Feedback Count by ${
          timeRange === "year" ? "Month" : "Day"
        }`, // Dynamically update title
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: true,
        },
        
      },
    },
  };

  return (
    <div className="flex w-full flex-col lg:flex-row gap-6 mb-2">
      {/* Feedback Chart Section */}
      <div className="w-full lg:w-3/5 flex-1 bg-white rounded-lg p-6 shadow-md">
        <div>
          <h3 className="mb-4 font-semibold text-gray-700 text-xl">Feedback</h3>
          <div className="flex flex-wrap justify-start gap-6 md:gap-8 mb-6">
            <div className="flex items-center gap-4 w-full sm:w-auto">
              <span className="text-green-600 text-3xl sm:text-4xl bg-gray-100 text-center rounded-full p-4">
                <FaRegThumbsUp />
              </span>
              <div>
                <p className="text-gray-600 text-sm">Positive</p>
                <h3 className="text-xl sm:text-2xl font-bold">
                  {filteredRatingsLoading
                    ? "..."
                    : filteredRatings.reduce(
                        (total, item) => total + item.positive_feedback_count,
                        0
                      )}
                </h3>
              </div>
            </div>

            <div className="flex items-center gap-4 w-full sm:w-auto">
              <span className="text-yellow-500 text-3xl sm:text-4xl bg-gray-100 text-center rounded-full p-4">
                <MdOutlineFrontHand />
              </span>
              <div>
                <p className="text-gray-600 text-sm">Neutral</p>
                <h3 className="text-xl sm:text-2xl font-bold">
                  {filteredRatingsLoading
                    ? "..."
                    : filteredRatings.reduce(
                        (total, item) => total + item.neutral_feedback_count,
                        0
                      )}
                </h3>
              </div>
            </div>

            <div className="flex items-center gap-4 w-full sm:w-auto">
              <span className="text-red-600 text-3xl sm:text-4xl bg-gray-100 text-center rounded-full p-4">
                <FaRegThumbsDown />
              </span>
              <div>
                <p className="text-gray-600 text-sm">Negative</p>
                <h3 className="text-xl sm:text-2xl font-bold">
                  {filteredRatingsLoading
                    ? "..."
                    : filteredRatings.reduce(
                        (total, item) => total + item.negative_feedback_count,
                        0
                      )}
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div className="h-[250px] sm:h-[300px] w-full">
          {filteredRatingsLoading ? (
            <p>Loading...</p>
          ) : (
            <Bar data={chartData} options={chartOptions} />
          )}
        </div>
      </div>

      {/* Reviews and Incentive Section */}
      <div className="grid grid-rows-[1fr_1fr] gap-4 w-full lg:w-2/5">
        <ReviewsSection />
        <IncentivesSection />
      </div>
    </div>
  );
};

export default FeedbackAnalytics;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import {
  IoIosArrowBack,
  IoMdList,
  IoIosCheckmarkCircle,
  IoIosRadioButtonOff,
} from "react-icons/io";
import { FaExclamationTriangle } from "react-icons/fa";
import { MdVerifiedUser } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SurveyDetail() {
  const { surveyId } = useParams();
  const [survey, setSurvey] = useState(null);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [showPopUp, setShowPopUp] = useState(false);
  const [isActive, setIsActive] = useState(true); // Keeps track of the activation status

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        const response = await axios.get(
          `https://zola.technology/api/company/surveys/survey/${surveyId}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSurvey(response.data);
        setIsActive(response.data.active); // Set initial activation status
      } catch (error) {
        console.error("Error fetching survey:", error);
        toast.error("Failed to fetch survey details.");
      }
    };

    fetchSurvey();
  }, [surveyId, token]);

  const updateSurveyStatus = async (active) => {
    const endpoint = active
      ? `https://zola.technology/api/company/surveys/survey/activate/?survey_id=${surveyId}`
      : `https://zola.technology/api/company/surveys/survey/deactivate/?survey_id=${surveyId}`;

    try {
      await axios.patch(
        endpoint,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsActive(active);
      setShowPopUp(false);
      toast.success(`Survey successfully ${active ? "activated" : "deactivated"}.`);
    } catch (error) {
      console.error("Error updating survey status:", error);
      toast.error("Failed to update survey status.");
    }
  };

  if (!survey) return null;

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="flex items-center justify-between">
        <button
          onClick={() => navigate(-1)}
          className="mb-4 flex items-center text-blue-600 hover:underline focus:outline-none"
        >
          <IoIosArrowBack className="mr-1" /> Back
        </button>

        {isActive ? (
          <button
            onClick={() => setShowPopUp(true)}
            className="mb-4 flex items-center text-red-600 px-2 rounded-lg hover:text-white hover:bg-red-400"
          >
            Deactivate Survey
          </button>
        ) : (
          <button
            onClick={() => setShowPopUp(true)}
            className="mb-4 flex items-center text-green-600 px-2 rounded-lg hover:text-white hover:bg-green-400"
          >
            Activate Survey
          </button>
        )}
      </div>

      {/* Survey Title and Info */}
      <h2 className="text-3xl font-semibold text-gray-800 mb-2">
        {survey.title}
      </h2>
      <p className="text-gray-500 mb-6">
        Created at: {new Date(survey.created_at).toLocaleString()}
      </p>

      {/* Questions List */}
      <div className="space-y-6">
        {survey.questions.map((question, index) => (
          <div
            key={question.id}
            className="p-4 bg-gray-100 rounded-lg shadow-md"
          >
            <div className="flex items-center mb-2">
              <h3 className="text-lg font-semibold text-gray-700">
                Question {index + 1}: {question.question_text}
              </h3>
              <span className="ml-auto px-2 py-1 rounded-full text-sm font-medium text-white bg-blue-600">
                {question.question_type === "paragraph"
                  ? "Paragraph"
                  : question.question_type === "multi_choice"
                  ? "Multiple Choice"
                  : "Single Choice"}
              </span>
            </div>

            {/* Question Type Indicator and Choices */}
            <div className="flex items-center mt-2">
              {question.question_type === "paragraph" ? (
                <>
                  <IoMdList className="text-gray-600 mr-2 text-xl" />
                  <p className="text-gray-600">Respond in paragraph format.</p>
                </>
              ) : (
                <ul className="mt-2 ml-6 space-y-2">
                  {Object.entries(question.choices).map(([key, choice]) => (
                    <li key={key} className="flex items-center text-gray-600">
                      {question.question_type === "single_choice" ? (
                        <IoIosRadioButtonOff className="mr-2 text-blue-500" />
                      ) : (
                        <IoIosCheckmarkCircle className="mr-2 text-green-500" />
                      )}
                      <span>{choice}</span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        ))}
      </div>

      {showPopUp && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
          <div
            className="bg-white rounded-xl p-6 w-[90%] max-w-sm text-center space-y-4"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
          >
            {isActive ? (
              <>
                <FaExclamationTriangle className="text-[#EE6F7C] text-5xl mx-auto" />
                <h2 className="text-2xl text-[#454861] font-semibold">
                  Deactivate Survey?
                </h2>
                <p>This action will deactivate the selected survey.</p>
              </>
            ) : (
              <>
                <MdVerifiedUser className="text-[#6fee84] text-5xl mx-auto" />
                <h2 className="text-2xl text-[#454861] font-semibold">
                  Activate Survey?
                </h2>
                <p>
                  This action will activate the selected survey and deactivate
                  any active survey.
                </p>
              </>
            )}
            <div className="flex justify-center space-x-4 mt-4">
              <button
                onClick={() => updateSurveyStatus(!isActive)}
                className="bg-[#0C4EF8] text-[#FEFEFF] font-semibold px-8 py-1 rounded-[4px] hover:bg-blue-700 transition"
              >
                Yes
              </button>
              <button
                className="border border-[#F7BCC2] text-[#E6283B] font-semibold px-8 py-1 rounded-[4px] hover:bg-red-600 hover:text-white transition"
                onClick={() => setShowPopUp(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SurveyDetail;

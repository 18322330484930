const ResponsesList = () => {
  const responses = Array(20).fill({
    score: 10,
    comment:
      "I recently dined at [Restaurant Name], and it was incredible! The ambiance was delightful, the service was prompt, and the food was simply divine.",
    timeAgo: "2 hours ago",
  });

  return (
    <div className="p-6 bg-white rounded-md shadow h-[48rem] overflow-y-auto">
      <h3 className="text-lg font-semibold mb-4">Responses: NPS</h3>
      {responses.map((response, idx) => (
        <div
          key={idx}
          className="flex items-start gap-4 py-4 border-b last:border-none"
        >
          <div
            className={`p-2 text-white rounded-full ${
              response.score > 7 ? "bg-green-500" : "bg-red-500"
            }`}
          >
            {response.score}
          </div>
          <div>
            <p>{response.comment}</p>
            <span className="text-xs text-gray-500">{response.timeAgo}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ResponsesList;

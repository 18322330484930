import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import QRCode from "qrcode";
import { FaTimes } from "react-icons/fa";

function CompanyDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const company = location.state;

  const [employees, setEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [qrCodeData, setQrCodeData] = useState("");
  const [showQrCodeIndex, setShowQrCodeIndex] = useState(null);

  const PAGE_SIZE = 10;
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (company?.username) {
      fetchEmployees(company.username, currentPage);
    }
  }, [company?.username, currentPage]);

  const fetchEmployees = async (username, page) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `https://zola.technology/api/company/${username}/employees/get/`,
        {
          params: { page, page_size: PAGE_SIZE },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { data, total_pages, current_page } = response.data;
      setEmployees(data);
      setTotalPages(total_pages);
      setCurrentPage(current_page);
    } catch (err) {
      setError("Failed to fetch employees.");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleShowQrCode = async (index) => {
    if (showQrCodeIndex === index) {
      setShowQrCodeIndex(null);
      setQrCodeData("");
      return;
    }
  
    const employeeUrl = employees[index]?.employee_url;
    if (employeeUrl) {
      try {
        const qrCodeUrl = `${employeeUrl}/q`;
  
        const qrCode = await QRCode.toDataURL(qrCodeUrl);
        setQrCodeData(qrCode);
        setShowQrCodeIndex(index);
      } catch (err) {
        console.error("Error generating QR code:", err);
      }
    }
  };
  

  const handleDownloadQrCode = () => {
    const link = document.createElement("a");
    link.href = qrCodeData;
    link.download = `qr-code-${employees[showQrCodeIndex]?.name || "employee"}.png`;
    link.click();
  };

  const handlePrintQrCode = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`<img src="${qrCodeData}" alt="QR Code" style="width:100%;"/>`);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };

  if (!company) {
    return (
      <div className="p-6">
        <p className="text-red-500">No company data found.</p>
        <button
          onClick={() => navigate("/companies")}
          className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4"
          aria-label="Go back to the companies page"
        >
          Back to Companies
        </button>
      </div>
    );
  }

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="bg-white shadow-md rounded-lg p-6">
        {/* Header Section */}
        <div className="text-center mb-6">
          <h1 className="text-3xl font-bold mb-2">{company.company_name}</h1>
          <p className="text-gray-500 italic">{company.industry_type || "Industry not specified"}</p>
        </div>

        {/* Employees Section */}
        <div className="mt-10">
          <h2 className="text-2xl font-bold mb-4">Employees</h2>
          {error && <p className="text-red-500">{error}</p>}
          {loading ? (
            <p>Loading employees...</p>
          ) : employees.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="min-w-full table-auto border-collapse border border-gray-200">
                <thead className="bg-gray-100 text-gray-600 text-[16px]">
                  <tr>
                    <th className="px-4 py-2 text-left font-semibold border-b border-gray-300">S/N</th>
                    <th className="px-4 py-2 text-left font-semibold border-b border-gray-300">Name</th>
                    <th className="px-4 py-2 text-left font-semibold border-b border-gray-300">NFC URL</th>
                    <th className="px-4 py-2 text-left font-semibold border-b border-gray-300">QR Code</th>
                  </tr>
                </thead>
                <tbody>
                  {employees.map((employee, index) => (
                    <tr key={employee.id} className="bg-white hover:bg-gray-50 transition-colors duration-200 text-gray-800">
                      <td data-cell="S/N" className="px-4 py-3 text-sm border-b border-gray-200">{index + 1 + (currentPage - 1) * PAGE_SIZE}</td>
                      <td data-cell="Name" className="px-4 py-3 text-sm border-b border-gray-200">{employee.name}</td>
                      <td data-cell="NFC URL" className="px-4 py-3 text-sm border-b border-gray-200">{employee.employee_url}</td>
                      <td data-cell="QR Code" className="px-4 py-3 text-sm border-b border-gray-200">
                        <button
                          onClick={() => handleShowQrCode(index)}
                          className="bg-blue-500 text-white px-4 py-2 rounded-md shadow hover:bg-blue-600"
                          aria-label={
                            showQrCodeIndex === index
                              ? "Hide QR code for item " + (index + 1)
                              : "Show QR code for item " + (index + 1)
                          }
                          aria-expanded={showQrCodeIndex === index}
                        >
                          {showQrCodeIndex === index ? "Hide QR Code" : "Show QR Code"}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Pagination */}
              <div className="flex justify-center items-center mt-4">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="px-4 py-2 mr-2 bg-gray-200 rounded-md hover:bg-gray-300 disabled:opacity-50"
                  aria-label={
                    currentPage === 1
                      ? "Previous page (disabled, on first page)"
                      : "Go to previous page"
                  }
                >
                  Previous
                </button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="px-4 py-2 ml-2 bg-gray-200 rounded-md hover:bg-gray-300 disabled:opacity-50"
                  aria-label={
                    currentPage === totalPages
                      ? "Next page (disabled, on last page)"
                      : "Go to next page"
                  }
                >
                  Next
                </button>
              </div>
            </div>
          ) : (
            <p className="text-gray-500">No employees found.</p>
          )}
        </div>

        {/* QR Code Modal */}
        {showQrCodeIndex !== null && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            aria-modal="true"
            role="dialog"
            onClick={() => setShowQrCodeIndex(null)}
          >
            <div
              className="bg-white rounded-xl p-6 max-w-[500px] w-full text-center relative"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                onClick={() => setShowQrCodeIndex(null)}
                className="absolute top-2 right-2 bg-gray-200 text-gray-700 hover:bg-gray-300 rounded-full p-2"
                aria-label="Close QR code"
              >
                <FaTimes className="h-5 w-5" />
              </button>
              <h3 className="text-xl font-semibold text-[#454861] mb-4">
                QR Code for {employees[showQrCodeIndex]?.name}
              </h3>
              <div className="flex justify-center mb-4">
                <img
                  src={qrCodeData}
                  alt="Employee QR Code"
                  className="w-[250px] h-[250px] object-contain border border-gray-300 rounded-md shadow-lg"
                />
              </div>
              <div className="flex justify-center space-x-4">
                <button
                  onClick={handleDownloadQrCode}
                  className="bg-green-500 text-white px-4 py-2 rounded-md shadow hover:bg-green-600"
                  aria-label="Download the QR Code for offline use"
                >
                  Download QR Code
                </button>
                {/* <button
                  onClick={handlePrintQrCode}
                  className="bg-blue-500 text-white px-4 py-2    rounded-md shadow hover:bg-blue-600"
                >
                  Print QR Code
                </button> */}
              </div>
            </div>
          </div>
        )}

        {/* Actions */}
        <div className="mt-6 text-center">
          <button
            onClick={() => navigate("/dashboard/companies")}
            className="bg-blue-500 text-white px-4 py-2 rounded-md shadow hover:bg-blue-600"
            aria-label="Navigate back to the companies dashboard"
          >
            Back to Companies
          </button>
        </div>
      </div>
    </div>
  );
}

export default CompanyDetail;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function MobileSurvey() {
  const [survey, setSurvey] = useState(null);
  const [error, setError] = useState(null);
  const [responses, setResponses] = useState({});
  const { company_username } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        // const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://zola.technology/api/company/${company_username}/surveys/survey/get-active/`,
          {
            headers: {
              // Authorization: `Bearer ${token}`,
            },
          }
        );
        setSurvey(response.data);
      } catch (error) {
        console.error("Error fetching survey:", error);
        setError("Failed to load survey. Please try again.");
      }
    };

    fetchSurvey();
  }, [company_username]);

  const handleResponseChange = (questionId, value) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionId]: value,
    }));
  };

  const handleCheckboxChange = (questionId, choice) => {
    setResponses((prevResponses) => {
      const currentSelections = prevResponses[questionId] || [];
      const updatedSelections = currentSelections.includes(choice)
        ? currentSelections.filter((item) => item !== choice)
        : [...currentSelections, choice];
      return {
        ...prevResponses,
        [questionId]: updatedSelections,
      };
    });
  };

  const handleSubmit = async () => {
    const formattedAnswers = survey.questions.map((question) => {
      const response = responses[question.id];
      let formattedResponse;

      if (question.question_type === "multi_choice") {
        formattedResponse = response || [];
      } else if (question.question_type === "single_choice") {
        formattedResponse = response || "";
      } else if (question.question_type === "numeric_choice") {
        formattedResponse = response || 0;
      } else {
        formattedResponse = response || "";
      }

      return {
        question_id: question.id,
        response: formattedResponse,
      };
    });

    const payload = {
      survey_id: survey.id,
      answers: formattedAnswers,
    };

    console.log("Survey submission data:", JSON.stringify(payload, null, 2));

    // const confirmSubmission = window.confirm(
    //   "Are you sure you want to submit your survey responses?"
    // );

    // if (!confirmSubmission) {
    //   console.log("Survey submission canceled by the user.");
    //   return;
    // }

    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `https://zola.technology/api/company/${company_username}/surveys/survey/get-responses/`,
        payload,
        {
          headers: {
            // Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      navigate("/survey/success")
    } catch (error) {
      console.error("Error submitting survey:", error);
      toast.error("Failed to submit the survey. Please try again.");
    }
  };

  if (error)
    return (
      <div className="w-full h-screen flex flex-col items-center justify-center bg-gray-50 dark:bg-gray-900 space-y-6">
        <p className="text-xl font-semibold text-gray-700 dark:text-gray-200">
          {error === "Failed to load survey. Please try again."
            ? "Oops! There are no active surveys available at the moment."
            : error}
        </p>
        <button
          onClick={() => window.history.back()} // Go back to the previous page
          className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition"
        >
          Go Back
        </button>
      </div>
    );
  
  if (!survey)
    return (
      <div className="w-full h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
        <div className="animate-pulse text-gray-500 dark:text-gray-400">
          Loading survey...
        </div>
      </div>
    );

  return (
    <div className="p-6 bg-gray-100 dark:bg-gray-900 min-h-screen">
      <ToastContainer />
      {/* Survey Header */}
      <div className="max-w-4xl mx-auto flex items-center flex-col  bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100">
          {survey.title}
        </h1>
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          Created on: {new Date(survey.created_at).toLocaleDateString()}
        </p>
      </div>

      {/* Survey Questions */}
      <div className="mt-6 max-w-4xl mx-auto space-y-6">
        {survey.questions.map((question, index) => (
          <div
            key={question.id}
            className="bg-white dark:bg-gray-800 p-4   rounded-lg  transition"
          >
            <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
              {index + 1}. {question.question_text}
            </h2>
            <div className="mt-4 space-y-3">
              {/* Paragraph */}
              {question.question_type === "paragraph" && (
                <textarea
                  className="w-full px-3 py-2 border-2 border-transparent rounded-lg bg-gray-100 outline-none transition-all duration-500"
                  rows="4"
                  placeholder="Type your answer..."
                  value={responses[question.id] || ""}
                  onChange={(e) =>
                    handleResponseChange(question.id, e.target.value)
                  }
                />
              )}

              {/* Single Choice */}
              {question.question_type === "single_choice" &&
                Object.entries(question.choices).map(([key, value]) => (
                  <label
                    key={key}
                    className="block bg-gray-100 dark:bg-gray-700 rounded-lg p-3 cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-600"
                  >
                    <input
                      type="radio"
                      name={`question-${question.id}`}
                      value={value}
                      checked={responses[question.id] === value}
                      onChange={() => handleResponseChange(question.id, value)}
                      className="form-radio text-blue-600 dark:text-blue-400 mr-3"
                    />
                    {value}
                  </label>
                ))}
              {question.question_type === "numeric_choice" &&
                Object.entries(question.choices).map(([key, value]) => (
                  <label
                    key={key}
                    className="block bg-gray-100 dark:bg-gray-700 rounded-lg p-3 cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-600"
                  >
                    <input
                      type="radio"
                      name={`question-${question.id}`}
                      value={value}
                      checked={responses[question.id] === value}
                      onChange={() => handleResponseChange(question.id, value)}
                      className="form-radio text-blue-600 dark:text-blue-400 mr-3"
                    />
                    {value}
                  </label>
                ))}

              {/* Multi Choice */}
              {question.question_type === "multi_choice" &&
                Object.entries(question.choices).map(([key, value]) => (
                  <label
                    key={key}
                    className="block bg-gray-100 dark:bg-gray-700 rounded-lg p-3 cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-600"
                  >
                    <input
                      type="checkbox"
                      value={value}
                      checked={responses[question.id]?.includes(value) || false}
                      onChange={() => handleCheckboxChange(question.id, value)}
                      className="form-checkbox text-blue-600 dark:text-blue-400 mr-3"
                    />
                    {value}
                  </label>
                ))}
            </div>
          </div>
        ))}
      </div>

      {/* Submit Button */}
      <div className="mt-10 flex justify-center">
        <button
          onClick={handleSubmit}
          className="px-6 py-3 w-full bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white text-lg font-semibold rounded-lg shadow-lg transform transition-transform"
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default MobileSurvey;
